import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import Button from 'components/common/Button';
import Icon from 'components/common/Icon';
import Slider from 'components/common/Slider';
import { __ } from 'utils/translation';
import Links from 'components/Links';
import QRCode from "qrcode.react";
import API from 'utils/api';

import { EDIT_REVIEWS_DATA } from 'constants/actions';

import './index.scss';

const Reviews = () => {
    const [settingsOpened, setSettingsOpened] = useState(false);
    const [data, setData] = useState([]);
    const [quantityCopy, setQuantityCopy] = useState("");

    const openReviewSettings = () => {
        setSettingsOpened(true);
    };

    const closeReviewSettings = () => {
        setSettingsOpened(false);
    };

    const handleChangeItem = (item, value) => {
        setData(data.map((review) => (review.title === item ? { ...review, value } : review)));
    };

    const dispatch = useDispatch();
    const applyReviewsSettings = () => {
        dispatch({ type: EDIT_REVIEWS_DATA, data });
        closeReviewSettings();
    };

    const { url, title, reviews, settingsName } = useSelector((state) => state.config.data);
    useEffect(() => {
        setData(reviews);
    }, [reviews]);

    const [sent, setSent] = useState(false);
    const [timer, setTimer] = useState(null);
    const urlParametrs = `https://search.google.com/local/writereview?placeid=`;

    useEffect(() => {
        timer && timer > 0 && setTimeout(() => {
            if (!timer || timer <= 1) {
                setTimer(null);
                setSent(false);
                return;
            }
            setTimer(timer - 1);
        }, 1000);
    }, [timer]);


    const handleSubmit = () => {
        API.sendOrder({
            type: "QR",
            linkQr: `${urlParametrs}${utmLink}`,
            title: title,
            table: "Google maps отзывы",
            tableNumber: "",
            description0: "&#9733;&#9733;&#9733;&#9733;&#9733;",
            description1: "&#10102Сканируйте этот qr",
            description2: "&#10103;Поставьте звезды",
            description3: "&#10104;Напишите отзыв",
            user: settingsName,
            copy: quantityCopy
        }).then(() => {
            setTimer(30);
            setSent(true);
        });
    };

    const filteredReviews = data.filter((review) => review.value !== '');
    const showAddButton = filteredReviews.length === 0;

    let utmLink = "";
    if (!showAddButton) {
        utmLink = (data.map(e => e.value).toString());

    };
    console.log("utmLink", utmLink)
    return (
        <React.Fragment>
            <div className="reviews">

                {filteredReviews.map((callWaiter) => (
                    <Button
                        key={callWaiter.title}
                        className="review"
                        onClick={openReviewSettings}
                    >
                        <div className="google-map-preview-without-image-with-button">
                            <div className="google-maps-button-box"><Button className="google-maps-button-button"><Icon className="google-maps-icon" type="star" /></Button></div>
                            <div className="google-maps-dilimetr-box"></div>
                            <div className="google-map-p">{__("Функция генератор отзывов в Google Maps была активирована")}</div>
                        </div>
                    </Button>
                ))
                }
                {
                    showAddButton && (
                        <Button
                            key="add-button"
                            onClick={openReviewSettings}
                            className='review-tech-button'
                        >
                            <Icon type="plus" />
                            <span>{__("Отзывы")}</span>
                        </Button>
                    )
                }

            </div>

            <Slider
                opened={settingsOpened}
                onClose={closeReviewSettings}
                title={__("Настройка генератора отзывов")}
                onSubmit={applyReviewsSettings}
                subtitle={__("При помощи этого Qr code вы быстро и бесплатно начнете получать новых клиентов из Google. Как это работает? Клиенты сканируют и в один клик ставят вам отзыв в Google карты. Чем больше отзывов, тем лучше результаты выдачи в поиске Google и Google maps.")}
            >
                <div className="area-box-for-catalogItems" >
                    <Links data={data} onChange={handleChangeItem} />
                    {!utmLink &&<div class="catalogItem-settings__settings__field-header" style={{ marginLeft: 24, marginTop: -30, paddingRight: 24 }}>{__("Чтобы активировать виджет отзывов для автоматического продвижения вашего ресторана в Google Maps получите Google Place ID  в формате 'ChIJbQKLqmGXagwR8mjuzA0wqVA'")}</div>}
                    {utmLink && <div class="catalogItem-settings__settings__field-header" style={{ color: "red", marginTop: -30, marginLeft: 24, paddingRight: 24 }}>{__("Если вам нужен только сервис отзывов и не нужен maps.menu - удалите название и логотип. Без названия и без логотипа отзывы откроются на сайте Google.")}</div>}
                    <br />
                    {utmLink &&
                        <>
                            <div className="qrComponents-flexBox">
                                <div className="catalog-table-settings-screenshot__screenshot-area">
                                    <QRCode
                                        id="paramUrl"
                                        value={urlParametrs + utmLink}
                                        size={290}
                                        bgColor={"#ffffff"}
                                        fgColor={"#000000"}
                                        level={"L"}
                                        includeMargin={false}

                                    />
                                    <p className="linkQr">{urlParametrs}{utmLink}</p>

                                </div>
                            </div>

                            <div className="catalog-table-settings-screenshot__screenshot-area__description">
                                <Icon type="camera" className="catalog-table-settings-screenshot__screenshot-area__description__icon" />&nbsp;
                                <b>{__("Область для снимка экрана")}</b>
                            </div>
                            {/* КНОПКА ОТПРАВИТЬ QR В ПЕЧАТЬ */}
                            {!sent &&
                                <div className="call-waiter-buton-with-input-box" >
                                    <input className="call-waiter-buton-with-input"
                                        type='text'
                                        id='parametrs'
                                        placeholder="0"
                                        maxLength="2"
                                        value={quantityCopy}
                                        onChange={(e) => setQuantityCopy(e.target.value)} />
                                    {quantityCopy > 0 ?
                                        <Button
                                            className='call-waiter-settings__settings__animation__store'

                                            onClick={handleSubmit}
                                            isInline
                                            noStyled
                                        >
                                            {__("РАСПЕЧАТАТЬ ЭТОТ QR CODE")}
                                        </Button> :
                                        <Button
                                            className='call-waiter-settings__settings__animation__nostore'
                                            onClick={() => { }}
                                            isInline
                                            noStyled
                                        >
                                            {__("РАСПЕЧАТАТЬ ЭТОТ QR CODE")}
                                        </Button>
                                    }

                                </div>

                            }
                            {sent && <Button
                                className='call-waiter__checked'
                                onClick={() => { }}
                                isInline
                                noStyled
                            >
                                {__("QR CODE ОТПРАВЛЕН В ПЕЧАТЬ")}{timer % 3 === 0 ? "..." : timer % 3 === 1 ? ".." : "."}
                            </Button>
                            }
                        <br />
                        </>}
                    
                    <div className="settings-video-link-box" style={{ marginLeft: 12 }}>
                        <a href="https://youtu.be/H_svEuYpOP4" target="_blank">
                            <div className="settings-video-link-box__flexbox" style={{ marginLeft: 12 }}>
                                <Icon className="settings-video-link-box__flexbox__icon" type="youtube" />
                                <div className="settings-video-link-box__flexbox__text">{__("Нажмите, чтобы посмотреть инструкцию в YouTube")}</div>
                            </div>
                        </a>
                    </div>
                    <br />
                </div>
                <br/>
                <div className="catalogItem-settings__settings__field-header-picker-red">{__("Внимание! Этот компонент дступен только в мобильной версии. Если ширина экрана более 600 пкс этот комопнент булет скрыт.")}</div>
                <br/>
            </Slider>

        </React.Fragment>
    );
};

export default Reviews;
