import React, { useState } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

import Icon from 'components/common/Icon';
import Input from 'components/common/Input';
import Textarea from 'components/common/Textarea';
import Popup from 'components/common/Popup';
import CropImage from 'components/common/CropImage';
import { __ } from 'utils/translation';

import './index.scss';

const GoogleMapSettings = (props) => {
    const [selectedImage, setSelectedImage] = useState(undefined);

    const updateSettings = (googleMapType, value) => {
        const {
            onChange,
            ...settings
        } = props;
        onChange({
            ...settings,
            [googleMapType]: value
        });
    };

    const {
        link,
        text,
        image,
    } = props;

    const uploadImage = (value) => {
        const imageUrl = value[0];
        setSelectedImage(imageUrl);
    };

    const onCropSave = (value) => {
        updateSettings('image', value);
        setSelectedImage(undefined);
    };

    return (
        <React.Fragment>
            <div className="block-settings">
            
                <div className="area-box-for-catalogItems">
                    {/* область для инпутов*/}
                    <div className="area-box-for-catalogItems__header">{__("Настройки контента")}</div>
                    <div className="block-settings__settings">

                        <Textarea
                            className="block-settings__settings__input"
                            value={text}
                            placeholder={__("Вставь готовый текст")}
                            onChange={(value) => updateSettings('text', value)}
                        />

                        <Input
                            className={classnames([
                                'block-settings__settings__image pulse1 icon1',
                                { used: Boolean(image) }
                            ])}
                            type="file"
                            icon="image"
                            onChange={(value) => uploadImage(value)}
                            onClick={image ? () => updateSettings('image', undefined) : undefined}
                        />
                        {!image
                            ? (
                                <div className="catalogItem-settings__settings__field-header"
                                >
                                    {__("Нажмите на кнопку, чтобы загрузить изображение")}
                                </div>
                            ) : <div className="catalogItem-settings__settings__field-header"
                            >
                                <div className="block-image-box" >
                                    <div className="block-image-box__image" style={{ backgroundImage: `URL(${image})` }} />
                                    {__("Нажмите на кнопку, чтобы удалить загруженное изображение")}
                                </div>
                            </div>}
                        <br />

                        <Input
                            className="block-settings__settings__input"
                            value={link}
                            type="url"
                            placeholder={__("Ссылка (формат https://goo.gl/maps/CTpth...)")}
                            onChange={(value) => updateSettings('link', value)}
                        />
                        <br />
                    </div>
                    {/* область для видео инструкции*/}
                    <div className="settings-video-link-box">
                        <a href="https://youtu.be/kEF2dQFc4aU" target="_blank">
                            <div className="settings-video-link-box__flexbox" style={{ marginLeft: 12 }}>
                                <Icon className="settings-video-link-box__flexbox__icon" type="youtube" />
                                <div className="settings-video-link-box__flexbox__text">{__("Нажмите, чтобы посмотреть инструкцию в YouTube")}</div>
                            </div>
                        </a>
                    </div>
                    <br />
                </div>
                <div className="catalogItem-settings__settings__field-header">
                    {__("Область настроек контента")}
                </div>
                <br/>
                <div className="catalogItem-settings__settings__field-header-picker-red">{__("Внимание! Этот компонент дступен только в мобильной версии. Если ширина экрана более 600 пкс этот комопнент булет скрыт.")}</div>
       
            </div>

            {
                selectedImage && (
                    <Popup visible={Boolean(selectedImage)}>
                        <CropImage
                            file={selectedImage}
                            onSave={onCropSave}
                        />
                    </Popup>
                )
            }
        </React.Fragment>
    );
};

GoogleMapSettings.propTypes = {
    link: PropTypes.string,
    text: PropTypes.string,
    image: PropTypes.string,
    onChange: PropTypes.func
};

GoogleMapSettings.defaultProps = {
    link: '',
    text: '',
    image: '',
    onChange: () => { }
};

export default GoogleMapSettings;

