import superagent from 'superagent';
import { getSearchString } from 'utils/url';
import history from 'utils/history';
import { getDefaultLanguage } from 'utils/translation';
import { GoogleSpreadsheet } from "google-spreadsheet";

const SOMETHING_WENT_WRONG = 'Что-то пошло не так!';

let token = null;
let invite = '';
const host = 'https://api.sweety.link';

let profile = null;

const setProfile = (newProfile) => {
  profile = newProfile;
};

const _NEWHOST = 'https://api.maps.menu';
// const _NEWHOST = 'http://localhost:3006';

export const setToken = (newToken) => {
  token = newToken;
};

export const setInvite = (newInvite) => {
  invite = newInvite;
};

export const getInvite = () => {
  const searchInvite = getSearchString(window.location.search, 'invitationId');
  return invite || searchInvite;
};



const responseBody = (res) => res && res.body;
const tokenPlugin = (req) => {
  if (token)
    req.set('Authorization', `Bearer ${token}`);
};

const handleError = (e) => {
  console.error(SOMETHING_WENT_WRONG, e);
};

const gapiEmail = "catalog@sweetyimport.iam.gserviceaccount.com";
const gapiKey = "-----BEGIN PRIVATE KEY-----\nMIIEvAIBADANBgkqhkiG9w0BAQEFAASCBKYwggSiAgEAAoIBAQCPbQ9bbKiiPmAi\nM4OJxvcPYQX4dOEcjA/zflguCelELC+CptGh8Ye1GrzfhoMudR2ea1lBhZ35tBzS\nzpI+e3fyrYjrKh2CTB41xp4En37NCDUfaVD5ni5QEwyLvuq5pw+wvv4nocnRrEtc\n0c7RD61Ij6E4+sSIBHrBAZAlNBaimbfgqVGn6Y4wWZK6CawTe7lsOc8Ujaarc21o\nYIOg9rW5idxFYAJUMJAcy62V7hz86ROXF2lkl9gjBuH2Npz6q0oV3JVbTveBFt5u\nfYeJj4pBWQtRD1rWS86O4Y4r1K+8fmVxwsRX3CAshONgW27wniL5qT2P21s3Kadt\nijclltj1AgMBAAECggEAKRauWpuzZ9+EvOeTHGpypuY9xqL9Rqddfn1Il8/hEtWx\nurU9wy4lmZ2SVFr9w9ZraNIKHPpBeqK9yyrAvMO0ZkKOwggrsgNKeE9xP5PNyME8\nLsaGGyzUyb3bT0GfjxYsKY9d0k7IOZgpcursW/oow2vaXS+CFBFsdVSUTgQxI92G\nAoplOOyKCfTazMQghdU7bDMwGMYNlULNNQEwAS5WJ32PDe3PBM4+1qK5L2sBD4to\nAn2Ma4He0E4FXlmfhokd2iAPD4GJI1qK7MZJYMIHeur8XhuuVI3/9OrEEiFpte6K\nSCPE8f5O4txR0Q23BAKD5aaJ2zwHGFAT+Wfk2yTSFQKBgQDCA76RdmiJ3Q2skMfR\nkaAl8Ra4lUv6WV7a4RAsWzZAv3MjUh5cn3gCTZAdfaCm8/oI6mfaE0Q/aCAygIhD\nMx1zP99M29q8O4GyD/UUBfbeZpy/ed9N7hDLdi/Wp7hqTmK+R86q6f20qiRIIUxv\nkTn72dh8M4h492/i8Nly/ttwFwKBgQC9P7m1Vd9G9517REh57hVRRsUOSgzVAO6s\nhRKBX2+OFOLAX+4za91cLd3Z1Pg4oNmq+IfQiKrs5cI3P0Nl9BrgPaieuAgjCyyP\nhRAlbMd6QB7D84xTwI+06eN1Q6aFK3S6gL2Vt4JC+DygroFM0vSeWQlHnY5QnvAE\nlE7xrbX60wKBgERhAXdPHkUIrdsWI/bOtnzo3bMsm1yexvmpvQOFGjfzwea++Ih4\ng9l78MEUF9z/vC4MP5HynGkkj8R83ImiqEyIRHFYQ114M5vIV/44o+t6iuBJWdSj\nhTPQccfb0PlWqyKZOFOwqIRWOvdZFRF1Q9Rp0QzlNMI9oyd+74TCIiD9AoGAdxZa\nrhlTXz0CBEd7s/51u6dk6RD/8imcB0PV2UNM14OdDKFRK1p8+TyDlkfFyxys3EF4\ndWkK5ffOtyVALC/nmaQzL21u8V5etBFvj51cCTnAIl5nt2w9AgML9waTCsnFsnbA\n1i2b8rhyrkohY058UAiHJmGm5GSfdMI+yyYclbECgYBTEz5uHO1tXshG0+E33mRG\nzWdP13a9IeMSzbCRKc5uz3cS3idMJTIuUnK6oU50c1qwAH9s3zk+2SLZ8lerLNh3\n3sMYeIh0y0b1ZsddaBi5SThBdl1gs4pWpYi31VxQhlXCDEsnRb+CEH5Kx57sbEGa\ntbk2c9Xz9SCfLYDg9stzsQ==\n-----END PRIVATE KEY-----\n";
const gApiTranslationKey = "AIzaSyAh-zM0SB-WYt1YVKKkcnDLqUbQJ5b3fnE";

const requests = {
  get: (url, params) => superagent.get(`${_NEWHOST}${url}`).query(params)
    .use(tokenPlugin)
    .retry(100)
    .catch(handleError)
    .then(responseBody),
  post: (url, params) => superagent.post(`${_NEWHOST}${url}`).send(params)
    .use(tokenPlugin)
    .catch(handleError)
    .then(responseBody),
  put: (url, params) => superagent.put(`${_NEWHOST}${url}`).send(params)
    .use(tokenPlugin)
    .catch(handleError)
    .then(responseBody),
  del: (url, params) => superagent.del(`${host}${url}`).send(params)
    .use(tokenPlugin)
    .catch(handleError)
    .then(responseBody)
};

const API = {
  updateProfile: (value) => {
    setProfile(value);
    console.log("profile API updateProfile", profile)
  },
  getToken: () => superagent.post(`${_NEWHOST}/api/users/invite/${getInvite()}/confirm`)
    .retry(100)
    .catch(handleError)
    .then(responseBody)
    .then((response) => {
      console.log("Get Token ->")
      console.log(response)
      console.log("Get Token <-")
      setToken(response.token)
    }),
  updateToken: (keyCaptcha) => {
    const id = getInvite();
    console.log("keyCaptcha updateToken", keyCaptcha)
    

// генерация новых админок, разкомментировать чтобы создавать новые админки    
    if (!id && keyCaptcha === "123") {
      return superagent.post(`${_NEWHOST}/api/users/register`)
        .send({})
        .catch(handleError)
        .then(responseBody)
        .then((response) => {
          console.log("Register ->")
          console.log(response)
          console.log("Register <-")
          const { invitationId } = response;
          setInvite(invitationId);
          history.push({
            pathname: '/',
            search: `?invitationId=${invitationId}` 
          });
          return API.getToken();
        });
    };
    
    if (!id && keyCaptcha !== "123") {
      return window.open("https://maps.menu/en/index.html", "_self");
        };

  return API.getToken(); 
    // конец


    // ниже блок для открытие стартового шаблона если нет своей админки. закомментировать чтобы создавать новые админки
    // if (!id) {
        
    //         const  invitationId  = '9812b2f8-add3-4d0f-a2b5-32d243ee09f9';
    //         setInvite(invitationId);
    //         history.push({
    //           pathname: '/',
    //           search: `?invitationId=${invitationId}` 
    //         });
    //         return API.getToken();
          
    //   }
    // return API.getToken();
// конец
 
  },
  getData: () =>  requests.get('/api/profiles/pages/settings'),
  getDataRecovery: () => requests.get(`/api/profiles/pages/public/${profile}`),
  saveData: (data) => requests.put('/api/profiles/pages/settings', data),
  // register: (data) => requests.put('/api/users/profile', { ...data, lang: getDefaultLanguage() }),
  // login: (data) => requests.post('/api/users/login', data),
  uploadImage: (data) => requests.post('/api/profiles/pages/images/upload', data),
  // getBalance: () => requests.get('/api/payments/balance'),
  // getReferrals: () => requests.get('/api/profiles/referrals'),
  sendOrder: (data) => requests.post(`/api/profiles/${profile}/preorders`, data),
  
  toDataUrl: (url) => {
    
    return new Promise ((resolve) => {
      var xhr = new XMLHttpRequest();
      xhr.onload = function() {
        resolve(xhr.response);
      };
      xhr.onerror = function () {
        resolve(null);
      };
      const loadingUrl = window.location.hostname !== "localhost" || !url.startsWith("https://maps.menu") || !url.includes(".mpeg") ? url :
        "https://cors-anywhere.herokuapp.com/" + url;
      xhr.open('GET', url);
      xhr.responseType = 'blob';
      xhr.send();
    })
  },
  getGoogleSpreadSheetById: async (docId, sheetId) => {
    const doc = new GoogleSpreadsheet(docId);
    try {
      await doc.useServiceAccountAuth({
        client_email: gapiEmail,
        private_key: gapiKey,
      });
      // loads document properties and worksheets
      await doc.loadInfo();
  
      return doc.sheetsById[sheetId]; 
    } catch (e) {
      console.error('Error: ', e);
    }
  },
  getGoogleSpreadSheet: async (docId) => {
    const sheet = await API.getGoogleSpreadSheetById(docId, "0")
    return await sheet.getRows()
  },
  // translateGoogle: (text, fromLang, toLang) => {
  //   let url = `https://translation.googleapis.com/language/translate/v2?key=${gApiTranslationKey}`;
  //   url += '&q=' + encodeURI(text);
  //   url += `&source=${fromLang}`;
  //   url += `&target=${toLang}`;
    
  //   return fetch(url, { 
  //     method: 'GET',
  //     headers: {
  //       "Content-Type": "application/json",
  //       Accept: "application/json"
  //     }
  //   })
  //   .then(res => res.json())
  //   .then((response) => {
  //     console.log("response from google: ", response);
  //   })
  //   .catch(error => {
  //     console.log("There was an error with the translation request: ", error);
  //   });
  // },
  translate: (texts, langFrom, langTo) => requests.post(`/api/profiles/translate/${langFrom}/${langTo}`, texts)
};

export default API;
