import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { Router } from 'react-router';
import history from 'utils/history';
import { getSearchString } from 'utils/url';
import store from 'store';

import RouterComponent from 'components/Router';
import Captcha from 'components/Captcha';


import './index.scss';

let invite = '';

export const setInvite = (newInvite) => {
  invite = newInvite;
};

export const getInvite = () => {
  const searchInvite = getSearchString(window.location.search, 'invitationId');
  return invite || searchInvite;
};

const id = getInvite();



ReactDOM.render(
  (
      <Router history={history}>
        <Provider store={store}>
          {id ? <RouterComponent /> : <Captcha /> }
        </Provider>
      </Router>
  ),
  document.getElementById('root')
);
