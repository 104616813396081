import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { PDFDownloadLink, Page, Image, Text, View, Document, StyleSheet } from '@react-pdf/renderer';
import QRCode from "qrcode.react";
import { getSearchString } from 'utils/url';

import Icon from 'components/common/Icon';
import Button from 'components/common/Button';
import './index.scss';
import { __ } from 'utils/translation';
import Slider from 'components/common/Slider';

import imageBig1 from 'images/big-book-page-1.jpg';
import imageBig2 from 'images/big-book-page-2.jpg';
import imageBig1png from 'images/big-book-page-1.png';
import imageBig2png from 'images/big-book-page-2.png';
import imageSmall1 from 'images/small-book-page-1.jpg';
import imageSmall2 from 'images/small-book-page-2.jpg';

import cpBig1 from 'images/big-cp-page-1.jpg';
import cpBig2 from 'images/big-cp-page-2.jpg';
import cpBig1png from 'images/big-cp-page-1.png';
import cpBig2png from 'images/big-cp-page-2.png';
import cpSmall1 from 'images/small-cp-page-1.jpg';
import cpSmall2 from 'images/small-cp-page-2.jpg';


const Book = () => {
    const { settingsName } = useSelector((state) => state.config.data);
    const [settingsOpened, setSettingsOpened] = useState(false); 

    const [dowloadPageSizeLeft, setDowloadPageSizeLeft] = useState(false);
    const [dowloadPageSizeRight, setDowloadPageSizeRight] = useState(false);
    const [dowloadPageSizeLeftQr, setDowloadPageSizeLeftQr] = useState(false);
    const [dowloadPageSizeRightQr, setDowloadPageSizeRightQr] = useState(false);
    const [dowloadCpSizeLeft, setDowloadCpSizeLeft] = useState(false);
    const [dowloadCpSizeRight, setDowloadCpSizeRight] = useState(false);

    const [pngPageWhicky, setPngPageWhicky] = useState("");
    const [pngPageWine, setPngPageWine] = useState("");
    const [pngPageCoctails, setPngPageCoctails] = useState("");
    const [pngPageBrandy, setPngPageBrandy] = useState("");
    const [pngPageCoffee, setPngPageCoffee] = useState("");
    const [pngPageVodka, setPngPageVodka] = useState("");

    const [pngPageGin, setPngPageGin] = useState("");
    const [pngPageLiquor, setPngPageLiquor] = useState("");
    const [pngPageRum, setPngPageRum] = useState("");
    const [pngPageBeer, setPngPageBeer] = useState("");
    const [pngPageOther, setPngPageOther] = useState("");
    const [pngPageSoftDrinks, setPngPageSoftDrinks] = useState("");

    const [pngCpWhicky, setPngCpWhicky] = useState("");
    const [pngCpWine, setPngCpWine] = useState("");
    const [pngCpCoctails, setPngCpCoctails] = useState("");
    const [pngCpBrandy, setPngCpBrandy] = useState("");
    const [pngCpCoffee, setPngCpCoffee] = useState("");
    const [pngCpVodka, setPngCpVodka] = useState("");

    const [pngCpGin, setPngCpGin] = useState("");
    const [pngCpLiquor, setPngCpLiquor] = useState("");
    const [pngCpRum, setPngCpRum] = useState("");
    const [pngCpBeer, setPngCpBeer] = useState("");
    const [pngCpOther, setPngCpOther] = useState("");
    const [pngCpSoftDrinks, setPngCpSoftDrinks] = useState("");

    const searchInvite = getSearchString(window.location.search, 'invitationId');
    const urlThisAdmin = `https://dash.maps.menu/?invitationId=${searchInvite}`;

    const isBook = false;

    const urlCategoryCoctails = `https://maps.menu/${settingsName}?categoriacoctails`;
    const urlAdminCoctails = `${urlThisAdmin}&categoriacoctails`;

    const urlCategoryBeer = `https://maps.menu/${settingsName}?categoriabeer`;
    const urlAdminBeer = `${urlThisAdmin}&categoriabeer`;

    const urlCategoryWine = `https://maps.menu/${settingsName}?categoriawine`;
    const urlAdminWine = `${urlThisAdmin}&categoriawine`;

    const urlCategoryWhicky = `https://maps.menu/${settingsName}?categoriawhicky`;
    const urlAdminWhicky = `${urlThisAdmin}&categoriawhicky`;

    const urlCategoryBrandy = `https://maps.menu/${settingsName}?categoriabrandy`;
    const urlAdminBrandy = `${urlThisAdmin}&categoriabrandy`;

    const urlCategoryCoffee = `https://maps.menu/${settingsName}?categoriacoffee`;
    const urlAdminCoffee = `${urlThisAdmin}&categoriacoffee`;

    const urlCategoryVodka = `https://maps.menu/${settingsName}?categoriavodka`;
    const urlAdminVodka = `${urlThisAdmin}&categoriavodka`;

    const urlCategoryGin = `https://maps.menu/${settingsName}?categoriagin`;
    const urlAdminGin = `${urlThisAdmin}&categoriagin`;

    const urlCategoryRum = `https://maps.menu/${settingsName}?categoriarum`;
    const urlAdminRum = `${urlThisAdmin}&categoriarum`;

    const urlCategoryOther = `https://maps.menu/${settingsName}?categoriaother`;
    const urlAdminOther = `${urlThisAdmin}&categoriaother`;

    const urlCategoryDrinks = `https://maps.menu/${settingsName}?categoriasoftdrinks`;
    const urlAdminDrinks = `${urlThisAdmin}&categoriasoftdrinks`;

    const urlCategoryHookah = `https://maps.menu/${settingsName}?categoriahookah`;
    const urlAdminHookah = `${urlThisAdmin}&categoriahookah`;

    const urlCategoryTequila = `https://maps.menu/${settingsName}?categoriatequila`;
    const urlAdminTequila = `${urlThisAdmin}&categoriatequila`;

    const urlCategoryAperitifs = `https://maps.menu/${settingsName}?categoriaaperitifs`;
    const urlAdminAperitifs = `${urlThisAdmin}&categoriaaperitifs`;

    const urlCategoryDegistives = `https://maps.menu/${settingsName}?categoriadegistives`;
    const urlAdminDegistives = `${urlThisAdmin}&categoriadegistives`;

    const urlCategorySnacks = `https://maps.menu/${settingsName}?categoriasnacks`;
    const urlAdminSnacks = `${urlThisAdmin}&categoriasnacks`;

    const urlCategoryLiquore = `https://maps.menu/${settingsName}?categorialiquore`;
    const urlAdminLiquore = `${urlThisAdmin}&categorialiquore`;

    const linkWhatsapp = `https://wa.me/34672442251?text=I want to order 250 catalogues for 100 euros for:${urlThisAdmin}`;
    const openPrinterSettings = () => {
        setSettingsOpened(true);
        return false;
    };
    const closePrinterSettings = () => {
        setSettingsOpened(false);
        window.location.reload();
    };


    const preparePageSizeLeft = () => {
        const canvas = document.getElementById("qrWhicky");
        const pngPageWhicky = canvas
            .toDataURL("image/png")
            .replace("image/png", "image/octet-stream");
            setPngPageWhicky(pngPageWhicky);

        const canvas2 = document.getElementById("qrWine");
        const pngPageWine = canvas2
            .toDataURL("image/png")
            .replace("image/png", "image/octet-stream");
            setPngPageWine(pngPageWine);

        const canvas3 = document.getElementById("qrCoctails");
        const pngPageCoctails = canvas3
            .toDataURL("image/png")
            .replace("image/png", "image/octet-stream");
            setPngPageCoctails(pngPageCoctails);

        const canvas4 = document.getElementById("qrBrandy");
        const pngPageBrandy = canvas4
            .toDataURL("image/png")
            .replace("image/png", "image/octet-stream");
            setPngPageBrandy(pngPageBrandy);

        const canvas5 = document.getElementById("qrCoffee");
        const pngPageCoffee = canvas5
            .toDataURL("image/png")
            .replace("image/png", "image/octet-stream");
            setPngPageCoffee(pngPageCoffee);

        const canvas6 = document.getElementById("qrVodka");
        const pngPageVodka = canvas6
            .toDataURL("image/png")
            .replace("image/png", "image/octet-stream");        
            setPngPageVodka(pngPageVodka);
        setDowloadPageSizeLeft(true);        
    };

    const preparePageSizeRight = () => {
        const canvas = document.getElementById("qrGin");
        const pngPageGin = canvas
            .toDataURL("image/png")
            .replace("image/png", "image/octet-stream");
            setPngPageGin(pngPageGin);

        const canvas2 = document.getElementById("qrLiquor");
        const pngPageLiquor = canvas2
            .toDataURL("image/png")
            .replace("image/png", "image/octet-stream");
            setPngPageLiquor(pngPageLiquor);

        const canvas3 = document.getElementById("qrRum");
        const pngPageRum = canvas3
            .toDataURL("image/png")
            .replace("image/png", "image/octet-stream");
            setPngPageRum(pngPageRum);

        const canvas4 = document.getElementById("qrBeer");
        const pngPageBeer = canvas4
            .toDataURL("image/png")
            .replace("image/png", "image/octet-stream");
            setPngPageBeer(pngPageBeer);

        const canvas5 = document.getElementById("qrOther");
        const pngPageOther = canvas5
            .toDataURL("image/png")
            .replace("image/png", "image/octet-stream");
            setPngPageOther(pngPageOther);

        const canvas6 = document.getElementById("qrSoftDrinks");
        const pngPageSoftDrinks = canvas6
            .toDataURL("image/png")
            .replace("image/png", "image/octet-stream");        
            setPngPageSoftDrinks(pngPageSoftDrinks);

        setDowloadPageSizeRight(true);
               
    };

    
    const preparePageSizeLeftQr = () => {
        const canvas = document.getElementById("qrWhicky");
        const pngPageWhicky = canvas
            .toDataURL("image/png")
            .replace("image/png", "image/octet-stream");
            setPngPageWhicky(pngPageWhicky);

        const canvas2 = document.getElementById("qrWine");
        const pngPageWine = canvas2
            .toDataURL("image/png")
            .replace("image/png", "image/octet-stream");
            setPngPageWine(pngPageWine);

        const canvas3 = document.getElementById("qrCoctails");
        const pngPageCoctails = canvas3
            .toDataURL("image/png")
            .replace("image/png", "image/octet-stream");
            setPngPageCoctails(pngPageCoctails);

        const canvas4 = document.getElementById("qrBrandy");
        const pngPageBrandy = canvas4
            .toDataURL("image/png")
            .replace("image/png", "image/octet-stream");
            setPngPageBrandy(pngPageBrandy);

        const canvas5 = document.getElementById("qrCoffee");
        const pngPageCoffee = canvas5
            .toDataURL("image/png")
            .replace("image/png", "image/octet-stream");
            setPngPageCoffee(pngPageCoffee);

        const canvas6 = document.getElementById("qrVodka");
        const pngPageVodka = canvas6
            .toDataURL("image/png")
            .replace("image/png", "image/octet-stream");        
            setPngPageVodka(pngPageVodka);

        setDowloadPageSizeLeftQr(true);         
    };

    const preparePageSizeRightQr = () => {
        const canvas = document.getElementById("qrGin");
        const pngPageGin = canvas
            .toDataURL("image/png")
            .replace("image/png", "image/octet-stream");
            setPngPageGin(pngPageGin);

        const canvas2 = document.getElementById("qrLiquor");
        const pngPageLiquor = canvas2
            .toDataURL("image/png")
            .replace("image/png", "image/octet-stream");
            setPngPageLiquor(pngPageLiquor);

        const canvas3 = document.getElementById("qrRum");
        const pngPageRum = canvas3
            .toDataURL("image/png")
            .replace("image/png", "image/octet-stream");
            setPngPageRum(pngPageRum);

        const canvas4 = document.getElementById("qrBeer");
        const pngPageBeer = canvas4
            .toDataURL("image/png")
            .replace("image/png", "image/octet-stream");
            setPngPageBeer(pngPageBeer);

        const canvas5 = document.getElementById("qrOther");
        const pngPageOther = canvas5
            .toDataURL("image/png")
            .replace("image/png", "image/octet-stream");
            setPngPageOther(pngPageOther);

        const canvas6 = document.getElementById("qrSoftDrinks");
        const pngPageSoftDrinks = canvas6
            .toDataURL("image/png")
            .replace("image/png", "image/octet-stream");        
            setPngPageSoftDrinks(pngPageSoftDrinks);

        setDowloadPageSizeRightQr(true);        
    };
    

    const prepareCpSizeLeft = () => {
        const canvas = document.getElementById("qrCpWhicky");
        const pngCpWhicky = canvas
            .toDataURL("image/png")
            .replace("image/png", "image/octet-stream");
            setPngCpWhicky(pngCpWhicky);

        const canvas2 = document.getElementById("qrCpWine");
        const pngCpWine = canvas2
            .toDataURL("image/png")
            .replace("image/png", "image/octet-stream");
            setPngCpWine(pngCpWine);

        const canvas3 = document.getElementById("qrCpCoctails");
        const pngCpCoctails = canvas3
            .toDataURL("image/png")
            .replace("image/png", "image/octet-stream");
            setPngCpCoctails(pngCpCoctails);

        const canvas4 = document.getElementById("qrCpBrandy");
        const pngCpBrandy = canvas4
            .toDataURL("image/png")
            .replace("image/png", "image/octet-stream");
            setPngCpBrandy(pngCpBrandy);

        const canvas5 = document.getElementById("qrCpCoffee");
        const pngCpCoffee = canvas5
            .toDataURL("image/png")
            .replace("image/png", "image/octet-stream");
            setPngCpCoffee(pngCpCoffee);

        const canvas6 = document.getElementById("qrCpVodka");
        const pngCpVodka = canvas6
            .toDataURL("image/png")
            .replace("image/png", "image/octet-stream");        
            setPngCpVodka(pngCpVodka);

        setDowloadCpSizeLeft(true);        
    };

    const prepareCpSizeRight = () => {
        const canvas = document.getElementById("qrCpGin");
        const pngCpGin = canvas
            .toDataURL("image/png")
            .replace("image/png", "image/octet-stream");
            setPngCpGin(pngCpGin);

        const canvas2 = document.getElementById("qrCpLiquor");
        const pngCpLiquor = canvas2
            .toDataURL("image/png")
            .replace("image/png", "image/octet-stream");
            setPngCpLiquor(pngCpLiquor);

        const canvas3 = document.getElementById("qrCpRum");
        const pngCpRum = canvas3
            .toDataURL("image/png")
            .replace("image/png", "image/octet-stream");
            setPngCpRum(pngCpRum);

        const canvas4 = document.getElementById("qrCpBeer");
        const pngCpBeer = canvas4
            .toDataURL("image/png")
            .replace("image/png", "image/octet-stream");
            setPngCpBeer(pngCpBeer);

        const canvas5 = document.getElementById("qrCpOther");
        const pngCpOther = canvas5
            .toDataURL("image/png")
            .replace("image/png", "image/octet-stream");
            setPngCpOther(pngCpOther);

        const canvas6 = document.getElementById("qrCpSoftDrinks");
        const pngCpSoftDrinks = canvas6
            .toDataURL("image/png")
            .replace("image/png", "image/octet-stream");        
            setPngCpSoftDrinks(pngCpSoftDrinks);

        setDowloadCpSizeRight(true);        
    };

    const styles = StyleSheet.create({
        page: {
          flexDirection: 'row',
        },
        bg: {
            objectfit: "contain",
          },
        image1: {    
            maxHeight: 50,
            maxWidth: 50,
            position: 'absolute',
            bottom: 29,
            left: 20,
            right: 0,
          },
          image2: {    
            maxHeight: 48,
            maxWidth: 48,
            position: 'absolute',
            bottom: 29,
            left: 527,            
            right: 0,
          },

          image3: {    
            maxHeight: 48,
            maxWidth: 48,
            position: 'absolute',
            bottom: 310,
            left: 20,
            right: 0,
          },
          image4: {    
            maxHeight: 48,
            maxWidth: 48,
            position: 'absolute',
            bottom: 310,
            left: 527,            
            right: 0,
          },

          image5: {    
            maxHeight: 48,
            maxWidth: 48,
            position: 'absolute',
            bottom: 590,
            left: 20,
            right: 0,
          },
          image6: {    
            maxHeight: 48,
            maxWidth: 48,
            position: 'absolute',
            bottom: 590,
            left: 527,            
            right: 0,
          },

          imagecp1: {    
            maxHeight: 75,
            maxWidth: 75,
            position: 'absolute',
            bottom: 42,
            left: 28,
            right: 0,
          },
          imagecp2: {    
            maxHeight: 75,
            maxWidth: 75,
            position: 'absolute',
            bottom: 42,
            left: 493,            
            right: 0,
          },

          imagecp3: {    
            maxHeight: 75,
            maxWidth: 75,
            position: 'absolute',
            bottom: 323,
            left: 28,
            right: 0,
          },
          imagecp4: {    
            maxHeight: 75,
            maxWidth: 75,
            position: 'absolute',
            bottom: 323,
            left: 493,            
            right: 0,
          },

          imagecp5: {    
            maxHeight: 75,
            maxWidth: 75,
            position: 'absolute',
            bottom:603,
            left: 28,
            right: 0,
          },
          imagecp6: {    
            maxHeight: 75,
            maxWidth: 75,
            position: 'absolute',
            bottom: 603,
            left: 493,            
            right: 0,
          },
      });
      
      const PageBarSizeLeft = () => (
        <Document>
          <Page size="A4" style={styles.page}>
          <Image style={styles.bg} src={imageBig1} />
            <Image style={styles.image5} src={pngPageWhicky} />
            <Image style={styles.image6} src={pngPageWine}  />
            <Image style={styles.image3} src={pngPageCoctails} />
            <Image style={styles.image4} src={pngPageBrandy} />
            <Image style={styles.image1} src={pngPageCoffee} />
            <Image style={styles.image2} src={pngPageVodka} />        
          </Page>
        </Document>
      );
      const PageBarSizeRight = () => (
        <Document>
          <Page size="A4" style={styles.page}>
          <Image style={styles.bg} src={imageBig2} />
            <Image style={styles.image5} src={pngPageGin} />
            <Image style={styles.image6} src={pngPageLiquor}  />
            <Image style={styles.image3} src={pngPageRum} />
            <Image style={styles.image4} src={pngPageBeer} />
            <Image style={styles.image1} src={pngPageOther} />
            <Image style={styles.image2} src={pngPageSoftDrinks} />        
          </Page>
        </Document>
      );

      const PageBarSizeLeftQr = () => (
        <Document>
          <Page size="A4" style={styles.page}>
            <Image style={styles.image5} src={pngPageWhicky} />
            <Image style={styles.image6} src={pngPageWine}  />
            <Image style={styles.image3} src={pngPageCoctails} />
            <Image style={styles.image4} src={pngPageBrandy} />
            <Image style={styles.image1} src={pngPageCoffee} />
            <Image style={styles.image2} src={pngPageVodka} />        
          </Page>
        </Document>
      );
      const PageBarSizeRightQr = () => (
        <Document>
          <Page size="A4" style={styles.page}>
            <Image style={styles.image5} src={pngPageGin} />
            <Image style={styles.image6} src={pngPageLiquor}  />
            <Image style={styles.image3} src={pngPageRum} />
            <Image style={styles.image4} src={pngPageBeer} />
            <Image style={styles.image1} src={pngPageOther} />
            <Image style={styles.image2} src={pngPageSoftDrinks} />        
          </Page>
        </Document>
      );

      const CpBarSizeLeft = () => (
        <Document>
          <Page size="A4" style={styles.page}>
          <Image style={styles.bg} src={cpBig1} />
            <Image style={styles.imagecp5} src={pngCpWhicky} />
            <Image style={styles.imagecp6} src={pngCpWine}  />
            <Image style={styles.imagecp3} src={pngCpCoctails} />
            <Image style={styles.imagecp4} src={pngCpBrandy} />
            <Image style={styles.imagecp1} src={pngCpCoffee} />
            <Image style={styles.imagecp2} src={pngCpVodka} />        
          </Page>
        </Document>
      );
      const CpBarSizeRight = () => (
        <Document>
          <Page size="A4" style={styles.page}>
          <Image style={styles.bg} src={cpBig2} />
            <Image style={styles.imagecp5} src={pngCpGin} />
            <Image style={styles.imagecp6} src={pngCpLiquor}  />
            <Image style={styles.imagecp3} src={pngCpRum} />
            <Image style={styles.imagecp4} src={pngCpBeer} />
            <Image style={styles.imagecp1} src={pngCpOther} />
            <Image style={styles.imagecp2} src={pngCpSoftDrinks} />        
          </Page>
        </Document>
      );
      
    
    

    return (
        <React.Fragment>
            <Button onClick={openPrinterSettings} isInline 
                style={{color:"red"}} className="icon-header-profile blink_me" >
                <Icon type="bookOpen" />
            </Button>
            <Slider
                opened={settingsOpened}
                onClose={closePrinterSettings}
                onSubmit={closePrinterSettings }
                title={__("Каталог бар меню")}
                subtitle={__("Ваши рекламные материалы готовы на 100%. Сначала распечатайте каталог для панели управления и настройте цены. Затем распечатайте каталог для клиентов и разложите на свои столы. Хотите еще дешевле? Закажите у нас 250 каталогов всего за 100 евро и получите 6 месяцев использования в подарок.")}
                linkWhatsapp={linkWhatsapp }
                submitTitle={__("Заказать 250 каталогов")}
            > 
            <div className='book-box' onClick={()=> window.open(imageBig1png, "_blank")}>
                <div className={dowloadPageSizeLeft || dowloadPageSizeLeftQr ? 'book-foto-box-selected' : 'book-foto-box'}  
                    style={{ backgroundImage: `URL(${imageSmall1})`}} />
            </div>
            <div className='book-box' onClick={()=> window.open(imageBig2png, "_blank")}>
                <div className={dowloadPageSizeRight || dowloadPageSizeRightQr ? 'book-foto2-box-selected' : 'book-foto2-box'} 
                style={{ backgroundImage: `URL(${imageSmall2})`}} />
            </div>

            
            <div className='book-box-flex'>
            {!dowloadPageSizeRight && !dowloadPageSizeLeftQr && !dowloadPageSizeRightQr && !dowloadCpSizeLeft && !dowloadCpSizeRight &&
                <>
                    {!dowloadPageSizeLeft ? 
                        <Button onClick={preparePageSizeLeft}>{__("Подготовить и скачать левую сторону каталога")}</Button>
                    :
                        <PDFDownloadLink document={<PageBarSizeLeft />} fileName="Page-size-left.pdf">
                            {({ blob, url, loading, error }) => (loading ? 
                            <Button style={{color: 'red'}}>{__("Мы закачиваем каталог в высоком качестве. Это  может занять некоторое время. Пожалуйста подождите...")}</Button> 
                            : 
                            <Button style={{color: 'green'}}>{__("Нажмите эту кнопку, чтобы получить PDF файл. Дождитесь окончания скачивание на ваше устройство...")}</Button>)}
                        </PDFDownloadLink> 
                    }
                </>
            }
                <div className='book-box-flex__empty'></div>
                {!dowloadPageSizeLeft  && !dowloadPageSizeLeftQr && !dowloadPageSizeRightQr && !dowloadCpSizeLeft && !dowloadCpSizeRight &&
                <>
                    {!dowloadPageSizeRight ? 
                        <Button  onClick={preparePageSizeRight}>{__("Подготовить и скачать правую сторону каталога")}</Button>
                    :
                        <PDFDownloadLink document={<PageBarSizeRight />} fileName="Page-size-right.pdf">
                            {({ blob, url, loading, error }) => (loading ? 
                            <Button style={{color: 'red'}}>{__("Мы закачиваем каталог в высоком качестве. Это  может занять некоторое время. Пожалуйста подождите...")}</Button> 
                            : 
                            <Button style={{color: 'green'}}>{__("Нажмите эту кнопку, чтобы получить PDF файл. Дождитесь окончания скачивание на ваше устройство...")}</Button>)}
                        </PDFDownloadLink> 
                    }
                </>
            }
            </div>
            <div className='book-box-flex__empty'></div>
            <div className='book-box-flex'>
            { !dowloadPageSizeLeft && !dowloadPageSizeRight && !dowloadPageSizeRightQr && !dowloadCpSizeLeft && !dowloadCpSizeRight &&
                <>
                    {!dowloadPageSizeLeftQr ? 
                        <Button  onClick={preparePageSizeLeftQr}>{__("Подготовить и скачать QR коды без картинки для левой стороны каталога")}</Button>
                    :
                        <PDFDownloadLink document={<PageBarSizeLeftQr />} fileName="Page-size-left.pdf">
                            {({ blob, url, loading, error }) => (loading ? 
                            <Button style={{color: 'red'}}>{__("Подождите несколько секунд, мы готовим ваши QR коды для левой стороны каталога")}</Button> 
                            : 
                            <Button style={{color: 'green'}}>{__("Нажмите эту кнопку, чтобы получить PDF файл. Дождитесь окончания скачивание на ваше устройство...")}</Button>)}
                        </PDFDownloadLink> 
                    }
                </>
            }
                <div className='book-box-flex__empty'></div>
                {!dowloadPageSizeLeft && !dowloadPageSizeRight && !dowloadPageSizeLeftQr && !dowloadCpSizeLeft && !dowloadCpSizeRight &&
                    <>
                        {!dowloadPageSizeRightQr ? 
                            <Button  onClick={preparePageSizeRightQr}>{__("Подготовить и скачать QR коды без картинки для правой стороны каталога")}</Button>
                        :
                            <PDFDownloadLink document={<PageBarSizeRightQr />} fileName="Page-size-left.pdf">
                                {({ blob, url, loading, error }) => (loading ? 
                                <Button style={{color: 'red'}}>{__("Подождите несколько секунд, мы готовим ваши QR коды для правой стороны каталога")}</Button> 
                                : 
                                <Button style={{color: 'green'}}>{__("Нажмите эту кнопку, чтобы получить PDF файл. Дождитесь окончания скачивание на ваше устройство...")}</Button>)}
                            </PDFDownloadLink> 
                        }
                    </>
            }
            </div>
            <br/>
            <Button
                key="add-button" style={{width: "100%", fontSize:24}}
                onClick={()=> window.open(linkWhatsapp, "_blank")}
                >
                <Icon type="bookOpen" />
                <span>&nbsp;&nbsp;&nbsp;{__("Заказать 250 каталогов")}</span>
            </Button>
            
            
            <br/><br/>
            <div className='book-box' onClick={()=> window.open(cpBig1png, "_blank")}>
                <div className={dowloadCpSizeLeft ? 'book-foto-box-selected' : 'book-foto-box'} 
                    style={{ backgroundImage: `URL(${cpSmall1})`}} />
            </div>
            <div className='book-box' onClick={()=> window.open(cpBig2png, "_blank")}>
                <div className={dowloadCpSizeRight ? 'book-foto2-box-selected' : 'book-foto2-box'}
                    style={{ backgroundImage: `URL(${cpSmall2})`}} />
            </div>

            <div className='book-box-flex'>
            {!dowloadPageSizeLeft && !dowloadPageSizeRight && !dowloadPageSizeLeftQr && !dowloadPageSizeRightQr && !dowloadCpSizeRight &&
                <>
                    {!dowloadCpSizeLeft ? 
                        <Button  onClick={prepareCpSizeLeft}>{__("Подготовить и скачать левую сторону панели управления")}</Button>
                    :
                        <PDFDownloadLink document={<CpBarSizeLeft />} fileName="Control-panel-size-left.pdf">
                            {({ blob, url, loading, error }) => (loading ? 
                            <Button style={{color: 'red'}}>{__("Мы закачиваем каталог в высоком качестве. Это  может занять некоторое время. Пожалуйста подождите...")}</Button> 
                            : 
                            <Button style={{color: 'green'}}>{__("Нажмите эту кнопку, чтобы получить PDF файл. Дождитесь окончания скачивание на ваше устройство...")}</Button>)}
                        </PDFDownloadLink> 
                    }
                </>
            }
                <div className='book-box-flex__empty'></div>
                {!dowloadPageSizeLeft && !dowloadPageSizeRight && !dowloadPageSizeLeftQr && !dowloadPageSizeRightQr && !dowloadCpSizeLeft && 
                    <>
                        {!dowloadCpSizeRight ? 
                            <Button  onClick={prepareCpSizeRight}>{__("Подготовить и скачать правую сторону панели управления")}</Button>
                        :
                            <PDFDownloadLink document={<CpBarSizeRight />} fileName="Control-panel-size-right.pdf">
                                {({ blob, url, loading, error }) => (loading ? 
                                <Button style={{color: 'red'}} >{__("Мы закачиваем каталог в высоком качестве. Это  может занять некоторое время. Пожалуйста подождите...")}</Button> 
                                : 
                                <Button style={{color: 'green'}}>{__("Нажмите эту кнопку, чтобы получить PDF файл. Дождитесь окончания скачивание на ваше устройство...")}</Button>)}
                            </PDFDownloadLink> 
                        }
                    </>
                }
            </div>            
            <br/>
            <Button
                key="add-button" style={{width: "100%", fontSize:24}}
                onClick={()=> window.open(linkWhatsapp, "_blank")}
                >
                <Icon type="bookOpen" />
                <span>&nbsp;&nbsp;&nbsp;{__("Заказать 250 каталогов")}</span>
            </Button>
            
            
            <br/><br/>
            <p>{__("Если вы разрабатывате собственный дизайн, ниже вы найдете еще больше вариантов для вашего меню. Сделайте скриншот нужного QR кода и передейте дизайнеру меню. Вы можете проверить нажать на активные ссылки чтобы открыть нужную категорию.")}</p>

            
                    <div className="catalog-table-settings-screenshot__screenshot-area">
                        <QRCode
                            id="qrCoctails"
                            value={urlCategoryCoctails}
                            size={290}
                            bgColor={"#ffffff"}
                            fgColor={"#000000"}
                            level={"L"}
                            includeMargin={false}
                        
                        />
                        <br />
                        <div className="catalog-table-settings-screenshot__screenshot-area__description">
                            <Icon type="camera" className="catalog-table-settings-screenshot__screenshot-area__description__icon" />&nbsp;
                            <b>{__("Область для снимка экрана")}</b>
                        </div>
                        <p className="linkQr" onClick={() => window.open(urlCategoryCoctails, "_blank")}>{urlCategoryCoctails}</p>
                    </div>
                    <div className="qrComponents-flexBox__box">
                        <p>{__("Этот QR код нужно распечатать в вашем бумажном меню. Данная ссылка откроет раздел: ")}Coctails</p>
                    </div>
                    <br />
                    <br />

                    <div className="catalog-table-settings-screenshot__screenshot-area">
                        <QRCode
                            id="qrCpCoctails"
                            value={urlAdminCoctails}
                            size={290}
                            bgColor={"#c40404"}
                            fgColor={"#ffffff"}
                            level={"L"}
                            includeMargin={false}
                        
                        />
                        <br />
                        <div className="catalog-table-settings-screenshot__screenshot-area__description">
                            <Icon type="camera" className="catalog-table-settings-screenshot__screenshot-area__description__icon" />&nbsp;
                            <b>{__("Область для снимка экрана")}</b>
                        </div>
                        <p className="linkQr" onClick={() => window.open(urlAdminCoctails, "_blank")}>{urlAdminCoctails}</p>
                    </div>
                    <div className="qrComponents-flexBox__box">
                        <p>{__("Эта секретная ссылка откроет страницу для редактирования ")}Coctails</p>
                    </div>
                    <br />
                    <br /> 

                    
                    <div className="catalog-table-settings-screenshot__screenshot-area">
                        <QRCode
                            id="qrBeer"
                            value={urlCategoryBeer}
                            size={290}
                            bgColor={"#ffffff"}
                            fgColor={"#000000"}
                            level={"L"}
                            includeMargin={false}
                        
                        />
                        <br />
                        <div className="catalog-table-settings-screenshot__screenshot-area__description">
                            <Icon type="camera" className="catalog-table-settings-screenshot__screenshot-area__description__icon" />&nbsp;
                            <b>{__("Область для снимка экрана")}</b>
                        </div>
                        <p className="linkQr" onClick={() => window.open(urlCategoryBeer, "_blank")}>{urlCategoryBeer}</p>
                    </div>
                    <div className="qrComponents-flexBox__box">
                        <p>{__("Этот QR код нужно распечатать в вашем бумажном меню. Данная ссылка откроет раздел: ")}Beer</p>
                    </div>
                    <br />
                    <br />

                    <div className="catalog-table-settings-screenshot__screenshot-area">
                        <QRCode
                            id="qrCpBeer"
                            value={urlAdminBeer}
                            size={290}
                            bgColor={"#c40404"}
                            fgColor={"#ffffff"}
                            level={"L"}
                            includeMargin={false}
                        
                        />
                        <br />
                        <div className="catalog-table-settings-screenshot__screenshot-area__description">
                            <Icon type="camera" className="catalog-table-settings-screenshot__screenshot-area__description__icon" />&nbsp;
                            <b>{__("Область для снимка экрана")}</b>
                        </div>
                        <p className="linkQr" onClick={() => window.open(urlAdminBeer, "_blank")}>{urlAdminBeer}</p>
                    </div>
                    <div className="qrComponents-flexBox__box">
                        <p>{__("Эта секретная ссылка откроет страницу для редактирования ")}Beer</p>
                    </div>
                    <br />
                    <br /> 


            
                    <div className="catalog-table-settings-screenshot__screenshot-area">
                        <QRCode
                            id="qrWine"
                            value={urlCategoryWine}
                            size={290}
                            bgColor={"#ffffff"}
                            fgColor={"#000000"}
                            level={"L"}
                            includeMargin={false}
                        
                        />
                        <br />
                        <div className="catalog-table-settings-screenshot__screenshot-area__description">
                            <Icon type="camera" className="catalog-table-settings-screenshot__screenshot-area__description__icon" />&nbsp;
                            <b>{__("Область для снимка экрана")}</b>
                        </div>
                        <p className="linkQr" onClick={() => window.open(urlCategoryWine, "_blank")}>{urlCategoryWine}</p>
                    </div>
                    <div className="qrComponents-flexBox__box">
                        <p>{__("Этот QR код нужно распечатать в вашем бумажном меню. Данная ссылка откроет раздел: ")}Wine</p>
                    </div>
                    <br />
                    <br />

                    <div className="catalog-table-settings-screenshot__screenshot-area">
                        <QRCode
                            id="qrCpWine"
                            value={urlAdminWine}
                            size={290}
                            bgColor={"#c40404"}
                            fgColor={"#ffffff"}
                            level={"L"}
                            includeMargin={false}
                        
                        />
                        <br />
                        <div className="catalog-table-settings-screenshot__screenshot-area__description">
                            <Icon type="camera" className="catalog-table-settings-screenshot__screenshot-area__description__icon" />&nbsp;
                            <b>{__("Область для снимка экрана")}</b>
                        </div>
                        <p className="linkQr" onClick={() => window.open(urlAdminWine, "_blank")}>{urlAdminWine}</p>
                    </div>
                    <div className="qrComponents-flexBox__box">
                        <p>{__("Эта секретная ссылка откроет страницу для редактирования ")}Wine</p>
                    </div>
                    <br />
                    <br />
                    
            
                    
                    <div className="catalog-table-settings-screenshot__screenshot-area">
                        <QRCode
                            id="qrWhicky"
                            value={urlCategoryWhicky}
                            size={290}
                            bgColor={"#ffffff"}
                            fgColor={"#000000"}
                            level={"L"}
                            includeMargin={false}
                        
                        />
                        <br />
                        <div className="catalog-table-settings-screenshot__screenshot-area__description">
                            <Icon type="camera" className="catalog-table-settings-screenshot__screenshot-area__description__icon" />&nbsp;
                            <b>{__("Область для снимка экрана")}</b>
                        </div>
                        <p className="linkQr" onClick={() => window.open(urlCategoryWhicky, "_blank")}>{urlCategoryWhicky}</p>
                    </div>
                    <div className="qrComponents-flexBox__box">
                        <p>{__("Этот QR код нужно распечатать в вашем бумажном меню. Данная ссылка откроет раздел: ")}Whicky</p>
                    </div>
                    <br />
                    <br />

                    <div className="catalog-table-settings-screenshot__screenshot-area">
                        <QRCode
                            id="qrCpWhicky"
                            value={urlAdminWhicky}
                            size={290}
                            bgColor={"#c40404"}
                            fgColor={"#ffffff"}
                            level={"L"}
                            includeMargin={false}
                        
                        />
                        <br />
                        <div className="catalog-table-settings-screenshot__screenshot-area__description">
                            <Icon type="camera" className="catalog-table-settings-screenshot__screenshot-area__description__icon" />&nbsp;
                            <b>{__("Область для снимка экрана")}</b>
                        </div>
                        <p className="linkQr" onClick={() => window.open(urlAdminWhicky, "_blank")}>{urlAdminWhicky}</p>
                    </div>
                    <div className="qrComponents-flexBox__box">
                        <p>{__("Эта секретная ссылка откроет страницу для редактирования ")}Whicky</p>
                    </div>
                    <br />
                    <br />


                
                    <div className="catalog-table-settings-screenshot__screenshot-area">
                        <QRCode
                            id="qrBrandy"
                            value={urlCategoryBrandy}
                            size={290}
                            bgColor={"#ffffff"}
                            fgColor={"#000000"}
                            level={"L"}
                            includeMargin={false}
                        
                        />
                        <br />
                        <div className="catalog-table-settings-screenshot__screenshot-area__description">
                            <Icon type="camera" className="catalog-table-settings-screenshot__screenshot-area__description__icon" />&nbsp;
                            <b>{__("Область для снимка экрана")}</b>
                        </div>
                        <p className="linkQr" onClick={() => window.open(urlCategoryBrandy, "_blank")}>{urlCategoryBrandy}</p>
                    </div>
                    <div className="qrComponents-flexBox__box">
                        <p>{__("Этот QR код нужно распечатать в вашем бумажном меню. Данная ссылка откроет раздел: ")}Brandy</p>
                    </div>
                    <br />
                    <br />

                    <div className="catalog-table-settings-screenshot__screenshot-area">
                        <QRCode
                            id="qrCpBrandy"
                            value={urlAdminBrandy}
                            size={290}
                            bgColor={"#c40404"}
                            fgColor={"#ffffff"}
                            level={"L"}
                            includeMargin={false}
                        
                        />
                        <br />
                        <div className="catalog-table-settings-screenshot__screenshot-area__description">
                            <Icon type="camera" className="catalog-table-settings-screenshot__screenshot-area__description__icon" />&nbsp;
                            <b>{__("Область для снимка экрана")}</b>
                        </div>
                        <p className="linkQr" onClick={() => window.open(urlAdminBrandy, "_blank")}>{urlAdminBrandy}</p>
                    </div>
                    <div className="qrComponents-flexBox__box">
                        <p>{__("Эта секретная ссылка откроет страницу для редактирования ")}Brandy</p>
                    </div>
                    <br />
                    <br />
                    
                    
                    
                    <div className="catalog-table-settings-screenshot__screenshot-area">
                        <QRCode
                            id="qrCoffee"
                            value={urlCategoryCoffee}
                            size={290}
                            bgColor={"#ffffff"}
                            fgColor={"#000000"}
                            level={"L"}
                            includeMargin={false}
                        
                        />
                        <br />
                        <div className="catalog-table-settings-screenshot__screenshot-area__description">
                            <Icon type="camera" className="catalog-table-settings-screenshot__screenshot-area__description__icon" />&nbsp;
                            <b>{__("Область для снимка экрана")}</b>
                        </div>
                        <p className="linkQr" onClick={() => window.open(urlCategoryCoffee, "_blank")}>{urlCategoryCoffee}</p>
                    </div>
                    <div className="qrComponents-flexBox__box">
                        <p>{__("Этот QR код нужно распечатать в вашем бумажном меню. Данная ссылка откроет раздел: ")}Coffee</p>
                    </div>
                    <br />
                    <br />

                    <div className="catalog-table-settings-screenshot__screenshot-area">
                        <QRCode
                            id="qrCpCoffee"
                            value={urlAdminCoffee}
                            size={290}
                            bgColor={"#c40404"}
                            fgColor={"#ffffff"}
                            level={"L"}
                            includeMargin={false}
                        
                        />
                        <br />
                        <div className="catalog-table-settings-screenshot__screenshot-area__description">
                            <Icon type="camera" className="catalog-table-settings-screenshot__screenshot-area__description__icon" />&nbsp;
                            <b>{__("Область для снимка экрана")}</b>
                        </div>
                        <p className="linkQr" onClick={() => window.open(urlAdminCoffee, "_blank")}>{urlAdminCoffee}</p>
                    </div>
                    <div className="qrComponents-flexBox__box">
                        <p>{__("Эта секретная ссылка откроет страницу для редактирования ")}Coffee</p>
                    </div>
                    <br />
                    <br />
                    
                    
                    
                    <div className="catalog-table-settings-screenshot__screenshot-area">
                        <QRCode
                            id="qrVodka"
                            value={urlCategoryVodka}
                            size={290}
                            bgColor={"#ffffff"}
                            fgColor={"#000000"}
                            level={"L"}
                            includeMargin={false}
                        
                        />
                        <br />
                        <div className="catalog-table-settings-screenshot__screenshot-area__description">
                            <Icon type="camera" className="catalog-table-settings-screenshot__screenshot-area__description__icon" />&nbsp;
                            <b>{__("Область для снимка экрана")}</b>
                        </div>
                        <p className="linkQr" onClick={() => window.open(urlCategoryVodka, "_blank")}>{urlCategoryVodka}</p>
                    </div>
                    <div className="qrComponents-flexBox__box">
                        <p>{__("Этот QR код нужно распечатать в вашем бумажном меню. Данная ссылка откроет раздел: ")}Vodka</p>
                    </div>
                    <br />
                    <br />

                    <div className="catalog-table-settings-screenshot__screenshot-area">
                        <QRCode
                            id="qrCpVodka"
                            value={urlAdminVodka}
                            size={290}
                            bgColor={"#c40404"}
                            fgColor={"#ffffff"}
                            level={"L"}
                            includeMargin={false}
                        
                        />
                        <br />
                        <div className="catalog-table-settings-screenshot__screenshot-area__description">
                            <Icon type="camera" className="catalog-table-settings-screenshot__screenshot-area__description__icon" />&nbsp;
                            <b>{__("Область для снимка экрана")}</b>
                        </div>
                        <p className="linkQr" onClick={() => window.open(urlAdminVodka, "_blank")}>{urlAdminVodka}</p>
                    </div>
                    <div className="qrComponents-flexBox__box">
                        <p>{__("Эта секретная ссылка откроет страницу для редактирования ")}Vodka</p>
                    </div>
                    <br />
                    <br />
                    
                    
                    
                    <div className="catalog-table-settings-screenshot__screenshot-area">
                        <QRCode
                            id="qrGin"
                            value={urlCategoryGin}
                            size={290}
                            bgColor={"#ffffff"}
                            fgColor={"#000000"}
                            level={"L"}
                            includeMargin={false}
                        
                        />
                        <br />
                        <div className="catalog-table-settings-screenshot__screenshot-area__description">
                            <Icon type="camera" className="catalog-table-settings-screenshot__screenshot-area__description__icon" />&nbsp;
                            <b>{__("Область для снимка экрана")}</b>
                        </div>
                        <p className="linkQr" onClick={() => window.open(urlCategoryGin, "_blank")}>{urlCategoryGin}</p>
                    </div>
                    <div className="qrComponents-flexBox__box">
                        <p>{__("Этот QR код нужно распечатать в вашем бумажном меню. Данная ссылка откроет раздел: ")}Gin</p>
                    </div>
                    <br />
                    <br />

                    <div className="catalog-table-settings-screenshot__screenshot-area">
                        <QRCode
                            id="qrCpGin"
                            value={urlAdminGin}
                            size={290}
                            bgColor={"#c40404"}
                            fgColor={"#ffffff"}
                            level={"L"}
                            includeMargin={false}
                        
                        />
                        <br />
                        <div className="catalog-table-settings-screenshot__screenshot-area__description">
                            <Icon type="camera" className="catalog-table-settings-screenshot__screenshot-area__description__icon" />&nbsp;
                            <b>{__("Область для снимка экрана")}</b>
                        </div>
                        <p className="linkQr" onClick={() => window.open(urlAdminGin, "_blank")}>{urlAdminGin}</p>
                    </div>
                    <div className="qrComponents-flexBox__box">
                        <p>{__("Эта секретная ссылка откроет страницу для редактирования ")}Gin</p>
                    </div>
                    <br />
                    <br />
                    
                

                
                <div className="catalog-table-settings-screenshot__screenshot-area">
                        <QRCode
                            id="qrRum"
                            value={urlCategoryRum}
                            size={290}
                            bgColor={"#ffffff"}
                            fgColor={"#000000"}
                            level={"L"}
                            includeMargin={false}
                        
                        />
                        <br />
                        <div className="catalog-table-settings-screenshot__screenshot-area__description">
                            <Icon type="camera" className="catalog-table-settings-screenshot__screenshot-area__description__icon" />&nbsp;
                            <b>{__("Область для снимка экрана")}</b>
                        </div>
                        <p className="linkQr" onClick={() => window.open(urlCategoryRum, "_blank")}>{urlCategoryRum}</p>
                    </div>
                    <div className="qrComponents-flexBox__box">
                        <p>{__("Этот QR код нужно распечатать в вашем бумажном меню. Данная ссылка откроет раздел: ")}Rum</p>
                    </div>
                    <br />
                    <br />

                    <div className="catalog-table-settings-screenshot__screenshot-area">
                        <QRCode
                            id="qrCpRum"
                            value={urlAdminRum}
                            size={290}
                            bgColor={"#c40404"}
                            fgColor={"#ffffff"}
                            level={"L"}
                            includeMargin={false}
                        
                        />
                        <br />
                        <div className="catalog-table-settings-screenshot__screenshot-area__description">
                            <Icon type="camera" className="catalog-table-settings-screenshot__screenshot-area__description__icon" />&nbsp;
                            <b>{__("Область для снимка экрана")}</b>
                        </div>
                        <p className="linkQr" onClick={() => window.open(urlAdminRum, "_blank")}>{urlAdminRum}</p>
                    </div>
                    <div className="qrComponents-flexBox__box">
                        <p>{__("Эта секретная ссылка откроет страницу для редактирования ")}Rum</p>
                    </div>
                    <br />
                    <br />
                    
                    
                    
                    
                    <div className="catalog-table-settings-screenshot__screenshot-area">
                        <QRCode
                            id="qrOther"
                            value={urlCategoryOther}
                            size={290}
                            bgColor={"#ffffff"}
                            fgColor={"#000000"}
                            level={"L"}
                            includeMargin={false}
                        
                        />
                        <br />
                        <div className="catalog-table-settings-screenshot__screenshot-area__description">
                            <Icon type="camera" className="catalog-table-settings-screenshot__screenshot-area__description__icon" />&nbsp;
                            <b>{__("Область для снимка экрана")}</b>
                        </div>
                        <p className="linkQr" onClick={() => window.open(urlCategoryOther, "_blank")}>{urlCategoryOther}</p>
                    </div>
                    <div className="qrComponents-flexBox__box">
                        <p>{__("Этот QR код нужно распечатать в вашем бумажном меню. Данная ссылка откроет раздел: ")}Other</p>
                    </div>
                    <br />
                    <br />

                    <div className="catalog-table-settings-screenshot__screenshot-area">
                        <QRCode
                            id="qrCpOther"
                            value={urlAdminOther}
                            size={290}
                            bgColor={"#c40404"}
                            fgColor={"#ffffff"}
                            level={"L"}
                            includeMargin={false}
                        
                        />
                        <br />
                        <div className="catalog-table-settings-screenshot__screenshot-area__description">
                            <Icon type="camera" className="catalog-table-settings-screenshot__screenshot-area__description__icon" />&nbsp;
                            <b>{__("Область для снимка экрана")}</b>
                        </div>
                        <p className="linkQr" onClick={() => window.open(urlAdminOther, "_blank")}>{urlAdminOther}</p>
                    </div>
                    <div className="qrComponents-flexBox__box">
                        <p>{__("Эта секретная ссылка откроет страницу для редактирования ")}Other</p>
                    </div>
                    <br />
                    <br />
                    
                    
                    
                    
                    <div className="catalog-table-settings-screenshot__screenshot-area">
                        <QRCode
                            id="qrSoftDrinks"
                            value={urlCategoryDrinks}
                            size={290}
                            bgColor={"#ffffff"}
                            fgColor={"#000000"}
                            level={"L"}
                            includeMargin={false}
                        
                        />
                        <br />
                        <div className="catalog-table-settings-screenshot__screenshot-area__description">
                            <Icon type="camera" className="catalog-table-settings-screenshot__screenshot-area__description__icon" />&nbsp;
                            <b>{__("Область для снимка экрана")}</b>
                        </div>
                        <p className="linkQr" onClick={() => window.open(urlCategoryDrinks, "_blank")}>{urlCategoryDrinks}</p>
                    </div>
                    <div className="qrComponents-flexBox__box">
                        <p>{__("Этот QR код нужно распечатать в вашем бумажном меню. Данная ссылка откроет раздел: ")}Drinks</p>
                    </div>
                    <br />
                    <br />

                    <div className="catalog-table-settings-screenshot__screenshot-area">
                        <QRCode
                            id="qrCpSoftDrinks"
                            value={urlAdminDrinks}
                            size={290}
                            bgColor={"#c40404"}
                            fgColor={"#ffffff"}
                            level={"L"}
                            includeMargin={false}
                        
                        />
                        <br />
                        <div className="catalog-table-settings-screenshot__screenshot-area__description">
                            <Icon type="camera" className="catalog-table-settings-screenshot__screenshot-area__description__icon" />&nbsp;
                            <b>{__("Область для снимка экрана")}</b>
                        </div>
                        <p className="linkQr" onClick={() => window.open(urlAdminDrinks, "_blank")}>{urlAdminDrinks}</p>
                    </div>
                    <div className="qrComponents-flexBox__box">
                        <p>{__("Эта секретная ссылка откроет страницу для редактирования ")}Drinks</p>
                    </div>
                    <br />
                    <br />
                    
                    
                    
                    
                    <div className="catalog-table-settings-screenshot__screenshot-area">
                        <QRCode
                            id="qrHookah"
                            value={urlCategoryHookah}
                            size={290}
                            bgColor={"#ffffff"}
                            fgColor={"#000000"}
                            level={"L"}
                            includeMargin={false}
                        
                        />
                        <br />
                        <div className="catalog-table-settings-screenshot__screenshot-area__description">
                            <Icon type="camera" className="catalog-table-settings-screenshot__screenshot-area__description__icon" />&nbsp;
                            <b>{__("Область для снимка экрана")}</b>
                        </div>
                        <p className="linkQr" onClick={() => window.open(urlCategoryHookah, "_blank")}>{urlCategoryHookah}</p>
                    </div>
                    <div className="qrComponents-flexBox__box">
                        <p>{__("Этот QR код нужно распечатать в вашем бумажном меню. Данная ссылка откроет раздел: ")}Hookah</p>
                    </div>
                    <br />
                    <br />

                    <div className="catalog-table-settings-screenshot__screenshot-area">
                        <QRCode
                            id="qrCpHookah"
                            value={urlAdminHookah}
                            size={290}
                            bgColor={"#c40404"}
                            fgColor={"#ffffff"}
                            level={"L"}
                            includeMargin={false}
                        
                        />
                        <br />
                        <div className="catalog-table-settings-screenshot__screenshot-area__description">
                            <Icon type="camera" className="catalog-table-settings-screenshot__screenshot-area__description__icon" />&nbsp;
                            <b>{__("Область для снимка экрана")}</b>
                        </div>
                        <p className="linkQr" onClick={() => window.open(urlAdminHookah, "_blank")}>{urlAdminHookah}</p>
                    </div>
                    <div className="qrComponents-flexBox__box">
                        <p>{__("Эта секретная ссылка откроет страницу для редактирования ")}Hookah</p>
                    </div>
                    <br />
                    <br />
                    
                    
                    
                    <div className="catalog-table-settings-screenshot__screenshot-area">
                        <QRCode
                            id="qrTequila"
                            value={urlCategoryTequila}
                            size={290}
                            bgColor={"#ffffff"}
                            fgColor={"#000000"}
                            level={"L"}
                            includeMargin={false}
                        
                        />
                        <br />
                        <div className="catalog-table-settings-screenshot__screenshot-area__description">
                            <Icon type="camera" className="catalog-table-settings-screenshot__screenshot-area__description__icon" />&nbsp;
                            <b>{__("Область для снимка экрана")}</b>
                        </div>
                        <p className="linkQr" onClick={() => window.open(urlCategoryTequila, "_blank")}>{urlCategoryTequila}</p>
                    </div>
                    <div className="qrComponents-flexBox__box">
                        <p>{__("Этот QR код нужно распечатать в вашем бумажном меню. Данная ссылка откроет раздел: ")}Tequila</p>
                    </div>
                    <br />
                    <br />

                    <div className="catalog-table-settings-screenshot__screenshot-area">
                        <QRCode
                            id="qrCpTequila"
                            value={urlAdminTequila}
                            size={290}
                            bgColor={"#c40404"}
                            fgColor={"#ffffff"}
                            level={"L"}
                            includeMargin={false}
                        
                        />
                        <br />
                        <div className="catalog-table-settings-screenshot__screenshot-area__description">
                            <Icon type="camera" className="catalog-table-settings-screenshot__screenshot-area__description__icon" />&nbsp;
                            <b>{__("Область для снимка экрана")}</b>
                        </div>
                        <p className="linkQr" onClick={() => window.open(urlAdminTequila, "_blank")}>{urlAdminTequila}</p>
                    </div>
                    <div className="qrComponents-flexBox__box">
                        <p>{__("Эта секретная ссылка откроет страницу для редактирования ")}Tequila</p>
                    </div>
                    <br />
                    <br />
                    
                    
                    
                    <div className="catalog-table-settings-screenshot__screenshot-area">
                        <QRCode
                            id="qrAperitifs"
                            value={urlCategoryAperitifs}
                            size={290}
                            bgColor={"#ffffff"}
                            fgColor={"#000000"}
                            level={"L"}
                            includeMargin={false}
                        
                        />
                        <br />
                        <div className="catalog-table-settings-screenshot__screenshot-area__description">
                            <Icon type="camera" className="catalog-table-settings-screenshot__screenshot-area__description__icon" />&nbsp;
                            <b>{__("Область для снимка экрана")}</b>
                        </div>
                        <p className="linkQr" onClick={() => window.open(urlCategoryAperitifs, "_blank")}>{urlCategoryAperitifs}</p>
                    </div>
                    <div className="qrComponents-flexBox__box">
                        <p>{__("Этот QR код нужно распечатать в вашем бумажном меню. Данная ссылка откроет раздел: ")}Aperitifs</p>
                    </div>
                    <br />
                    <br />

                    <div className="catalog-table-settings-screenshot__screenshot-area">
                        <QRCode
                            id="qrCpAperitifs"
                            value={urlAdminAperitifs}
                            size={290}
                            bgColor={"#c40404"}
                            fgColor={"#ffffff"}
                            level={"L"}
                            includeMargin={false}
                        
                        />
                        <br />
                        <div className="catalog-table-settings-screenshot__screenshot-area__description">
                            <Icon type="camera" className="catalog-table-settings-screenshot__screenshot-area__description__icon" />&nbsp;
                            <b>{__("Область для снимка экрана")}</b>
                        </div>
                        <p className="linkQr" onClick={() => window.open(urlAdminAperitifs, "_blank")}>{urlAdminAperitifs}</p>
                    </div>
                    <div className="qrComponents-flexBox__box">
                        <p>{__("Эта секретная ссылка откроет страницу для редактирования ")}Aperitifs</p>
                    </div>
                    <br />
                    <br />
                    
                    
                    
                    
                    <div className="catalog-table-settings-screenshot__screenshot-area">
                        <QRCode
                            id="qrDegistives"
                            value={urlCategoryDegistives}
                            size={290}
                            bgColor={"#ffffff"}
                            fgColor={"#000000"}
                            level={"L"}
                            includeMargin={false}
                        
                        />
                        <br />
                        <div className="catalog-table-settings-screenshot__screenshot-area__description">
                            <Icon type="camera" className="catalog-table-settings-screenshot__screenshot-area__description__icon" />&nbsp;
                            <b>{__("Область для снимка экрана")}</b>
                        </div>
                        <p className="linkQr" onClick={() => window.open(urlCategoryDegistives, "_blank")}>{urlCategoryDegistives}</p>
                    </div>
                    <div className="qrComponents-flexBox__box">
                        <p>{__("Этот QR код нужно распечатать в вашем бумажном меню. Данная ссылка откроет раздел: ")}Degistives</p>
                    </div>
                    <br />
                    <br />

                    <div className="catalog-table-settings-screenshot__screenshot-area">
                        <QRCode
                            id="qrCpDegistives"
                            value={urlAdminDegistives}
                            size={290}
                            bgColor={"#c40404"}
                            fgColor={"#ffffff"}
                            level={"L"}
                            includeMargin={false}
                        
                        />
                        <br />
                        <div className="catalog-table-settings-screenshot__screenshot-area__description">
                            <Icon type="camera" className="catalog-table-settings-screenshot__screenshot-area__description__icon" />&nbsp;
                            <b>{__("Область для снимка экрана")}</b>
                        </div>
                        <p className="linkQr" onClick={() => window.open(urlAdminDegistives, "_blank")}>{urlAdminDegistives}</p>
                    </div>
                    <div className="qrComponents-flexBox__box">
                        <p>{__("Эта секретная ссылка откроет страницу для редактирования ")}Degistives</p>
                    </div>
                    <br />
                    <br />
                    
                    
                    
                    <div className="catalog-table-settings-screenshot__screenshot-area">
                        <QRCode
                            id="qrSnacks"
                            value={urlCategorySnacks}
                            size={290}
                            bgColor={"#ffffff"}
                            fgColor={"#000000"}
                            level={"L"}
                            includeMargin={false}
                        
                        />
                        <br />
                        <div className="catalog-table-settings-screenshot__screenshot-area__description">
                            <Icon type="camera" className="catalog-table-settings-screenshot__screenshot-area__description__icon" />&nbsp;
                            <b>{__("Область для снимка экрана")}</b>
                        </div>
                        <p className="linkQr" onClick={() => window.open(urlCategorySnacks, "_blank")}>{urlCategorySnacks}</p>
                    </div>
                    <div className="qrComponents-flexBox__box">
                        <p>{__("Этот QR код нужно распечатать в вашем бумажном меню. Данная ссылка откроет раздел: ")}Snacks</p>
                    </div>
                    <br />
                    <br />

                    <div className="catalog-table-settings-screenshot__screenshot-area">
                        <QRCode
                            id="qrCpSnacks"
                            value={urlAdminSnacks}
                            size={290}
                            bgColor={"#c40404"}
                            fgColor={"#ffffff"}
                            level={"L"}
                            includeMargin={false}
                        
                        />
                        <br />
                        <div className="catalog-table-settings-screenshot__screenshot-area__description">
                            <Icon type="camera" className="catalog-table-settings-screenshot__screenshot-area__description__icon" />&nbsp;
                            <b>{__("Область для снимка экрана")}</b>
                        </div>
                        <p className="linkQr" onClick={() => window.open(urlAdminSnacks, "_blank")}>{urlAdminSnacks}</p>
                    </div>
                    <div className="qrComponents-flexBox__box">
                        <p>{__("Эта секретная ссылка откроет страницу для редактирования ")}Snacks</p>
                    </div>
                    <br />
                    <br />
                    
                    
                    
                    <div className="catalog-table-settings-screenshot__screenshot-area">
                        <QRCode
                            id="qrLiquor"
                            value={urlCategoryLiquore}
                            size={290}
                            bgColor={"#ffffff"}
                            fgColor={"#000000"}
                            level={"L"}
                            includeMargin={false}
                        
                        />
                        <br />
                        <div className="catalog-table-settings-screenshot__screenshot-area__description">
                            <Icon type="camera" className="catalog-table-settings-screenshot__screenshot-area__description__icon" />&nbsp;
                            <b>{__("Область для снимка экрана")}</b>
                        </div>
                        <p className="linkQr" onClick={() => window.open(urlCategoryLiquore, "_blank")}>{urlCategoryLiquore}</p>
                    </div>
                    <div className="qrComponents-flexBox__box">
                        <p>{__("Этот QR код нужно распечатать в вашем бумажном меню. Данная ссылка откроет раздел: ")}Liquore</p>
                    </div>
                    <br />
                    <br />

                    <div className="catalog-table-settings-screenshot__screenshot-area">
                        <QRCode
                            id="qrCpLiquor"
                            value={urlAdminLiquore}
                            size={290}
                            bgColor={"#c40404"}
                            fgColor={"#ffffff"}
                            level={"L"}
                            includeMargin={false}
                        
                        />
                        <br />
                        <div className="catalog-table-settings-screenshot__screenshot-area__description">
                            <Icon type="camera" className="catalog-table-settings-screenshot__screenshot-area__description__icon" />&nbsp;
                            <b>{__("Область для снимка экрана")}</b>
                        </div>
                        <p className="linkQr" onClick={() => window.open(urlAdminLiquore, "_blank")}>{urlAdminLiquore}</p>
                    </div>
                    <div className="qrComponents-flexBox__box">
                        <p>{__("Эта секретная ссылка откроет страницу для редактирования ")}Liquore</p>
                    </div>
                    <br />
                    <br />
                    
                
            
            </Slider>
        </React.Fragment>
    );
};


export default Book;
