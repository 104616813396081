import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { uuid } from 'uuidv4';
import { useSelector, useDispatch } from 'react-redux';

import Button from 'components/common/Button';
import Icon from 'components/common/Icon';
import Slider from 'components/common/Slider';
import history from 'utils/history';
import {__} from 'utils/translation';
import { EDIT_CATALOG_TABLE, REMOVE_CATALOG_TABLE, ROTATE_CATALOG_TABLE } from 'constants/actions';

import CatalogItemSettings from './CatalogItemSettings';
import CatalogItem from './CatalogItem';

import './index.scss';

const emptySettings = {
  animation: false,
  guid: null,
  image: '',
  text: '',
  textEn: '',
  description: '',
  textAlt: '',
  storyGuid: null,
  order: null,
  price: '0',
  currency: '',
  timeFrom: '9',
  timeTo: '24',
  unavailableForBooking: false,
  unavailableJoin: true,
  number: '',
  type: 'preview-text',
  mainSeats: '4',
  aditionalSeats: '0',
  unavailableSelfService: false,
  unavailableCallingWaiter: true,
  withChildren: true,
  withAnimals: false,
  forDisabled: true,
  onlyForAdiltos: false,
  forVip: true,
  booking: []
};

const CatalogTablesNew = ({ data }) => {
  const [settingsOpened, setSettingsOpened] = useState(null);
  const [catalogItemData, setСatalogItemData] = useState(emptySettings);
  const [currency, setCurrency] = useState(null);

  const closeCatalogItemsSettings = () => {
    setSettingsOpened(null);
  };

  const onOpenCatalogItemSettings = (catalogItemId) => {
    setSettingsOpened(catalogItemId);
  };

  const dispatch = useDispatch();
  const submitSettings = () => {
    if (catalogItemData.number )
      dispatch({ type: EDIT_CATALOG_TABLE, payload: catalogItemData });
    if (currency !== catalogItemData.currency) {
      catalogTables.forEach(item => {
        dispatch({ type: EDIT_CATALOG_TABLE, payload: {...item, currency: catalogItemData.currency} });
      });
    }
    closeCatalogItemsSettings();
  };

  const { active, paymentData } = useSelector((state) => state.config.account);

  const { catalogTables } = useSelector((state) => state.config.data);
  const { storyGuid } = useSelector((state) => state.config);

  useEffect(() => {
    const currentCatalogItem = catalogTables.find((catalogItem) => catalogItem.guid === settingsOpened);
    const settings = currentCatalogItem || { ...emptySettings };
    if (!settings.guid) {
      settings.guid = uuid();
      settings.currency = catalogTables.filter(e => e.currency)[0]?.currency ?? "";
    }
    setCurrency(settings.currency);
    setСatalogItemData({ ...settings });
  }, [settingsOpened, catalogTables]);

  const onRotate = (guid, order) => {
    dispatch({ type: ROTATE_CATALOG_TABLE, guid, order });
  };

  const removeCatalog = () => {
    dispatch({ type: REMOVE_CATALOG_TABLE, guid: catalogItemData.guid });
    closeCatalogItemsSettings();
  };

  return (
    <React.Fragment>
      <div className="catalogItems">
        <Button
          key="add-button"
          onClick={() => onOpenCatalogItemSettings('')}
          className={classnames(['catalogItem-button', 'tech-button'])}
        >
          <Icon type="plus" />
          <span>{__("Столы")}</span>
        </Button>
        </div>
        {
          data.sort((a, b) => a.number - b.number).map((catalogItem) => <CatalogItem onClick={() => onOpenCatalogItemSettings(catalogItem.guid)}
            key={catalogItem.guid} {...catalogItem} />) 
        }
        
      <Slider
        opened={settingsOpened !== null}
        onClose={closeCatalogItemsSettings}
        onRemoveDown={removeCatalog}
        title= {__("Настройка обеденных столов")}
        onSubmit={submitSettings}
      >
        <CatalogItemSettings {...catalogItemData}  onRotate={onRotate} onChange={(settings) => setСatalogItemData({ ...catalogItemData, ...settings })} />
      </Slider>

    </React.Fragment >
  );
};

CatalogTablesNew.propTypes = {
  data: PropTypes.arrayOf(PropTypes.shape({}))
};

CatalogTablesNew.defaultProps = {
  data: []
};

export default CatalogTablesNew;
