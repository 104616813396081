import React from 'react';
import PropTypes from 'prop-types';
import './index.scss';



const Avatar = ({
    image,
    text,
    onClick
}) => {
    return (
        <>
            <div className="avatar" onClick={onClick} style={{ backgroundImage: `URL(${image})` }} />
            <h1 className="main-page__page1__h1" onClick={onClick}>{text}</h1>
        </>
    );
};

Avatar.propTypes = {
    image: PropTypes.string,
    text: PropTypes.string
};

Avatar.defaultProps = {
    image: undefined,
    text: ''
};

export default Avatar;
