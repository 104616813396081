import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { uuid } from 'uuidv4';
import { useSelector, useDispatch } from 'react-redux';

import Button from 'components/common/Button';
import Icon from 'components/common/Icon';
import Slider from 'components/common/Slider';
import history from 'utils/history';
import {__} from 'utils/translation';
import { EDIT_CATALOG_DRINK, REMOVE_CATALOG_DRINK, ROTATE_CATALOG_DRINK } from 'constants/actions';

import CatalogDrinkSettings from './CatalogDrinkSettings';
import CatalogDrink from './CatalogDrink';

import './index.scss';

const emptySettings = {
  animation: false,
  guid: null,
  image: '',
  text: '',
  textEn: '',
  description: '',
  textAlt: '',
  storyDrinkGuid: null,
  order: null,
  price: '0',
  currency: '',
  timeFrom: '', 
  timeTo: '',
  outOfStock: false,
  number: '',
  allergySoja: false,
  allergyPescado: false,
  allergyMostaza: false,
  allergyMoluscos: false,
  allergyGluten: false,
  allergyCascara: false,
  allergySulfitos: false,
  allergyCrustaseos: false,
  allergyAltramuces: false,
  allergyLacteos: false,
  allergyHuevos: false,
  allergySesamo: false,
  allergyApio: false,
  allergyCacahuetes: false,
  type: 'preview-text',
};

const CatalogDrinks = ({ data }) => {
  const [settingsOpened, setSettingsOpened] = useState(null);
  const [catalogDrinkData, setСatalogDrinkData] = useState(emptySettings);
  const [currency, setCurrency] = useState(null);

  const closeCatalogDrinksSettings = () => {
    setSettingsOpened(null);
  };

  const onOpenCatalogDrinkSettings = (catalogDrinkId) => {
    setSettingsOpened(catalogDrinkId);
  };

  const dispatch = useDispatch();
  const submitSettings = () => {
    if (catalogDrinkData.text || catalogDrinkData.textEn || catalogDrinkData.image)
      dispatch({ type: EDIT_CATALOG_DRINK, payload: catalogDrinkData });
    if (currency !== catalogDrinkData.currency) {
      catalogDrinks.forEach(drink => {
        dispatch({ type: EDIT_CATALOG_DRINK, payload: {...drink, currency: catalogDrinkData.currency} });
      });
    }
    closeCatalogDrinksSettings();
  };

  const { active, paymentData } = useSelector((state) => state.config.account);

  const { catalogDrinks = []} = useSelector((state) => state.config.data);
  const { storyDrinkGuid, storyDrinkGuidSelected } = useSelector((state) => state.config);

  useEffect(() => {
    const currentCatalogDrink = catalogDrinks.find((catalogDrink) => catalogDrink.guid === settingsOpened);
    const settings = currentCatalogDrink || { ...emptySettings };
    if (!settings.guid) {
      settings.guid = uuid();
      settings.currency = catalogDrinks.filter(e => e.currency)[0]?.currency ?? "";
    }
    setCurrency(settings.currency);
    setСatalogDrinkData({ ...settings });
  }, [settingsOpened, catalogDrinks]);

  const onRotate = (guid, order) => {
    dispatch({ type: ROTATE_CATALOG_DRINK, guid, order });
  };

  const removeCatalog = () => {
    dispatch({ type: REMOVE_CATALOG_DRINK, guid: catalogDrinkData.guid });
    closeCatalogDrinksSettings();
  };

  return (
    <React.Fragment>
      
        <div className="catalogItems">
          <Button
            key="add-button"
            onClick={() => onOpenCatalogDrinkSettings('')}
            className={classnames(['catalogItem-button', 'pulse'])}
          >
            <Icon type="plus" />
            <span>{__("Алкоголь")}</span>
          </Button>
        </div>
        {
          data.filter(item => !item.storyDrinkGuid || item.storyDrinkGuid === storyDrinkGuid)
            .sort((a, b) => b.order - a.order).map((catalogDrink) => <CatalogDrink onClick={() => onOpenCatalogDrinkSettings(catalogDrink.guid)}
            key={catalogDrink.guid} {...catalogDrink} />)
        }
        
      <Slider
        opened={settingsOpened !== null}
        onClose={closeCatalogDrinksSettings}
        onRemoveDown={removeCatalog}
        title= {__("Добавление напитков")}
        subtitle= {__("ВАЖНО! СНАЧАЛА СОЗДАВАЙТЕ РАЗДЕЛЫ МЕНЮ, ЗАТЕМ ДОБАВЛЯЙТЕ ЭТУ НАПИТКИ В НУЖНЫЕ РАЗДЕЛЫ!")}
        onSubmit={submitSettings}
      >
        <CatalogDrinkSettings {...catalogDrinkData}  onRotate={onRotate} onChange={(settings) => setСatalogDrinkData({ ...catalogDrinkData, ...settings })} />
      </Slider>

    </React.Fragment >
  );
};

CatalogDrinks.propTypes = {
  data: PropTypes.arrayOf(PropTypes.shape({}))
};

CatalogDrinks.defaultProps = {
  data: []
};

export default CatalogDrinks;
