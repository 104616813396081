import React, { useState, useEffect } from 'react';

import PropTypes from 'prop-types';
import { useSelector, useDispatch } from 'react-redux';
import classnames from 'classnames';
import copy from 'clipboard-copy';
import { ACCOUNT_STATUS_DEMO } from 'constants/accountStatuses';
import { getSearchParams } from 'utils/url';
import { __ } from 'utils/translation';
import Button from 'components/common/Button';
import Icon from 'components/common/Icon';
import Slider from 'components/common/Slider';
import { SAVE_DATA } from 'constants/actions';

import './index.scss';

const Publish = () => {
  const { settingsName } = useSelector((state) => state.config.data);
  const url = `https://maps.menu/${settingsName}`;
  const [urlCopied, setUrlCopied] = useState(false);
  const [saveSite, setSaveSite] = useState(false);
  const { updated, imageUrl, imageSending, data, account } = useSelector((state) => state.config);
  const { appWaiterLink } = useSelector((state) => state.config.data);

  const [settingsOpened, setSettingsOpened] = useState(false);

  const linkWhatsapp = `https://wa.me/34672442251?text=Maps%20Menu%20control%20panel%3A ${window.location.href}`;
  
  // const linkRegWhatsapp = __("https://wa.me/34672442251?text=Чтобы%20завершить%20регистрацию%20отправьте%20нам%20название%20и%20адрес%20вашего%20ресторана%20или%20кафе");
  
  const id = Date.now()
  const createNewCP = `https://dash.maps.menu/?secretKey=${id}`;
  
  var params = getSearchParams(window.location.search);

  let paramsSortCategorias = "";
  let isBook = false;
  if (params['categoriacoctails'] === "" || params['categoriacoctails']) {
    paramsSortCategorias = "categoriacoctails";
    isBook = true;
  };
  if (params['categoriabeer'] === "" || params['categoriabeer']) {
    paramsSortCategorias = "categoriabeer";
    isBook = true;
  };
  if (params['categoriawine'] === "" || params['categoriawine']) {
    paramsSortCategorias = "categoriawine";
    isBook = true;
  };
  if (params['categoriawhicky'] === "" || params['categoriawhicky']) {
    paramsSortCategorias = "categoriawhicky";
    isBook = true;
  };
  if (params['categoriabrandy'] === "" || params['categoriabrandy']) {
    paramsSortCategorias = "categoriabrandy";
    isBook = true;
  };
  if (params['categoriacoffee'] === "" || params['categoriacoffee']) {
    paramsSortCategorias = "categoriacoffee";
    isBook = true;
  };
  if (params['categoriavodka'] === "" || params['categoriavodka']) {
    paramsSortCategorias = "categoriavodka";
    isBook = true;
  };
  if (params['categoriagin'] === "" || params['categoriagin']) {
    paramsSortCategorias = "categoriagin";
    isBook = true;
  };
  if (params['categoriarum'] === "" || params['categoriarum']) {
    paramsSortCategorias = "categoriarum";
    isBook = true;
  };
  if (params['categoriaother'] === "" || params['categoriaother']) {
    paramsSortCategorias = "categoriaother";
    isBook = true;
  };
  if (params['categoriasoftdrinks'] === "" || params['categoriasoftdrinks']) {
    paramsSortCategorias = "categoriasoftdrinks";
    isBook = true;
  };
  if (params['categoriahookah'] === "" || params['categoriahookah']) {
    paramsSortCategorias = "categoriahookah";
    isBook = true;
  };
  if (params['categoriatequila'] === "" || params['categoriatequila']) {
    paramsSortCategorias = "categoriatequila";
    isBook = true;
  };
  if (params['categoriaaperitifs'] === "" || params['categoriaperitifs']) {
    paramsSortCategorias = "categoriaaperitifs";
    isBook = true;
  };
  if (params['categoriadegistives'] === "" || params['categoriadegistives']) {
    paramsSortCategorias = "categoriadegistives";
    isBook = true;
  };
  if (params['categoriasnacks'] === "" || params['categoriasnacks']) {
    paramsSortCategorias = "categoriasnacks";
    isBook = true;
  };

  if (params['categorialiquore'] === "" || params['categorialiquore']) {
    paramsSortCategorias = "categorialiquore";
    isBook = true;
  };

  const openVerifySettings = () => {
    const id = Date.now()
   window.open(`https://dash.maps.menu/?secretKey=${id}`, "_self")
  };
  const closeVerifySettings = () => {
    setSettingsOpened(false);
  };

  useEffect(() => {
    if (!updated) {
      setSaveSite(false);
    }
  }, [updated]);

  const dispatch = useDispatch();
  const saveData = () => {
    setSaveSite(true);
    // разкомментировать чтобы внести изменения
    // dispatch({ type: SAVE_DATA, data })

    // закомментировать чтобы внести изменения
    if (data.settingsName !== "TbRqgtyBBd8MXYxSLzJeAT") {
      dispatch({ type: SAVE_DATA, data });
    };
    if (data.settingsName === "TbRqgtyBBd8MXYxSLzJeAT") {
      setTimeout(() => setSaveSite(false), 4000);
    };

  };

  const onCopy = () => {
    copy(url);
    setUrlCopied(true);
    setTimeout(() => setUrlCopied(false), 2000);
  };

  const clickOnCopy = () => {
    if (data.settingsName !== "TbRqgtyBBd8MXYxSLzJeAT") {
      onCopy();
    };
    if (data.settingsName == "TbRqgtyBBd8MXYxSLzJeAT") {
      openVerifySettings();
    }
  }

  // const link = url;
  // const linkAppWaiter = `${link}?${appWaiterLink}&iamwaiter`;
  // const linkWaiter = `${link}?waiter`;

  const handleOpen = () => {
    window.open(url, "_blank");
  };

  const thisPagaAdminUrl = window.location.href;
  const reLoadPage = () => {
    window.open(thisPagaAdminUrl, "_self");
  }

  const handleOpenAppBook = () => {
    const urlBook = `${url}?${paramsSortCategorias}`
    window.open(urlBook, "_blank");
  };

  const handleOpenApp = () => {
    window.open(url, "_blank");
  };

  

  return (
    <>
      <div className={(updated && imageUrl) ? "publish-fixed" : ""} >
        <div className="publish">
          {(imageSending && !imageUrl) ? 
            <Button onClick={()=>{}} className={classnames(['publish__button'])} noStyled
            >
            {__("Выгружаем новое фото...")}
            </Button> 
          :
            <>
              {updated || saveSite
                ? (
                    <>
                      <Button
                        // onClick={data.settingsName !== "TbRqgtyBBd8MXYxSLzJeAT" ? openVerifySettings : saveData }
                        onClick={data.settingsName === "TbRqgtyBBd8MXYxSLzJeAT" ? openVerifySettings : saveData }
                        className={classnames(['publish__button',
                          { publish__button__copy__already: saveSite }])}
                        noStyled
                      >
                        {data.settingsName !== "TbRqgtyBBd8MXYxSLzJeAT" ?
                          saveSite ? __("Публикуется обновление") : __("Сохрани изменения") :
                          saveSite ? __("text00014") : __("text00014")
                        }
                      </Button>
                    </>
                  )
                : (
                  <>
                      <Button
                        className={classnames(['publish__button', 'publish__button__copy',
                          { publish__button__copy__already: urlCopied }])}
                        noStyled
                        onClick={settingsName? clickOnCopy : reLoadPage }
                      >
                        {data.settingsName !== "TbRqgtyBBd8MXYxSLzJeAT" ?
                          urlCopied ?
                          __("Ссылка скопирована") :
                          settingsName?
                            <input className='linkrenderer' value={url} readOnly={true} /> :
                            <input className='linkrenderer' value={__("text00017")} readOnly={true} /> :
                          __("text00014")}
                      </Button>
                      <Button
                      onClick={handleOpenApp} 
                      className={classnames(['publish__button', 'publish__button__goto'])}
                      noStyled
                    >
                      <Icon type="globe" />
                    </Button>
                      {isBook && 
                        <Button
                          onClick={handleOpenAppBook}
                          className={classnames(['publish__button', 'publish__button__goto-book'])}
                          noStyled
                        >
                        <Icon type="bookOpen" />
                        </Button>
                      }
                    
                </>
                  )
                }
            </>
          }

          {!updated && data.settingsName !== "TbRqgtyBBd8MXYxSLzJeAT" && <div className="publish-notification"><b>{__("СКОПИРУЙ ЭТУ ССЫЛКУ И УСТАНОВИ В GOOGLE MAPS")}</b></div>}
          {data.settingsName == "TbRqgtyBBd8MXYxSLzJeAT" && <div className="publish-notification"><b>{__("text00015")}</b></div>}
        </div>
      </div>

      {data.settingsName === "TbRqgtyBBd8MXYxSLzJeAT" && <button className="openlink-button button" onClick={()=>{}} type="button">Demo</button>}

      <Slider
        opened={settingsOpened}
        onClose={closeVerifySettings}
        onSubmit={() => setSettingsOpened(false)}
        title={__("Панель управления")}
        subtitle={__("text00016")}
        linkWhatsapp={settingsName === "TbRqgtyBBd8MXYxSLzJeAT" ? '' : linkWhatsapp}
        submitTitle={settingsName === "TbRqgtyBBd8MXYxSLzJeAT" ? '' : __("Восстановление доступа")}
      >
        {settingsName === "TbRqgtyBBd8MXYxSLzJeAT" ? 
          <>
            <p></p>
          </>
          : <><p>{__("Мы отправим вам постоянную ссылку для этой панели управления.")}</p>
            <p>{__("Внимание!!! Это секретная ссылка, только администратор ресторана может использовать эту ссылку.")}</p></>}
      </Slider> 
    </>
  );
};

Publish.propTypes = {
  url: PropTypes.string
};

Publish.defaultProps = {
  url: null
};

export default Publish;
