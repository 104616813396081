import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import classnames from 'classnames';

import Icon from 'components/common/Icon';
import Button from 'components/common/Button';
import { __ } from 'utils/translation';

const Tariffs = () => {
  const [tariff, setTariff] = useState({});
  const linkEnter = (window.location.href);
  const { settingsName } = useSelector((state) => state.config.data);

  const tariffs = [
    { guid: "1", title: "Cafe", currency: "€", price: "0.00", advanced: null },
    { guid: "2", title: "Pizza", currency: "€", price: "1.00", advanced: ["dish", "orders"] },
    { guid: "3", title: "Restaurant", currency: "€", price: "19.00", advanced: ["dish", "orders", "payments", "analitycs"] }
  ]

  useEffect(() => {
    if (Object.keys(tariff).length === 0) {
      const currentTariff = tariffs[1] || tariffs[0] || {};
      setTariff(currentTariff);
    }
  }, [tariffs, tariff]);

  const selectTariff = (item) => {
    setTariff(item);
  };

  const linkWhatsapp0 = `https://wa.me/34672442251?text=...`;
  const linkWhatsapp1 = `https://wa.me/34672442251?text=${__("Хочу, чтобы вы взяли мое бумажное меню  и  сами сделали мне приложение. Готов оплатить 180 euro.")} ${linkEnter}`;
  const linkWhatsapp2 = `https://wa.me/34672442251?text=${__("Хочу получить исходные файлы и заказать установку на личный сервер за 1000 евро")}. You get a programmer and a marketer with extensive experience in the restaurant business`;
  const linkWhatsapp3 = `https://web.whatsapp.com/send?text=${__("text00010")} ${settingsName}`;
  

  return (
    <React.Fragment>
      <div className="tariffs">

      <Button 
          className="tariffs__buttonGreen"
          noStyled
          onClick={() => {}}
        >
          {__("text00008")}
        </Button>
        
        <Button
          className="tariffs__button "
          noStyled
          onClick={() => window.open(linkWhatsapp3, "_blank")}
        >
          {__("text00009")}
        </Button>
        <p>{__("text00011")}</p>
        
        <hr/>

        <Button
          className="tariffs__button pulse"
          noStyled
          onClick={() => window.open(linkWhatsapp1, "_blank")}
        >
          {__("Хочу, чтобы вы взяли мое бумажное меню  и  сами сделали мне приложение. Готов оплатить 180 euro.")}
        </Button>
        <p>{__("text00012")}</p>
        <hr/>
        <br/>
        

        <Button
          className="tariffs__button"
          noStyled
          onClick={() => window.open(linkWhatsapp2, "_blank")}
        >
          {__("Адаптировать и установить на личный сервер за 1000 евро")}
        </Button>
        <p>{__("text0101")}</p>
        <hr/>

        <Button
          className="tariffs__button"
          noStyled
          onClick={() => window.open(linkWhatsapp0, "_blank")}
        >
          {__("text00007")}
        </Button>
        <br/>
        

        <a href="https://elfsight.io/c/2894562/1088027/13113" target="_blank" id="1088027"><img src="//a.impactradius-go.com/display-ad/13113-1088027" border="0" alt="" width="100%" height="auto"/></a>
      </div>
    </React.Fragment>
  );
};

export default Tariffs;
