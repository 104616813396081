import React, { useState } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

import Icon from 'components/common/Icon';
import Input from 'components/common/Input';
import Popup from 'components/common/Popup';
import CropImage from 'components/common/CropImage';
import { __ } from 'utils/translation';

import './index.scss';

const AvatarSettings = (props) => {
    const [selectedImage, setSelectedImage] = useState(undefined);

    const updateSettings = (avatarType, value) => {
        const {
            onChange,
            ...settings
        } = props;
        onChange({
            ...settings,
            [avatarType]: value
        });
    };

    const {
        link,
        text,
        image,
    } = props;

    const uploadImage = (value) => {
        const imageUrl = value[0];
        setSelectedImage(imageUrl);
    };

    const onCropSave = (value) => {
        updateSettings('image', value);
        setSelectedImage(undefined);
    };

    return (
        <React.Fragment>
            <div className="block-settings">
                <div className="area-box-for-catalogItems">
                    {/* область для инпутов*/}
                    <div className="area-box-for-catalogItems__header">{__("Настройки контента")}</div>
                    <div className="block-settings__settings">

                        <Input
                            className="block-settings__settings__input"
                            value={text}
                            placeholder={__("Вставь готовый текст")}
                            onChange={(value) => updateSettings('text', value)}
                        />

                        <Input
                            className={classnames([
                                'block-settings__settings__image pulse1 icon1',
                                { used: Boolean(image) }
                            ])}
                            type="file"
                            icon="image"
                            onChange={(value) => uploadImage(value)}
                            onClick={image ? () => updateSettings('image', undefined) : undefined}
                        />
                        
                        <Input className="story-settings__settings__input"
                            value={image}
                            name="fieldImagePngLink"
                            type="text"
                            placeholder="or add link to file https://*.PNG"
                            onChange={(value) => updateSettings('image', value)}
                        />
                        {!image
                            ? (
                                <div className="catalogItem-settings__settings__field-header"
                                >
                                    {__("Нажмите на кнопку, чтобы загрузить изображение")}
                                </div>
                            ) : <div className="catalogItem-settings__settings__field-header"
                            >
                                <div className="block-image-box" >
                                    <div className="block-image-box__image" style={{ backgroundImage: `URL(${image})` }} />
                                    {__("Нажмите на кнопку, чтобы удалить загруженное изображение")}
                                </div>
                            </div>}
                        <br />

                        <Input
                            className="block-settings__settings__input"
                            value={link}
                            type="url"
                            placeholder={__("Ссылка (формат https://mysite.com)")}
                            onChange={(value) => updateSettings('link', value)}
                        />
                        <br />
                    </div>
                    <div class="catalogItem-settings__settings__field-header" style={{ color: "red", marginTop: -24, marginLeft: 24, paddingRight: 24 }}>{__("Если вам нужен только сервис отзывов и не нужен maps.menu - удалите название и логотип. Без названия и без логотипа отзывы откроются на сайте Google.")}</div>
                    <br /> {/* область для видео инструкции*/}
                    <div className="settings-video-link-box">
                        <a href="" target="_blank">
                            <div className="settings-video-link-box__flexbox" style={{ marginLeft: 12 }}>
                                <Icon className="settings-video-link-box__flexbox__icon" type="youtube" />
                                <div className="settings-video-link-box__flexbox__text">{__("Нажмите, чтобы посмотреть инструкцию в YouTube")}</div>
                            </div>
                        </a>
                    </div>
                    <br />
                </div>
                <div className="catalogItem-settings__settings__field-header">
                    {__("Область настроек контента")}
                </div>

            </div>

            {
                selectedImage && (
                    <Popup visible={Boolean(selectedImage)}>
                        <CropImage
                            file={selectedImage}
                            onSave={onCropSave}
                        />
                    </Popup>
                )
            }
        </React.Fragment>
    );
};

AvatarSettings.propTypes = {
    link: PropTypes.string,
    text: PropTypes.string,
    image: PropTypes.string,
    onChange: PropTypes.func
};

AvatarSettings.defaultProps = {
    link: '',
    text: '',
    image: '',
    onChange: () => { }
};

export default AvatarSettings;

