import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { uuid } from 'uuidv4';
import { useSelector, useDispatch } from 'react-redux';

import Slider from 'components/common/Slider';
import Button from 'components/common/Button';
import Icon from 'components/common/Icon';
import { __ } from 'utils/translation';
import { EDIT_GOOGLE_MAPS, REMOVE_GOOGLE_MAPS } from 'constants/actions';


import GoogleMapSettings from './GoogleMapSettings';
import GoogleMap from './GoogleMap';
import './index.scss';

const emptySettings = {
  guid: null,
  type: 'free',
  link: '',
  text: '',
  image: ''
};

const GoogleMaps = ({ data }) => {
  const [settingsOpened, setSettingsOpened] = useState(null);
  const [googleMapData, setGoogleMapData] = useState(emptySettings);

  const closeGoogleMapsSettings = () => {
    setSettingsOpened(null);
  };

  const onOpenGoogleMapsSettings = (googleMapId) => {
    setSettingsOpened(googleMapId);
  };

  const dispatch = useDispatch();
  const submitSettings = () => {
    if (googleMapData.text || googleMapData.image)
      dispatch({ type: EDIT_GOOGLE_MAPS, payload: googleMapData });
    closeGoogleMapsSettings();
  };

  const removeSettings = () => {
    dispatch({ type: REMOVE_GOOGLE_MAPS, guid: googleMapData.guid });
    closeGoogleMapsSettings();
  };

  const { googleMaps } = useSelector((state) => state.config.data);

  useEffect(() => {
    const currentGoogleMap = googleMaps.find((googleMap) => googleMap.guid === settingsOpened);
    const settings = currentGoogleMap || { ...emptySettings };
    if (!settings.guid)
      settings.guid = uuid();
    setGoogleMapData({ ...settings });
  }, [settingsOpened, googleMaps]);

  const showHeaderGoogleMaps = googleMaps.length !== 0;

  googleMaps.sort((a, b) => b.order - a.order);
console.log("googleMaps", googleMaps)
 
  return (
    <React.Fragment>
      <div className="google-maps">
        <Button
          key="add-button"
          onClick={() => onOpenGoogleMapsSettings('')}
          className={classnames(['google-maps-button'])}
        >
          <Icon type="plus" />
          <span>Google Maps</span>
        </Button>

        {
          showHeaderGoogleMaps && (
            <h2 className="main-page__page1__h2">{__("Адрес")}</h2>
          )}
        {
          data.map((googleMap) => <GoogleMap onClick={() => onOpenGoogleMapsSettings(googleMap.guid)} key={googleMap.guid} {...googleMap} />)
        }

      </div>

      <Slider
        opened={settingsOpened !== null}
        onClose={closeGoogleMapsSettings}
        title={__("Настройка адреса в Google Maps")}
        subtitle={__("Следуйте видео инструкции чтобы получить правильную ссылку на ваши Google maps. Правильная ссылка поможет проложить маршрут к вашему ресторану или парковке.")}
        onRemove={removeSettings}
        onSubmit={submitSettings}
      >
        <GoogleMapSettings {...googleMapData} onChange={(settings) => setGoogleMapData({ ...googleMapData, ...settings })} />
      </Slider>

    </React.Fragment >
  );
};

GoogleMaps.propTypes = {
  data: PropTypes.arrayOf(PropTypes.shape({}))
};

GoogleMaps.defaultProps = {
  data: []
};

export default GoogleMaps;
