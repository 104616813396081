export const LOADING_ERROR = 'LOADING_ERROR';
export const HIDE_LANDING = 'HIDE_LANDING';

export const BOOKING_ZONES = 'BOOKING_ZONES';
export const BOOKING_DATES = 'BOOKING_DATES';
export const BOOKING_DATES_CLEAR = 'BOOKING_DATES_CLEAR';

export const BOOKING_GROUP_ONE_TABLE = 'BOOKING_GROUP_ONE_TABLE';
export const BOOKING_GROUP_ONE_TABLE_CLEAR = 'BOOKING_GROUP_ONE_TABLE_CLEAR';
export const BOOKING_GROUP_TWO_TABLE = 'BOOKING_GROUP_TWO_TABLE';
export const BOOKING_GROUP_TWO_TABLE_CLEAR = 'BOOKING_GROUP_TWO_TABLE_CLEAR';
export const BOOKING_GROUP_THREE_TABLE = 'BOOKING_GROUP_THREE_TABLE';
export const BOOKING_GROUP_THREE_TABLE_CLEAR = 'BOOKING_GROUP_THREE_TABLE_CLEAR';
export const BOOKING_GROUP_FOUR_TABLE = 'BOOKING_GROUP_FOUR_TABLE';
export const BOOKING_GROUP_FOUR_TABLE_CLEAR = 'BOOKING_GROUP_FOUR_TABLE_CLEAR';

export const BOOKING_GROUP_IS_GROUP = 'BOOKING_GROUP_IS_GROUP';

export const BOOKING_TIMES_FROM = 'BOOKING_TIMES_FROM';
export const BOOKING_TIMES_FROM_CLEAR = 'BOOKING_TIMES_FROM_CLEAR';
export const BOOKING_TIMES_TO = 'BOOKING_TIMES_TO';
export const BOOKING_TIMES_TO_CLEAR = 'BOOKING_TIMES_TO_CLEAR';  
export const BOOKING_PEOPLE = 'BOOKING_PEOPLE';
export const BOOKING_PEOPLE_CLEAR = 'BOOKING_PEOPLE_CLEAR';
export const BOOKING_OPTIONS = 'BOOKING_OPTIONS';



export const CONFIG_LOAD = 'CONFIG_LOAD';
export const CONFIG_LOAD_RECOVERY = 'CONFIG_LOAD_RECOVERY';
export const SET_DATA = 'SET_DATA';

export const CHANGE_THEME = 'CHANGE_THEME';
export const CHANGE_BACKGROUND = 'CHANGE_BACKGROUND';
export const CHANGE_BUTTON_COLOR = 'CHANGE_BUTTON_COLOR';

export const EDIT_BLOCK = 'EDIT_BLOCK';
export const REMOVE_BLOCK = 'REMOVE_BLOCK';
export const ROTATE_BLOCK = 'ROTATE_BLOCK';


export const EDIT_STORY = 'EDIT_STORY';
export const EDIT_STORY_TABLE = 'EDIT_STORY_TABLE';
export const EDIT_STORY_DRINK = 'EDIT_STORY_DRINK';
export const EDIT_STORY_2_ITEMS = 'EDIT_STORY_2_ITEMS';
export const EDIT_STORY_3_ITEMS = 'EDIT_STORY_3_ITEMS';
export const EDIT_STORY_4_ITEMS = 'EDIT_STORY_4_ITEMS';
export const EDIT_STORY_5_ITEMS = 'EDIT_STORY_5_ITEMS';
export const EDIT_STORY_BOTTLES = 'EDIT_STORY_BOTTLES';
export const EDIT_STORY_CAKES = 'EDIT_STORY_CAKES';
export const EDIT_STORY_CIGARETTAS = 'EDIT_STORY_CIGARETTAS';
export const EDIT_STORY_DISHES = 'EDIT_STORY_DISHES';
export const EDIT_STORY_OFFERS = 'EDIT_STORY_OFFERS';
export const EDIT_STORY_SNACKS = 'EDIT_STORY_SNACKS';
export const EDIT_STORY_SOFTDRINKS = 'EDIT_STORY_SOFTDRINKS';


export const REMOVE_STORY = 'REMOVE_STORY';
export const REMOVE_STORY_DRINK = 'REMOVE_STORY_DRINK';
export const REMOVE_STORY_TABLE = 'REMOVE_STORY_TABLE';
export const REMOVE_STORY_2_ITEMS = 'REMOVE_STORY_2_ITEMS';
export const REMOVE_STORY_3_ITEMS = 'REMOVE_STORY_3_ITEMS';
export const REMOVE_STORY_4_ITEMS = 'REMOVE_STORY_4_ITEMS';
export const REMOVE_STORY_5_ITEMS = 'REMOVE_STORY_5_ITEMS';
export const REMOVE_STORY_BOTTLES = 'REMOVE_STORY_BOTTLES';
export const REMOVE_STORY_CAKES = 'REMOVE_STORY_CAKES';
export const REMOVE_STORY_CIGARETTAS = 'REMOVE_STORY_CIGARETTAS';
export const REMOVE_STORY_DISHES = 'REMOVE_STORY_DISHES';
export const REMOVE_STORY_OFFERS = 'REMOVE_STORY_OFFERS';
export const REMOVE_STORY_SNACKS = 'REMOVE_STORY_SNACKS';
export const REMOVE_STORY_SOFTDRINKS = 'REMOVE_STORY_SOFTDRINKS';


export const ROTATE_STORY = 'ROTATE_STORY';
export const ROTATE_STORY_DRINK = 'ROTATE_STORY_DRINK';
export const ROTATE_STORY_TABLE = 'ROTATE_STORY_TABLE';
export const ROTATE_STORY_2_ITEMS = 'ROTATE_STORY_2_ITEMS';
export const ROTATE_STORY_3_ITEMS = 'ROTATE_STORY_3_ITEMS';
export const ROTATE_STORY_4_ITEMS = 'ROTATE_STORY_4_ITEMS';
export const ROTATE_STORY_5_ITEMS = 'ROTATE_STORY_5_ITEMS';
export const ROTATE_STORY_BOTTLES = 'ROTATE_STORY_BOTTLES';
export const ROTATE_STORY_CAKES = 'ROTATE_STORY_CAKES';
export const ROTATE_STORY_CIGARETTAS = 'ROTATE_STORY_CIGARETTAS';
export const ROTATE_STORY_DISHES = 'ROTATE_STORY_DISHES';
export const ROTATE_STORY_OFFERS = 'ROTATE_STORY_OFFERS';
export const ROTATE_STORY_SNACKS = 'ROTATE_STORY_SNACKS';
export const ROTATE_STORY_SOFTDRINKS = 'ROTATE_STORY_SOFTDRINKS';


export const EDIT_CATALOG = 'EDIT_CATALOG';
export const EDIT_CATALOG_DRINK = 'EDIT_CATALOG_DRINK';
export const EDIT_CATALOG_2_ITEMS = 'EDIT_CATALOG_2_ITEMS';
export const EDIT_CATALOG_3_ITEMS = 'EDIT_CATALOG_3_ITEMS';
export const EDIT_CATALOG_4_ITEMS = 'EDIT_CATALOG_4_ITEMS';
export const EDIT_CATALOG_5_ITEMS = 'EDIT_CATALOG_5_ITEMS';
export const EDIT_CATALOG_BOTTLES = 'EDIT_CATALOG_BOTTLES';
export const EDIT_CATALOG_CAKES = 'EDIT_CATALOG_CAKES';
export const EDIT_CATALOG_CIGARETTAS = 'EDIT_CATALOG_CIGARETTAS';
export const EDIT_CATALOG_DISHES = 'EDIT_CATALOG_DISHES';
export const EDIT_CATALOG_OFFERS = 'EDIT_CATALOG_OFFERS';
export const EDIT_CATALOG_SNACKS = 'EDIT_CATALOG_SNACKS';
export const EDIT_CATALOG_SOFTDRINKS = 'EDIT_CATALOG_SOFTDRINKS';
export const EDIT_CATALOG_TABLE = 'EDIT_CATALOG_TABLE';


export const REMOVE_CATALOG  = 'REMOVE_CATALOG';
export const REMOVE_CATALOG_DRINK  = 'REMOVE_CATALOG_DRINK';
export const REMOVE_CATALOG_2_ITEMS  = 'REMOVE_CATALOG_2_ITEMS';
export const REMOVE_CATALOG_3_ITEMS  = 'REMOVE_CATALOG_3_ITEMS';
export const REMOVE_CATALOG_4_ITEMS  = 'REMOVE_CATALOG_4_ITEMS';
export const REMOVE_CATALOG_5_ITEMS  = 'REMOVE_CATALOG_5_ITEMS';
export const REMOVE_CATALOG_BOTTLES  = 'REMOVE_CATALOG_BOTTLES';
export const REMOVE_CATALOG_CAKES  = 'REMOVE_CATALOG_CAKES';
export const REMOVE_CATALOG_CIGARETTAS  = 'REMOVE_CATALOG_CIGARETTAS';
export const REMOVE_CATALOG_DISHES  = 'REMOVE_CATALOG_DISHES';
export const REMOVE_CATALOG_OFFERS  = 'REMOVE_CATALOG_OFFERS';
export const REMOVE_CATALOG_SNACKS  = 'REMOVE_CATALOG_SNACKS';
export const REMOVE_CATALOG_SOFTDRINKS  = 'REMOVE_CATALOG_SOFTDRINKS';
export const REMOVE_CATALOG_TABLE  = 'REMOVE_CATALOG_TABLE';


export const ROTATE_CATALOG  = 'ROTATE_CATALOG';
export const ROTATE_CATALOG_DRINK  = 'ROTATE_CATALOG_DRINK';
export const ROTATE_CATALOG_2_ITEMS  = 'ROTATE_CATALOG_2_ITEMS';
export const ROTATE_CATALOG_3_ITEMS  = 'ROTATE_CATALOG_3_ITEMS';
export const ROTATE_CATALOG_4_ITEMS  = 'ROTATE_CATALOG_4_ITEMS';
export const ROTATE_CATALOG_5_ITEMS  = 'ROTATE_CATALOG_5_ITEMS';
export const ROTATE_CATALOG_BOTTLES  = 'ROTATE_CATALOG_BOTTLES';
export const ROTATE_CATALOG_CAKES  = 'ROTATE_CATALOG_CAKES';
export const ROTATE_CATALOG_CIGARETTAS  = 'ROTATE_CATALOG_CIGARETTAS';
export const ROTATE_CATALOG_DISHES  = 'ROTATE_CATALOG_DISHES';
export const ROTATE_CATALOG_OFFERS  = 'ROTATE_CATALOG_OFFERS';
export const ROTATE_CATALOG_SNACKS  = 'ROTATE_CATALOG_SNACKS';
export const ROTATE_CATALOG_SOFTDRINKS  = 'ROTATE_CATALOG_SOFTDRINKS';
export const ROTATE_CATALOG_TABLE  = 'ROTATE_CATALOG_TABLE';


export const CATALOG_FILTER = 'CATALOG_FILTER';
export const CATALOG_DRINK_FILTER = 'CATALOG_DRINK_FILTER';
export const TABLE_FILTER = 'TABLE_FILTER';
export const CATALOG_2_ITEMS_FILTER = 'CATALOG_2_ITEMS_FILTER';
export const CATALOG_3_ITEMS_FILTER = 'CATALOG_3_ITEMS_FILTER';
export const CATALOG_4_ITEMS_FILTER = 'CATALOG_4_ITEMS_FILTER';
export const CATALOG_5_ITEMS_FILTER = 'CATALOG_5_ITEMS_FILTER';
export const CATALOG_BOTTLES_FILTER = 'CATALOG_BOTTLES_FILTER';
export const CATALOG_CAKES_FILTER = 'CATALOG_CAKES_FILTER';
export const CATALOG_CIGARETTAS_FILTER = 'CATALOG_CIGARETTAS_FILTER';
export const CATALOG_DISHES_FILTER = 'CATALOG_DISHES_FILTER';
export const CATALOG_OFFERS_FILTER = 'CATALOG_OFFERS_FILTER';
export const CATALOG_SNACKS_FILTER = 'CATALOG_SNACKS_FILTER';
export const CATALOG_SOFTDRINKS_FILTER = 'CATALOG_SOFTDRINKS_FILTER';


export const IMPORT_CATALOG = 'IMPORT_CATALOG';
export const IMPORT_CATALOG_DRINK = 'IMPORT_CATALOG_DRINK';
export const IMPORT_CATALOG_TABLE = 'IMPORT_CATALOG_TABLE';
export const IMPORT_CATALOG_2_ITEMS = 'IMPORT_CATALOG_2_ITEMS';
export const IMPORT_CATALOG_3_ITEMS = 'IMPORT_CATALOG_3_ITEMS';
export const IMPORT_CATALOG_4_ITEMS = 'IMPORT_CATALOG_4_ITEMS';
export const IMPORT_CATALOG_5_ITEMS = 'IMPORT_CATALOG_5_ITEMS';
export const IMPORT_CATALOG_BOTTLES = 'IMPORT_CATALOG_BOTTLES';
export const IMPORT_CATALOG_CAKES = 'IMPORT_CATALOG_CAKES';
export const IMPORT_CATALOG_CIGARETTAS = 'IMPORT_CATALOG_CIGARETTAS';
export const IMPORT_CATALOG_DISHES = 'IMPORT_CATALOG_DISHES';
export const IMPORT_CATALOG_OFFERS = 'IMPORT_CATALOG_OFFERS';
export const IMPORT_CATALOG_SNACKS = 'IMPORT_CATALOG_SNACKS';
export const IMPORT_CATALOG_SOFTDRINKS = 'IMPORT_CATALOG_SOFTDRINKS';

export const CATALOG_ORDER = 'CATALOG_ORDER';
export const CATALOG_DRINK_ORDER = 'CATALOG_DRINK_ORDER';
export const CATALOG_DRINK_2_ITEMS_ORDER = 'CATALOG_DRINK_2_ITEMS_ORDER';
export const CATALOG_DRINK_3_ITEMS_ORDER = 'CATALOG_DRINK_3_ITEMS_ORDER';
export const CATALOG_DRINK_4_ITEMS_ORDER = 'CATALOG_DRINK_4_ITEMS_ORDER';
export const CATALOG_DRINK_5_ITEMS_ORDER = 'CATALOG_DRINK_5_ITEMS_ORDER';
export const CATALOG_DRINK_BOTTLES_ORDER = 'CATALOG_DRINK_BOTTLES_ORDER';
export const CATALOG_DRINK_CAKES_ORDER = 'CATALOG_DRINK_CAKES_ORDER';
export const CATALOG_DRINK_CIGARETTAS_ORDER = 'CATALOG_DRINK_CIGARETTAS_ORDER';
export const CATALOG_DRINK_DISHES_ORDER = 'CATALOG_DRINK_DISHES_ORDER';
export const CATALOG_DRINK_OFFERS_ORDER = 'CATALOG_DRINK_OFFERS_ORDER';
export const CATALOG_DRINK_SNACKS_ORDER = 'CATALOG_DRINK_SNACKS_ORDER';
export const CATALOG_DRINK_SOFTDRINKS_ORDER = 'CATALOG_DRINK_SOFTDRINKS_ORDER';

export const EDIT_AD = 'EDIT_AD';
export const REMOVE_AD = 'REMOVE_AD';

export const EDIT_AVATAR = 'EDIT_AVATAR';

export const EDIT_GOOGLE_MAPS = 'EDIT_GOOGLE_MAPS';
export const REMOVE_GOOGLE_MAPS = 'REMOVE_GOOGLE_MAPS';

export const EDIT_MESSENGERS_DATA = 'EDIT_MESSENGERS_DATA';

export const EDIT_REVIEWS_DATA = 'EDIT_REVIEWS_DATA';

export const EDIT_OPENING_HOURS_DATA = 'EDIT_OPENING_HOURS_DATA';

export const EDIT_RESERVATIONS_DATA = 'EDIT_RESERVATIONS_DATA';

export const EDIT_CALL_WAITER_DATA = 'EDIT_CALL_WAITER_DATA';

export const EDIT_DELIVERY_DATA = 'EDIT_DELIVERY_DATA';

export const EDIT_TAKE_AWAY_DATA = 'EDIT_TAKE_AWAY_DATA';

export const EDIT_SOCIAL_DATA = 'EDIT_SOCIAL_DATA';

export const UPDATE_CONFIG_DATA = 'UPDATE_CONFIG_DATA';

export const SAVE_DATA = 'SAVE_DATA';
export const DATA_ALREADY_SAVED = 'DATA_ALREADY_SAVED';

export const REGISTER_USER = 'REGISTER_USER';
export const USER_REGISTERED = 'USER_REGISTERED';

// export const EDIT_RSS_DATA = 'EDIT_RSS_DATA';

// export const SET_INSTAGRAM_FEED = 'SET_INSTAGRAM_FEED';

// export const SET_YOUTUBE_FEED = 'SET_YOUTUBE_FEED';

export const IMAGE_UPLOADED = 'IMAGE_UPLOADED';
export const START_SENDING_IMAGE = 'START_SENDING_IMAGE';
export const IMAGE_ALREADY_SAVED = 'IMAGE_ALREADY_SAVED';

export const IMAGE_STORY_UPLOADED = 'IMAGE_STORY_UPLOADED';


