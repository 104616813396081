import React, { createElement, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { useSelector } from 'react-redux';

import Icon from 'components/common/Icon';
import Button from 'components/common/Button';
import Input from 'components/common/Input';
import Textarea from 'components/common/Textarea';
import Popup from 'components/common/Popup';
import CropImage from 'components/common/CropImage';
import Picker from 'components/common/Picker';
import { __, translatedProperty, translatedPropertyName, supportedLanguages, getDefaultLanguage, translate } from 'utils/translation';
import {
    CATALOG_LEFT,
    CATALOG_CENTER,
    CATALOG_RIGHT,
    CATALOG_HEADER
} from 'constants/catalogTypes';

import CatalogItem from '../CatalogItem';

import './index.scss';

const CatalogItemSettings = (props) => {
    const [selectedImage, setSelectedImage] = useState(undefined);
    const [translateText, setTranslateText] = useState(false);
    const [translateTextAlt, setTranslateTextAlt] = useState(false);
    const [translateDescription, setTranslateDescription] = useState(false);

    const [textLang, setTextLang] = useState(getDefaultLanguage());
    const [textTranslating, setTextTranslating] = useState(false);

    const [textAltLang, setTextAltLang] = useState(getDefaultLanguage());
    const [textAltTranslating, setTextAltTranslating] = useState(false);

    const [descriptionLang, setDescriptionLang] = useState(getDefaultLanguage());
    const [descriptionTranslating, setDescriptionTranslating] = useState(false);

    const [fullSettingsOpend, setFullSettingsOpend] = useState(true);
    const [allergySettingsOpend, setAllergySettingsOpend] = useState(false);
    const [additionalSettingsOpend, setAdditionalSettingsOpend] = useState(false);

    const { storyGuidSelected } = useSelector((state) => state.config);

    const updateSettings = (catalogItemType, value) => {

        // console.log(storyGuidSelected, "storyGuidSelected catalogitemssettings");

        const {
            onChange,
            order,
            onRotate,
            ...settings
        } = props;
        if (catalogItemType === "text" && !translateText) {
            var updating = {
                ...settings,
                text: value,
                storyGuid: storyGuidSelected
            };
            supportedLanguages.forEach(lang => {
                updating[translatedPropertyName("text", lang)] = value;
            });
            onChange(updating);
        } else if (catalogItemType === "text" && translateText) {
            onChange({
                ...settings,
                text: value,
                [translatedPropertyName("text", textLang)]: value
            })
        } else if (catalogItemType.startsWith("text") && !catalogItemType.startsWith("text")) {
            onChange({
                ...settings,
                [catalogItemType]: value
            })
        }

        else if (catalogItemType === "textAlt" && !translateTextAlt) {
            var updating = {
                ...settings,
                textAlt: value
            };
            supportedLanguages.forEach(lang => {
                updating[translatedPropertyName("textAlt", lang)] = value;
            });
            onChange(updating);
        } else if (catalogItemType === "textAlt" && translateTextAlt) {
            onChange({
                ...settings,
                textAlt: value,
                [translatedPropertyName("textAlt", textAltLang)]: value
            })
        } else if (catalogItemType.startsWith("textAlt")) {
            onChange({
                ...settings,
                [catalogItemType]: value
            })
        }

        else if (catalogItemType === "description" && !translateDescription) {
            var updating = {
                ...settings,
                description: value
            };
            supportedLanguages.forEach(lang => {
                updating[translatedPropertyName("description", lang)] = value;
            });
            onChange(updating);
        } else if (catalogItemType === "description" && translateDescription) {
            onChange({
                ...settings,
                description: value,
                [translatedPropertyName("description", descriptionLang)]: value
            })
        } else if (catalogItemType.startsWith("description")) {
            onChange({
                ...settings,
                [catalogItemType]: value,
                storyGuid: storyGuidSelected
            })
        }

        else {
            onChange({
                ...settings,
                [catalogItemType]: value,
                storyGuid: storyGuidSelected
            })
        };
    };

    const {
        animation, guid, image, additionally, storyGuid, order, price, currency, timeFrom, timeTo, outOfStock, number, type, onChange, onRotate,
        allergySoja, allergyPescado, allergyMostaza, allergyMoluscos, allergyGluten, allergyCascara, allergyApio,
        allergySulfitos, allergyCrustaseos, allergyAltramuces, allergyLacteos, allergyHuevos, allergySesamo, allergyCacahuetes

    } = props;



    let text = translatedProperty(props, "text", textLang, true);
    if (!text && props.text && !translateText) {
        text = props.text
    }
    let textAlt = translatedProperty(props, "textAlt", textAltLang, true);
    if (!textAlt && props.textAlt && !translateTextAlt) {
        textAlt = props.textAlt
    }
    let description = translatedProperty(props, "description", descriptionLang, true);
    if (!description && props.description && !translateDescription) {
        description = props.description
    }

    const [imageElements, setImageElements] = useState([]);
    const [lastImageText, setLastImageText] = useState(null);
    const [lastImageGuid, setLastImageGuid] = useState(null);
    const [imagesLoading, setImagesLoading] = useState(false);
    
    useEffect(() => {
        if (lastImageGuid !== guid) {
            setLastImageText(null);
            setImageElements([]);
        }
    }, [guid]);

    const { stories = {} } = useSelector((state) => state.config.data);
    // const storyElements = stories.map((story) => ({
    //     id: story.titleEn,
    //     component: (
    //         <div
    //             onClick={() => updateSettings('storyGuid', story.guid)}  
    //             className="theme-picker"
    //             style={{ backgroundImage: `URL(${story.image})`, backgroundRepeat: 'no-repeat', backgroundPosition: 'center center', backgroundSize: 'cover' }}
    //         />
    //     )
    // }));

    const showTitle = stories.length > 0;

    const uploadImage = (value) => {
        const imageUrl = value[0];
        setSelectedImage(imageUrl);
    };

    const onCropSave = (value) => {
        updateSettings('image', value);
        setSelectedImage(undefined);
    };

    const onOrderUp = !onRotate ? null : () => {
        onRotate(guid, 1);
      };
    
      const onOrderDown = !onRotate ? null : () => {
        onRotate(guid, -1);
    
      };
      
      

    const clickTextTranslate = async () => {
        if (!text) {
            return
        }
        setTextTranslating(true);
        const translated = await translate(text, textLang, "text");

        const {
            onChange,
            order,
            onRotate,
            ...settings
        } = props;
        onChange({
            ...settings,
            ...translated
        });
        setTextTranslating(false);
    }

    const clickTextAltTranslate = async () => {
        if (!textAlt) {
            return
        }
        setTextAltTranslating(true);
        const translated = await translate(textAlt, textAltLang, "textAlt");
        onChange({
            ...props,
            ...translated
        });
        setTextAltTranslating(false);
    }

    const clickDescriptionTranslate = async () => {
        if (!description) {
            return
        }
        setDescriptionTranslating(true);
        const translated = await translate(description, descriptionLang, "description");
        onChange({
            ...props,
            ...translated
        });
        setDescriptionTranslating(false);
    }

    return (
        <React.Fragment>
            {!showTitle && (
                <>
                    <div className="catalogItem-settings__settings__field-header-picker-red">{__("Вы не создали ни одного раздела меню. Сначала закройте эту вкладку, а затем создайте новый раздел меню.")}
                    </div>
                    <br />
                    <div className="settings-video-link-box">
                        <a href="" target="_blank">
                            <div className="settings-video-link-box__flexbox" style={{ marginLeft: 12 }}>
                                <Icon className="settings-video-link-box__flexbox__icon" type="youtube" />
                                <div className="settings-video-link-box__flexbox__text">{__("Нажмите, чтобы посмотреть инструкцию в YouTube")}</div>
                            </div>
                        </a>
                    </div>
                    <br />
                </>
            )
            }

            {showTitle && 
            // storyGuid && 
            (
                <div>
                    <div className="catalogItem-settings">                        




                        {!fullSettingsOpend ?
                            <>
                                <div className="area-box-for-catalogItems" onClick={() => setFullSettingsOpend(true)}>
                                    <div className="catalogItem-settings-field" >
                                        <Icon className="catalogItem-open-settings-field__icon" type="plusCircle" />
                                        <div className="catalogItem-open-settings-field__span">{__("Основные настройки")}</div>
                                    </div>
                                </div>
                            </>
                            :
                            <>
                                <div className="area-box-for-catalogItems">
                                    <div className="area-box-for-catalogItems__header" onClick={() => setFullSettingsOpend(false)}>{__(" - Основные настройки")}</div>
                                    <div className="catalogItem-settings__settings">

                                        <Input
                                            className="catalogItem-settings__settings__input"
                                            value={price}
                                            name="fieldPriceCaltalog"
                                            type="number"
                                            placeholder={__("Укажите стоимость за 1 ед.")}
                                            onChange={(value) => updateSettings('price', value)}
                                        />
                                        <div className="catalogItem-settings__settings__field-header">{__("Укажите стоимость за 1 ед.")}</div>
                                        <br />

                                        <Input
                                            className={classnames([
                                                'block-settings__settings__image pulse1 icon1',
                                                { used: Boolean(image) }
                                            ])}
                                            name="fieldImageCaltalog"
                                            type="file"
                                            icon="image"
                                            onChange={(value) => uploadImage(value)}
                                            onClick={image ? () => updateSettings('image', undefined) : undefined}
                                        />
                                        <Input className="story-settings__settings__input"
                                            value={image}
                                            name="fieldImagePngLink"
                                            type="text"
                                            placeholder="or add link to file https://*.PNG"
                                            onChange={(value) => updateSettings('image', value)}
                                        />
                                        {!image
                                            ? (
                                                <div className="catalogItem-settings__settings__field-header"
                                                >
                                                    {__("Нажмите на кнопку, чтобы загрузить изображение")}
                                                </div>
                                            ) : <div className="catalogItem-settings__settings__field-header"
                                            >
                                                <div className="block-image-box" >
                                                    <div className="block-image-box__image" style={{ backgroundImage: `URL(${image})` }} />
                                                    {__("Нажмите на кнопку, чтобы удалить загруженное изображение")}
                                                </div>
                                            </div>}
                                        <br />

                                        {!translateText && <>
                                            <div className="catalogItem-settings__settings__field-button"
                                                onClick={() => setTranslateText(true)}
                                            >
                                                {__("Добавить перевод")}
                                            </div>
                                            <Textarea className="catalogItem-settings__settings__input"
                                                value={text}
                                                name="fieldTextCaltalog"
                                                type="text"
                                                placeholder={__("Название блюда")}
                                                onChange={(value) => updateSettings('text', value)}
                                            />
                                            <div className="catalogItem-settings__settings__field-header">{__("Название блюда")}</div>
                                            <br />
                                        </>
                                        }



                                        {translateText && <>
                                            <div className="catalogItem-settings__settings__field-button-off"
                                                onClick={() => setTranslateText(false)}
                                            >
                                                {__("Скрыть переводы")}
                                            </div>

                                            <div className="advanced-settings-items">
                                                <div className="horizontal-picker-lang-buttons">

                                                    {supportedLanguages.map(lang => <div className="picker__item-lang-buttons">
                                                        <div className="picker__item-lang-buttons__box" onClick={() => setTextLang(lang)}>
                                                            {lang != textLang ? <div className="picker__item-lang-buttons__box__content"><p>{lang.toUpperCase()}</p></div> : <div className="picker__item-lang-buttons__box__selected"><p>{lang.toUpperCase()}</p></div>}
                                                        </div>
                                                    </div>)}
                                                </div>
                                            </div>

                                            <Textarea className="catalogItem-settings__settings__input"
                                                value={text}
                                                name="fieldTextCatalog"
                                                type="text"
                                                placeholder={__("Название блюда")}
                                                onChange={(value) => updateSettings('text', value)}
                                            />
                                            <Input
                                                className={classnames([
                                                    'catalogItem-settings__settings__input', 'pulse',
                                                    { used: Boolean(!textTranslating && text) },
                                                ])}
                                                type="file"
                                                icon="language"
                                                onClick={clickTextTranslate}
                                            />
                                            {!textTranslating ?
                                                <div className="settings-video-link-box">
                                                    <a href="https://youtu.be/AVbcZOxSuPE" target="_blank">
                                                        <div className="settings-video-link-box__flexbox">
                                                            <Icon className="settings-video-link-box__flexbox__icon" type="youtube" />
                                                            <div className="settings-video-link-box__flexbox__text">{__("Нажмите, чтобы посмотреть инструкцию в YouTube")}</div>
                                                        </div>
                                                    </a>
                                                </div>
                                                :
                                                <div className="story-settings__settings__field-header__translate">{__("Подождите, мы переводим ваш текст с")}&nbsp;: {textLang.toUpperCase()}</div>
                                            }
                                            <br />
                                        </>
                                        }

                                    </div>

                                    {!translateText ?
                                        <div className="settings-video-link-box">
                                            <a href="" target="_blank">
                                                <div className="settings-video-link-box__flexbox" style={{ marginLeft: 12 }}>
                                                    <Icon className="settings-video-link-box__flexbox__icon" type="youtube" />
                                                    <div className="settings-video-link-box__flexbox__text">{__("Нажмите, чтобы посмотреть инструкцию в YouTube")}</div>
                                                </div>
                                            </a>
                                        </div>
                                        : null}
                                    <br />
                                </div>

                                <div className="catalogItem-settings__settings__field-header">
                                    {__("Область основных настроек")}
                                </div>
                            </>
                        }
                        <br />

                        {!allergySettingsOpend ?
                            <>
                                <div className="area-box-for-catalogItems" onClick={() => setAllergySettingsOpend(true)}>
                                    <div className="catalogItem-settings-field" >
                                        <Icon className="catalogItem-open-settings-field__icon" type="plusCircle" />
                                        <div className="catalogItem-open-settings-field__span">{__("Настройка аллергенов")}</div>
                                    </div>
                                </div>
                            </>
                            : <>
                                <div className="area-box-for-catalogItems">
                                    <div className="area-box-for-catalogItems__header" onClick={() => setAllergySettingsOpend(false)}>{__(" - Настройка аллергенов")}</div>
                                    <div className="catalogItem-settings__settings">

                                        {/* алергия на сою */}

                                        <div className="catalogItem-settings-field"
                                            onClick={allergySoja ? () => updateSettings('allergySoja', false) : () => updateSettings('allergySoja', true)}>
                                            <Icon className="catalogItem-open-settings-field__icon" type={!allergySoja ? "plusCircle" : "check"} />
                                            <div className="catalogItem-open-settings-field__span">{__("Содержит Сою")}</div>
                                        </div>
                                        <div className="catalogItem-open-settings-field__border-bottom" />
                                        <br />

                                        {/* алергия на рыбу */}

                                        <div className="catalogItem-settings-field"
                                            onClick={allergyPescado ? () => updateSettings('allergyPescado', false) : () => updateSettings('allergyPescado', true)}>
                                            <Icon className="catalogItem-open-settings-field__icon" type={!allergyPescado ? "plusCircle" : "check"} />
                                            <div className="catalogItem-open-settings-field__span">{__("Содержит Рыбу")}</div>
                                        </div>
                                        <div className="catalogItem-open-settings-field__border-bottom" />
                                        <br />

                                        {/* алергия на горчицу */}

                                        <div className="catalogItem-settings-field"
                                            onClick={allergyMostaza ? () => updateSettings('allergyMostaza', false) : () => updateSettings('allergyMostaza', true)}>
                                            <Icon className="catalogItem-open-settings-field__icon" type={!allergyMostaza ? "plusCircle" : "check"} />
                                            <div className="catalogItem-open-settings-field__span">{__("Содержит горчицу")}</div>
                                        </div>
                                        <div className="catalogItem-open-settings-field__border-bottom" />
                                        <br />

                                        {/* алергия на молюсков */}

                                        <div className="catalogItem-settings-field"
                                            onClick={allergyMoluscos ? () => updateSettings('allergyMoluscos', false) : () => updateSettings('allergyMoluscos', true)}>
                                            <Icon className="catalogItem-open-settings-field__icon" type={!allergyMoluscos ? "plusCircle" : "check"} />
                                            <div className="catalogItem-open-settings-field__span">{__("Содержит моллюсков")}</div>
                                        </div>
                                        <div className="catalogItem-open-settings-field__border-bottom" />
                                        <br />

                                        {/* алергия на сельдерей" */}

                                        <div className="catalogItem-settings-field"
                                            onClick={allergyApio ? () => updateSettings('allergyApio', false) : () => updateSettings('allergyApio', true)}>
                                            <Icon className="catalogItem-open-settings-field__icon" type={!allergyApio ? "plusCircle" : "check"} />
                                            <div className="catalogItem-open-settings-field__span">{__("Содержит сельдерей")}</div>
                                        </div>
                                        <div className="catalogItem-open-settings-field__border-bottom" />
                                        <br />

                                        {/* алергия на глютен */}

                                        <div className="catalogItem-settings-field"
                                            onClick={allergyGluten ? () => updateSettings('allergyGluten', false) : () => updateSettings('allergyGluten', true)}>
                                            <Icon className="catalogItem-open-settings-field__icon" type={!allergyGluten ? "plusCircle" : "check"} />
                                            <div className="catalogItem-open-settings-field__span">{__("Содержит глютен")}</div>
                                        </div>
                                        <div className="catalogItem-open-settings-field__border-bottom" />
                                        <br />

                                        {/* алергия на орехи */}

                                        <div className="catalogItem-settings-field"
                                            onClick={allergyCascara ? () => updateSettings('allergyCascara', false) : () => updateSettings('allergyCascara', true)}>
                                            <Icon className="catalogItem-open-settings-field__icon" type={!allergyCascara ? "plusCircle" : "check"} />
                                            <div className="catalogItem-open-settings-field__span">{__("Содержит орехи")}</div>
                                        </div>
                                        <div className="catalogItem-open-settings-field__border-bottom" />
                                        <br />

                                        {/* алергия на ракообразных */}

                                        <div className="catalogItem-settings-field"
                                            onClick={allergyCrustaseos ? () => updateSettings('allergyCrustaseos', false) : () => updateSettings('allergyCrustaseos', true)}>
                                            <Icon className="catalogItem-open-settings-field__icon" type={!allergyCrustaseos ? "plusCircle" : "check"} />
                                            <div className="catalogItem-open-settings-field__span">{__("Содержит Ракообразных")}</div>
                                        </div>
                                        <div className="catalogItem-open-settings-field__border-bottom" />
                                        <br />

                                        {/* алергия на Люпины */}

                                        <div className="catalogItem-settings-field"
                                            onClick={allergyAltramuces ? () => updateSettings('allergyAltramuces', false) : () => updateSettings('allergyAltramuces', true)}>
                                            <Icon className="catalogItem-open-settings-field__icon" type={!allergyAltramuces ? "plusCircle" : "check"} />
                                            <div className="catalogItem-open-settings-field__span">{__("Содержит Люпины")}</div>
                                        </div>
                                        <div className="catalogItem-open-settings-field__border-bottom" />
                                        <br />

                                        {/* алергия на лактозу */}

                                        <div className="catalogItem-settings-field"
                                            onClick={allergyLacteos ? () => updateSettings('allergyLacteos', false) : () => updateSettings('allergyLacteos', true)}>
                                            <Icon className="catalogItem-open-settings-field__icon" type={!allergyLacteos ? "plusCircle" : "check"} />
                                            <div className="catalogItem-open-settings-field__span">{__("Содержит лактозу")}</div>
                                        </div>
                                        <div className="catalogItem-open-settings-field__border-bottom" />
                                        <br />

                                        {/* алергия на яйца */}

                                        <div className="catalogItem-settings-field"
                                            onClick={allergyHuevos ? () => updateSettings('allergyHuevos', false) : () => updateSettings('allergyHuevos', true)}>
                                            <Icon className="catalogItem-open-settings-field__icon" type={!allergyHuevos ? "plusCircle" : "check"} />
                                            <div className="catalogItem-open-settings-field__span">{__("Содержит яйца")}</div>
                                        </div>
                                        <div className="catalogItem-open-settings-field__border-bottom" />
                                        <br />

                                        {/* алергия на арахис */}

                                        <div className="catalogItem-settings-field"
                                            onClick={allergyCacahuetes ? () => updateSettings('allergyCacahuetes', false) : () => updateSettings('allergyCacahuetes', true)}>
                                            <Icon className="catalogItem-open-settings-field__icon" type={!allergyCacahuetes ? "plusCircle" : "check"} />
                                            <div className="catalogItem-open-settings-field__span">{__("Содержит арахис")}</div>
                                        </div>
                                        <div className="catalogItem-open-settings-field__border-bottom" />
                                        <br />

                                        {/* алергия на кунжут */}

                                        <div className="catalogItem-settings-field"
                                            onClick={allergySesamo ? () => updateSettings('allergySesamo', false) : () => updateSettings('allergySesamo', true)}>
                                            <Icon className="catalogItem-open-settings-field__icon" type={!allergySesamo ? "plusCircle" : "check"} />
                                            <div className="catalogItem-open-settings-field__span">{__("Содержит кунжутные зерна")}</div>
                                        </div>
                                        <div className="catalogItem-open-settings-field__border-bottom" />
                                        <br />

                                        {/* алергия на диоксиды */}

                                        <div className="catalogItem-settings-field"
                                            onClick={allergySulfitos ? () => updateSettings('allergySulfitos', false) : () => updateSettings('allergySulfitos', true)}>
                                            <Icon className="catalogItem-open-settings-field__icon" type={!allergySulfitos ? "plusCircle" : "check"} />
                                            <div className="catalogItem-open-settings-field__span">{__("Содержит диоксиды серы и сульфиты")}</div>
                                        </div>
                                        <div className="catalogItem-open-settings-field__border-bottom" />
                                        <br />
                                    </div>

                                    {/* ссылка на брощюру */}
                                    <div className="settings-video-link-box">
                                        <a href="http://www.aesan.gob.es/AECOSAN/docs/documentos/seguridad_alimentaria/gestion_riesgos/Cuadriptico_Alergias_Alimentarias_interactivo.pdf" download target="_blank">
                                            <div className="settings-video-link-box__flexbox" style={{ marginLeft: 12 }}>
                                                <Icon className="settings-video-link-box__flexbox__icon-pdf" type="filePdf" />
                                                <div className="settings-video-link-box__flexbox__text">{__("Нажмите, чтобы узнать законы")}</div>
                                            </div>
                                        </a>
                                    </div>
                                    <br />


                                    {/* ссылка на видео инструкцию */}
                                    <div className="settings-video-link-box">
                                        <a href="" target="_blank">
                                            <div className="settings-video-link-box__flexbox" style={{ marginLeft: 12 }}>
                                                <Icon className="settings-video-link-box__flexbox__icon" type="youtube" />
                                                <div className="settings-video-link-box__flexbox__text">{__("Нажмите, чтобы посмотреть инструкцию в YouTube")}</div>
                                            </div>
                                        </a>
                                    </div>
                                    <br />
                                </div>
                                <div className="catalogItem-settings__settings__field-header">
                                    {__("Область настройки аллергенов")}
                                </div>
                            </>
                        }
                        <br />

                        {!additionalSettingsOpend ?
                            <>
                                <div className="area-box-for-catalogItems" onClick={() => setAdditionalSettingsOpend(true)}>
                                    <div className="catalogItem-settings-field" >
                                        <Icon className="catalogItem-open-settings-field__icon" type="plusCircle" />
                                        <div className="catalogItem-open-settings-field__span">{__("Дополнительные настройки")}</div>
                                    </div>
                                </div>
                            </>
                            : <>
                                <div className="area-box-for-catalogItems">
                                    <div className="area-box-for-catalogItems__header" onClick={() => setAdditionalSettingsOpend(false)}>{__(" - Дополнительные настройки")}</div>
                                    <div className="catalogItem-settings__settings">
                                        {!translateTextAlt && <>
                                            <div className="catalogItem-settings__settings__field-button"
                                                onClick={() => setTranslateTextAlt(true)}
                                            >
                                                {__("Добавить перевод")}
                                            </div>

                                            <Textarea className="catalogItem-settings__settings__input"
                                                value={textAlt}
                                                name="fieldTextAltCaltalog"
                                                type="text"
                                                placeholder={__("Укажите ингредиенты или бренд")}
                                                onChange={(value) => updateSettings('textAlt', value)}
                                            />
                                            <div className="catalogItem-settings__settings__field-header">{__("Укажите ингредиенты или бренд")}</div>
                                            <br />
                                        </>
                                        }

                                        {translateTextAlt && <>
                                            <div className="catalogItem-settings__settings__field-button-off"
                                                onClick={() => setTranslateTextAlt(false)}
                                            >
                                                {__("Скрыть переводы")}
                                            </div>

                                            <div className="advanced-settings-items">
                                                <div className="horizontal-picker-lang-buttons">
                                                    {supportedLanguages.map(lang => <div className="picker__item-lang-buttons">
                                                        <div className="picker__item-lang-buttons__box" onClick={() => setTextAltLang(lang)}>
                                                            {lang != textAltLang ? <div className="picker__item-lang-buttons__box__content"><p>{lang.toUpperCase()}</p></div> : <div className="picker__item-lang-buttons__box__selected"><p>{lang.toUpperCase()}</p></div>}
                                                        </div>
                                                    </div>)}
                                                </div>
                                            </div>

                                            <Textarea className="catalogItem-settings__settings__input"
                                                value={textAlt}
                                                name="fieldTextAltCaltalog"
                                                type="text"
                                                placeholder={__("Укажите ингредиенты или бренд")}
                                                onChange={(value) => updateSettings('textAlt', value)}
                                            />
                                            <Input
                                                className={classnames([
                                                    'catalogItem-settings__settings__input','pulse',
                                                    { used: Boolean(!textAltTranslating && textAlt) },
                                                ])}
                                                type="file"
                                                icon="language"
                                                onClick={clickTextAltTranslate}
                                            />
                                            {!textAltTranslating ?
                                                <div className="settings-video-link-box">
                                                    <a href="https://youtu.be/AVbcZOxSuPE" target="_blank">
                                                        <div className="settings-video-link-box__flexbox">
                                                            <Icon className="settings-video-link-box__flexbox__icon" type="youtube" />
                                                            <div className="settings-video-link-box__flexbox__text">{__("Нажмите, чтобы посмотреть инструкцию в YouTube")}</div>
                                                        </div>
                                                    </a>
                                                </div>
                                                :
                                                <div className="story-settings__settings__field-header__translate">{__("Подождите, мы переводим ваш текст с")}&nbsp;: {textAltLang.toUpperCase()}</div>
                                            }
                                            <br />
                                        </>
                                        }


                                        {!translateDescription && <>
                                            <div className="catalogItem-settings__settings__field-button"
                                                onClick={() => setTranslateDescription(true)}
                                            >
                                                {__("Добавить перевод")}
                                            </div>

                                            <Textarea
                                                className="catalogItem-settings__settings__input"
                                                value={description}
                                                name="fieldDescriptionCaltalog"
                                                type="text"
                                                placeholder={__("Укажите вес или емкость, время приготовления блюда, особенности и прочее")}
                                                onChange={(value) => updateSettings('description', value)}
                                            />
                                            <div className="catalogItem-settings__settings__field-header">{__("Укажите вес или емкость, время приготовления блюда, особенности и прочее")}</div>
                                            <br />
                                        </>
                                        }

                                        {translateDescription && <>
                                            <div className="catalogItem-settings__settings__field-button-off"
                                                onClick={() => setTranslateDescription(false)}
                                            >
                                                {__("Скрыть переводы")}
                                            </div>

                                            <div className="advanced-settings-items">
                                                <div className="horizontal-picker-lang-buttons">
                                                    {supportedLanguages.map(lang => <div className="picker__item-lang-buttons">
                                                        <div className="picker__item-lang-buttons__box" onClick={() => setDescriptionLang(lang)}>
                                                            {lang != descriptionLang ? <div className="picker__item-lang-buttons__box__content"><p>{lang.toUpperCase()}</p></div> : <div className="picker__item-lang-buttons__box__selected"><p>{lang.toUpperCase()}</p></div>}
                                                        </div>
                                                    </div>)}
                                                </div>
                                            </div>



                                            <Textarea
                                                className="catalogItem-settings__settings__input"
                                                value={description}
                                                name="fieldDescriptionCaltalog"
                                                type="text"
                                                placeholder={__("Укажите вес или емкость, время приготовления блюда, особенности и прочее")}
                                                onChange={(value) => updateSettings('description', value)}
                                            />
                                            <Input
                                                className={classnames([
                                                    'catalogItem-settings__settings__input', 'pulse',
                                                    { used: Boolean(!descriptionTranslating && description) },
                                                ])}
                                                type="file"
                                                icon="language"
                                                onClick={clickDescriptionTranslate}
                                            />
                                            {!descriptionTranslating ?
                                                <div className="settings-video-link-box">
                                                    <a href="https://youtu.be/AVbcZOxSuPE" target="_blank">
                                                        <div className="settings-video-link-box__flexbox">
                                                            <Icon className="settings-video-link-box__flexbox__icon" type="youtube" />
                                                            <div className="settings-video-link-box__flexbox__text">{__("Нажмите, чтобы посмотреть инструкцию в YouTube")}</div>
                                                        </div>
                                                    </a>
                                                </div>
                                                :
                                                <div className="story-settings__settings__field-header__translate">{__("Подождите, мы переводим ваш текст с")}&nbsp;: {descriptionLang.toUpperCase()}</div>
                                            }
                                            <br />
                                        </>
                                        }


                                            <Input className="catalogItem-settings__settings__input"
                                            value={additionally}
                                            name="fieldNumberCaltalog"
                                            type="text"
                                            placeholder={__("Содержание")}
                                            onChange={(value) => updateSettings('additionally', value)}
                                        />
                                        <div className="catalogItem-settings__settings__field-header">{__("Содержание")}</div>
                                        <br />
                                        
                                        <Input className="catalogItem-settings__settings__input"
                                            value={number}
                                            name="fieldNumberCaltalog"
                                            type="text"
                                            placeholder={__("Артикул")}
                                            onChange={(value) => updateSettings('number', value)}
                                        />
                                        <div className="catalogItem-settings__settings__field-header">{__("Артикул")}</div>
                                        <br />


                                        <Input
                                            className="catalogItem-settings__settings__input"
                                            value={timeFrom}
                                            name="fieldTimeFromCaltalog"
                                            type="number"
                                            placeholder={__("Время (час) старта продаж")}
                                            onChange={(value) => updateSettings('timeFrom', value)}
                                        />
                                        <Input
                                            className="catalogItem-settings__settings__input"
                                            value={timeTo}
                                            name="fieldTimeToCaltalog"
                                            type="number"
                                            placeholder={__("Время (час) окончания продаж")}
                                            onChange={(value) => updateSettings('timeTo', value)}
                                        />
                                        <div className="catalogItem-settings__settings__field-header">{__("Продажи в заданные часы")}</div>
                                        <br />

                                        <Input
                                            className="catalogItem-settings__settings__input"
                                            value={currency}
                                            name="fieldCurrencyCaltalog"
                                            type="text"
                                            placeholder={__("Укажите валюту")}
                                            onChange={(value) => updateSettings('currency', value)}
                                        />
                                        <div className="catalogItem-settings__settings__field-header">{__("Укажите валюту")}</div>
                                        <br />

                                        {(text || image) && (
                                            <div className="catalogItem-settings__types">
                                                {/* {onOrderUp && <div className="catalogItem-settings__types__box-buttons"><Button noStyled onClick={onOrderUp}><Icon type="arrowAltCircleUp" /></Button></div>} */}
                                                <Button
                                                    className={classnames([
                                                        'catalogItem-settings__settings__animation__store',
                                                        { 'catalogItem-settings__settings__animation__store__checked': outOfStock }
                                                    ])}
                                                    onClick={() => updateSettings('outOfStock', !outOfStock)}
                                                    isInline
                                                    noStyled
                                                >
                                                    {outOfStock ? __("Нет в наличии") : __("Есть в наличии")}
                                                </Button>
                                                {/* {onOrderDown && <div className="catalogItem-settings__types__box-buttons"><Button noStyled onClick={onOrderDown}><Icon type="arrowAltCircleDown" /></Button></div>} */}
                                            </div>
                                        )}

                                    </div>
                                    <div className="settings-video-link-box">
                                        <a href="" target="_blank">
                                            <div className="settings-video-link-box__flexbox" style={{ marginLeft: 12 }}>
                                                <Icon className="settings-video-link-box__flexbox__icon" type="youtube" />
                                                <div className="settings-video-link-box__flexbox__text">{__("Нажмите, чтобы посмотреть инструкцию в YouTube")}</div>
                                            </div>
                                        </a>
                                    </div>
                                    <br />
                                </div>
                                <div className="catalogItem-settings__settings__field-header">
                                    {__("Область дополнительных настроек")}
                                </div>
                            </>
                        }
                        <br />

                    </div>
                </div>
            )} 

            {
                selectedImage && (
                    <Popup visible={Boolean(selectedImage)}>
                        <CropImage
                            file={selectedImage}
                            onSave={onCropSave}
                        />
                    </Popup>
                )
            }
        </React.Fragment >
    );
};

CatalogItemSettings.propTypes = {
    animation: PropTypes.bool,
    image: PropTypes.string,
    text: PropTypes.string,
    description: PropTypes.string,
    textAlt: PropTypes.string,
    storyGuid: PropTypes.string,
    additionally: PropTypes.string,
    order: PropTypes.number,
    price: PropTypes.string,
    currency: PropTypes.string,
    timeFrom: PropTypes.string,
    timeTo: PropTypes.string,
    outOfStock: PropTypes.bool,
    number: PropTypes.string,
    type: PropTypes.string,
    onChange: PropTypes.func,

};

CatalogItemSettings.defaultProps = {
    animation: false,
    image: '',
    text: '',
    description: '',
    textAlt: '',
    storyGuid: '',
    order: 0,
    price: '',
    currency: '',
    timeFrom: '',
    timeTo: '',
    additionally: '',
    outOfStock: false,
    number: '',
    type: 'preview-text',
    onChange: () => { },

};

export default CatalogItemSettings;

