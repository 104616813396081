import React, { useEffect } from 'react';
import { useDispatch} from 'react-redux';
import { CONFIG_LOAD_RECOVERY, UPDATE_CONFIG_DATA } from 'constants/actions';
import API from 'utils/api';


const Recovery = ({oldPageMenuLink}) => {   
const url = new URL(oldPageMenuLink);
const profile = url.pathname.replace(/\\|\//g,'');

    const dispatch = useDispatch();
    useEffect(() => {
        API.updateProfile(profile);

        dispatch({ type: CONFIG_LOAD_RECOVERY});
        dispatch({ type: UPDATE_CONFIG_DATA});

    }, []);


  return (
    <div></div>
  );
};
  
export default Recovery;