import React from 'react';
import PropTypes from 'prop-types';
import { __ } from 'utils/translation';
import Input from 'components/common/Input';
import './index.scss';

const CallWaiterSettings = ({
  appWaiterLink,
  onChange
}) => (
  <div className="title-settings">

    <Input
      value={appWaiterLink}
      type="text"
      name="appWaiterLinkSettings"
      placeholder="aWErv34D...2Erm"
      onChange={(value) => onChange(value, appWaiterLink)}
    />
  </div>
);

CallWaiterSettings.propTypes = {
  appWaiterLink: PropTypes.string,
  onChange: PropTypes.func
};

CallWaiterSettings.defaultProps = {
  appWaiterLink: '',
  onChange: () => { }
};

export default CallWaiterSettings;
