import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { getSearchParams } from 'utils/url';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { uuid } from 'uuidv4';
import { __ } from 'utils/translation';
import Button from 'components/common/Button';
import Icon from 'components/common/Icon';
import Slider from 'components/common/Slider';

import addedMenu from 'images/addedmenu.jpg';

import { EDIT_STORY, REMOVE_STORY, ROTATE_STORY, CATALOG_FILTER } from 'constants/actions';

import StorySettings from './StorySettings';
import Story from './Story';
import history from 'utils/history';

import './index.scss';
import { concat } from 'lodash';

const emptySettings = {
  guid: null,
  type: 'preview-text',
  order: null,
  linkUrl: '',
  description: '',
  image: '',
};

const Stories = ({ data }) => {
  const [settingsOpened, setSettingsOpened] = useState(null);
  const [storyData, setStoryData] = useState(emptySettings);
  const { storyGuid, storyGuidSelected } = useSelector((state) => state.config);
  const { catalogItems } = useSelector((state) => state.config.data);
  const showDescription = true;
  const closeStoriesSettings = () => {
    setSettingsOpened(null);
  };

  const addStorySettings = () => {
    setSettingsOpened(uuid());
  }

  const onOpenStorySettings = ({ guid }) => {
    setSettingsOpened(guid);
  };

  const dispatch = useDispatch();
  const submitSettings = () => {
    if (storyData.description || storyData.image)
      dispatch({ type: EDIT_STORY, payload: storyData });
    closeStoriesSettings();
  };

  const removeSettings = () => {
    dispatch({ type: REMOVE_STORY, guid: storyData.guid });
    closeStoriesSettings();
  };

  const { stories } = useSelector((state) => state.config.data);
  const { active, paymentData } = useSelector((state) => state.config.account);

  var params = getSearchParams(window.location.search);

  let paramsSortCategorias = "";
  let isBook = false;
  if (params['categoriacoctails'] === "" || params['categoriacoctails']) {
    paramsSortCategorias = "categoria-coctails";
    isBook = true;
  };
  if (params['categoriabeer'] === "" || params['categoriabeer']) {
    paramsSortCategorias = "categoria-beer";
    isBook = true;
  };
  if (params['categoriawine'] === "" || params['categoriawine']) {
    paramsSortCategorias = "categoria-wine";
    isBook = true;
  };
  if (params['categoriawhicky'] === "" || params['categoriawhicky']) {
    paramsSortCategorias = "categoria-whicky";
    isBook = true;
  };
  if (params['categoriabrandy'] === "" || params['categoriabrandy']) {
    paramsSortCategorias = "categoria-brandy";
    isBook = true;
  };
  if (params['categoriacoffee'] === "" || params['categoriacoffee']) {
    paramsSortCategorias = "categoria-coffee";
    isBook = true;
  };
  if (params['categoriavodka'] === "" || params['categoriavodka']) {
    paramsSortCategorias = "categoria-vodka";
    isBook = true;
  };
  if (params['categoriagin'] === "" || params['categoriagin']) {
    paramsSortCategorias = "categoria-gin";
    isBook = true;
  };
  if (params['categoriarum'] === "" || params['categoriarum']) {
    paramsSortCategorias = "categoria-rum";
    isBook = true;
  };
  if (params['categoriaother'] === "" || params['categoriaother']) {
    paramsSortCategorias = "categoria-other";
    isBook = true;
  };
  if (params['categoriasoftdrinks'] === "" || params['categoriasoftdrinks']) {
    paramsSortCategorias = "categoria-soft-drinks";
    isBook = true;
  };
  if (params['categoriahookah'] === "" || params['categoriahookah']) {
    paramsSortCategorias = "categoria-hookah";
    isBook = true;
  };
  if (params['categoriatequila'] === "" || params['categoriatequila']) {
    paramsSortCategorias = "categoria-tequila";
    isBook = true;
  };
  if (params['categoriaaperitifs'] === "" || params['categoriaperitifs']) {
    paramsSortCategorias = "categoria-aperitifs";
    isBook = true;
  };
  if (params['categoriadegistives'] === "" || params['categoriadegistives']) {
    paramsSortCategorias = "categoria-degistives";
    isBook = true;
  };
  if (params['categoriasnacks'] === "" || params['categoriasnacks']) {
    paramsSortCategorias = "categoria-snacks";
    isBook = true;
  };

  if (params['categorialiquore'] === "" || params['categorialiquore']) {
    paramsSortCategorias = "categoria-liquore";
    isBook = true;
  };

 

  useEffect(() => {
    const currentStory = stories.find((story) => story.guid === settingsOpened);
    const settings = currentStory || { ...emptySettings };
    if (!settings.guid && settingsOpened) {
      settings.guid = settingsOpened;
    } else if (!settings.guid) {
      settings.guid = uuid();
    }
    setStoryData({ ...settings });
    
  }, [settingsOpened, stories]);

  const onRotate = (guid, order) => {
    dispatch({ type: ROTATE_STORY, guid, order });
  };

  const setStoryFilter = ({ guid }) => {
    dispatch({ type: CATALOG_FILTER, storyGuid: storyGuid !== guid ? guid : null });
  }

  const handleImport = (data) => {
    dispatch({ type: EDIT_STORY, payload: data });
  }

  stories.sort((a, b) => a.order - b.order);
  return (
    <React.Fragment>
      {data.length > 0 && (
        <div className="stories-theme2">
          <div className="stories-theme2-picker">
            <div className="stories-theme2-picker__empty">
              <div className="stories-theme2-picker-item">
                <div className="stories-theme2-picker-item-flexbox">
                  <div className="stories-theme2-picker-item-flexbox__box" onClick={addStorySettings} style={{ backgroundImage: `URL(${addedMenu})` }} />
                  <Button onClick={addStorySettings} className="stories-theme2-picker-item-flexbox__title">{__("Создайте разделы меню")}</Button>
                  <div className="stories-theme2-picker-item-flexbox__icon" onClick={addStorySettings}><Icon className={classnames(['icon-40'])} type="plusCircle" /></div>
                </div>
              </div>

            {!isBook && 
              <>
                {data.map((story) =>
                  <Story className={classnames(['stories-theme2-picker-item'])}
                    onClick={() => onOpenStorySettings(story)}
                    onFilter={() => setStoryFilter(story)} 
                    key={story.guid} {...story}
                    isBook={isBook}
                    paramsSortCategorias={paramsSortCategorias}
                    dataCatalog = { catalogItems }
                    selected={storyGuidSelected === story.guid} />
                    )}
              </>
            }
            {isBook && 
              <>
                {data.filter(item => (item.linkUrl === paramsSortCategorias))
                .map((story) =>
                  <Story className={classnames(['stories-theme2-picker-item'])}
                    onClick={() => onOpenStorySettings(story)}
                    onFilter={() => setStoryFilter(story)} 
                    key={story.guid} {...story}
                    paramsSortCategorias={paramsSortCategorias}
                    isBook={isBook}
                    dataCatalog = { catalogItems }
                    selected={storyGuidSelected === story.guid} />
                    )}
              </>
            }
            </div>
          </div>
        </div>
      )}

      {data.length === 0 && (<div className="stories-theme2">
        <div className="stories-theme2-picker">
          <div className="stories-theme2-picker__empty">
            <div className="stories-theme2-picker-item">
              <div className="stories-theme2-picker-item-flexbox">
                <div className="stories-theme2-picker-item-flexbox__box" onClick={addStorySettings} style={{ backgroundImage: `URL(${addedMenu})` }} />
                <Button onClick={addStorySettings} className="stories-theme2-picker-item-flexbox__title">{__("Создайте разделы меню")}</Button>
                <div className="stories-theme2-picker-item-flexbox__icon" onClick={addStorySettings}><Icon className={classnames(['icon-40'])} type="plusCircle" /></div>
              </div>
            </div>
            <div className="stories-theme2-picker-item">
              <div className="stories-theme2-picker-item-flexbox">
                <div className="stories-theme2-picker-item-flexbox__box" onClick={addStorySettings} style={{ backgroundImage: `URL(${addedMenu})` }} />
                <Button onClick={addStorySettings} className="stories-theme2-picker-item-flexbox__title">{__("Создайте разделы меню")}</Button>
                <div className="stories-theme2-picker-item-flexbox__icon" onClick={addStorySettings}><Icon className={classnames(['icon-40'])} type="plusCircle" /></div>
              </div>
            </div>
            <div className="stories-theme2-picker-item">
              <div className="stories-theme2-picker-item-flexbox">
                <div className="stories-theme2-picker-item-flexbox__box" onClick={addStorySettings} style={{ backgroundImage: `URL(${addedMenu})` }} />
                <Button onClick={addStorySettings} className="stories-theme2-picker-item-flexbox__title">{__("Создайте разделы меню")}</Button>
                <div className="stories-theme2-picker-item-flexbox__icon" onClick={addStorySettings}><Icon className={classnames(['icon-40'])} type="plusCircle" /></div>
              </div>
            </div>
            <div className="stories-theme2-picker-item">
              <div className="stories-theme2-picker-item-flexbox">
                <div className="stories-theme2-picker-item-flexbox__box" onClick={addStorySettings} style={{ backgroundImage: `URL(${addedMenu})` }} />
                <Button onClick={addStorySettings} className="stories-theme2-picker-item-flexbox__title">{__("Создайте разделы меню")}</Button>
                <div className="stories-theme2-picker-item-flexbox__icon" onClick={addStorySettings}><Icon className={classnames(['icon-40'])} type="plusCircle" /></div>
              </div>
            </div>
            <div className="stories-theme2-picker-item">
              <div className="stories-theme2-picker-item-flexbox">
                <div className="stories-theme2-picker-item-flexbox__box" onClick={addStorySettings} style={{ backgroundImage: `URL(${addedMenu})` }} />
                <Button onClick={addStorySettings} className="stories-theme2-picker-item-flexbox__title">{__("Создайте разделы меню")}</Button>
                <div className="stories-theme2-picker-item-flexbox__icon" onClick={addStorySettings}><Icon className={classnames(['icon-40'])} type="plusCircle" /></div>
              </div>
            </div>
            <div className="stories-theme2-picker-item">
              <div className="stories-theme2-picker-item-flexbox">
                <div className="stories-theme2-picker-item-flexbox__box" onClick={addStorySettings} style={{ backgroundImage: `URL(${addedMenu})` }} />
                <Button onClick={addStorySettings} className="stories-theme2-picker-item-flexbox__title">{__("Создайте разделы меню")}</Button>
                <div className="stories-theme2-picker-item-flexbox__icon" onClick={addStorySettings}><Icon className={classnames(['icon-40'])} type="plusCircle" /></div>
              </div>
            </div>
          </div>
        </div>
      </div>
      )}

      <Slider
        opened={settingsOpened !== null}
        onClose={closeStoriesSettings}
        title={__("Добавление разделов меню")}
        subtitle={
          <React.Fragment>
            <p>{__("Создай разделы меню такие как: Фирменные блюда, Холодные закуски, Салаты, Пицца,  Суп...")}<br />
            </p>


          </React.Fragment>}
        onRemove={settingsOpened && removeSettings}
        onSubmit={submitSettings}
      >
        <StorySettings {...storyData} showDescription={showDescription} isBook={isBook} paramsSortCategorias={paramsSortCategorias} onRotate={onRotate}
          onChange={(settings) => setStoryData({ ...storyData, ...settings })}
          onImport={(settings) => handleImport({ ...storyData, ...settings })} />
      </Slider>

    </React.Fragment>
  );
};

Stories.propTypes = {
  showDescription: PropTypes.bool,
  data: PropTypes.arrayOf(PropTypes.shape({}))
};

Stories.defaultProps = {
  showDescription: true,
  data: []
};

export default Stories;
