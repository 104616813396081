import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import Icon from 'components/common/Icon';
import photo from 'images/photo.png';
import Button from 'components/common/Button';

import { translatedProperty } from 'utils/translation';
import { ROTATE_STORY_DRINK } from 'constants/actions';
import './index.scss';

const StoryDrink = (props) => {
    const {
        guid,
        image,
        onClick,
        onFilter,
        selected, 
        className,
    } = props;
    const title = translatedProperty(props, "title");

    const { catalogDrinks } = useSelector((state) => state.config.data);
    const itemsCount = catalogDrinks.filter(itemDrink => itemDrink.storyDrinkGuid === guid).length;
    const itemsPricedCount = catalogDrinks.filter(itemDrink => itemDrink.storyDrinkGuid === guid && itemDrink.price).length;
    const isFilter = itemsCount > 0;

    const dispatch = useDispatch();
    let order = null;
    
   
    const handleFilter = (e) => {
        if (isFilter && onFilter) {
            onFilter()
            e.stopPropagation();
        }
    }
    
    const onRotate = (guid, order) => {
        dispatch({ type: ROTATE_STORY_DRINK, guid, order });
      };
      
    const handleLeft = (e) => {
        e.stopPropagation();
        onRotate(guid, 1);
        dispatch({ type: ROTATE_STORY_DRINK, payload: { guid, order } });
    }

    const handleRight = (e) => {
        e.stopPropagation();
        onRotate(guid, -1);
        dispatch({ type: ROTATE_STORY_DRINK, payload: { guid, order } });
    }


    const storyDrink = (
        <div className={classnames([className])} onClick={handleFilter}> 
            <div className="story-theme2-picture">
                <div className='story-theme2-picture-box' 
                    style={{ backgroundImage: `URL(${image})` }}>

                    {selected  ? 
                        <>
                            <div className={isFilter ? "story-items-count-up" : "story-items-count-selected-up"}
                        onClick={handleFilter}>{itemsCount}</div>
                                    
                        </>
                    :
                        <>
                            <div className={isFilter ? "story-items-count" : "story-items-count-selected"}
                        onClick={handleFilter}>{itemsCount}</div>
                        </>
                    }  

                    <div className="story-button-move-left"
                        onClick={handleLeft}>
                        <Icon className="story-button-move-left__icon" type="arrowAltCircleLeft" />
                    </div>

                    <div className="story-button-move-center"
                       onClick={onClick}>
                        <Icon className="story-button-move-center__icon" type="camera" />
                    </div>

                    <div className="story-button-move-right"
                        onClick={handleRight}>
                        <Icon className="story-button-move-right__icon" type="arrowAltCircleRight" />
                    </div>                    
                </div>

                {selected  ? 
                        <>
                            <Button className="story-theme2-picture-title-selected" onClick={handleFilter}>{title}</Button>
                                    
                        </>
                    :
                        <>
                            <Button className="story-theme2-picture-title" onClick={handleFilter}>{title}</Button>
                        </>
                    }
                {selected ? 
                    <>
                        <div className="story-picture-title-selected-triangle"/>
                                
                    </>
                :
                    <>
                        <div className="story-picture-title-selected-notriangle"/>
                    </>
                }                
            </div>    
        </div>
    );
    return storyDrink;
}





StoryDrink.propTypes = {
    title: PropTypes.string,
    image: PropTypes.string,

};

StoryDrink.defaultProps = {
    title: '',
    image: undefined,

};

export default StoryDrink;
