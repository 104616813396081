import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { getSearchParams } from 'utils/url';
import Icon from 'components/common/Icon';
import Button from 'components/common/Button';
import './index.scss';
import { __ } from 'utils/translation';
import Slider from 'components/common/Slider';
import QRCode from "qrcode.react";


const QrComponent = () => {
    const { settingsName } = useSelector((state) => state.config.data);   

    const url = `https://maps.menu/${settingsName}`;
    const urlAdmin = `${window.location.href}`;

   
    
    const deliveryParametrs = `${url}?delivery`;
    const takeAwayParametrs = `${url}?takeAway`;
    const waiterParametrs = `${url}?waiter`;
    const pwaApp = `${url}?pwa=app`;


    const [settingsOpened, setSettingsOpened] = useState(false);
    const openQrComponentSettings = () => {
        setSettingsOpened(true);
        return false;
    };
    const closeQrComponentSettings = () => {
        setSettingsOpened(false);
    };
    const [pdfLoaded, setPdfLoaded] = useState(false);
    useEffect(() => {
        setPdfLoaded(true);
    }, []);

    return (
        <React.Fragment>
            <Button onClick={openQrComponentSettings} isInline className="icon-header-padding">
                <Icon type="qrcode" />
            </Button>
            <Slider
                opened={settingsOpened}
                onClose={closeQrComponentSettings}
                onSubmit={() => setSettingsOpened(false)}
                title={__("QR коды и ссылки")}
                subtitle={__("На этой вкладке вы сможете сгенерировать qr коды для вашего ресторана")}
            >
                <div className="catalog-table-settings-screenshot__screenshot-area">
                    <QRCode
                        id="AppUrl"
                        value={url}
                        size={290}
                        bgColor={"#ffffff"}
                        fgColor={"#000000"}
                        level={"L"}
                        includeMargin={false}
                       
                    />
                    <br />
                    <div className="catalog-table-settings-screenshot__screenshot-area__description">
                        <Icon type="camera" className="catalog-table-settings-screenshot__screenshot-area__description__icon" />&nbsp;
                        <b>{__("Область для снимка экрана")}</b>
                    </div>
                    <p className="linkQr" onClick={() => window.open(url, "_blank")}>{url}</p>
                </div>
                <div className="qrComponents-flexBox__box">
                    <p>{__("Эту ссылку и QR код нужно размещать на ваших бизнес карточках, рекламных материалах и в качестве последней фотографии для публикации в социальные сети")}</p>
                </div>
                <br />
                <br />

                {/* <div className="catalog-table-settings-screenshot__screenshot-area">
                    <QRCode
                        id="WebUrl"
                        value={waiterParametrs}
                        size={290}
                        bgColor={"#ffffff"}
                        fgColor={"#000000"}
                        level={"L"}
                        includeMargin={false}
                        
                    />
                    <br />
                    <div className="catalog-table-settings-screenshot__screenshot-area__description">
                        <Icon type="camera" className="catalog-table-settings-screenshot__screenshot-area__description__icon" />&nbsp;
                        <b>{__("Область для снимка экрана")}</b>
                    </div>
                    <p className="linkQr" onClick={() => window.open(waiterParametrs, "_blank")}>{waiterParametrs}</p>
                </div>
                <div className="qrComponents-flexBox__box">
                    <p>{__("Эту ссылку и QR код нужно размещать на ваших  столах, если вы хотите запретить самообслуживание клиентов. В приложении по этой ссылке нет кнопок для добавления или удаления. Только название и стоимость.")}</p>
                </div>
                <br />
                <br /> */}

                {/* <div className="catalog-table-settings-screenshot__screenshot-area">
                    <QRCode
                        id="WebUrl"
                        value={deliveryParametrs}
                        size={290}
                        bgColor={"#ffffff"}
                        fgColor={"#000000"}
                        level={"L"}
                        includeMargin={false}
                       
                    />
                    <br />
                    <div className="catalog-table-settings-screenshot__screenshot-area__description">
                        <Icon type="camera" className="catalog-table-settings-screenshot__screenshot-area__description__icon" />&nbsp;
                        <b>{__("Область для снимка экрана")}</b>
                    </div>
                    <p className="linkQr" onClick={() => window.open(deliveryParametrs, "_blank")}>{deliveryParametrs}</p>
                </div>
                <div className="qrComponents-flexBox__box">
                    <p>{__("Эту ссылку и QR код нужно размещать на ваших бизнес карточках, рекламных материалах если вы делаете доставку еды на дом. Эта ссылка позволит клиенту сформировать заказ и отправить его на ваш принтер. Вы получите контакты клиента, домашний адрес дополнительную информацию. Затем вы сможете позвонить клиенту и договориться о доставке. Оплата при получении заказа")}</p>
                </div>
                <br />
                <br />


                <div className="catalog-table-settings-screenshot__screenshot-area">
                    <QRCode
                        id="WebUrl"
                        value={takeAwayParametrs}
                        size={290}
                        bgColor={"#ffffff"}
                        fgColor={"#000000"}
                        level={"L"}
                        includeMargin={false}
                       
                    />
                    <br />
                    <div className="catalog-table-settings-screenshot__screenshot-area__description">
                        <Icon type="camera" className="catalog-table-settings-screenshot__screenshot-area__description__icon" />&nbsp;
                        <b>{__("Область для снимка экрана")}</b>
                    </div>
                    <p className="linkQr" onClick={() => window.open(takeAwayParametrs, "_blank")}>{takeAwayParametrs}</p>
                </div>
                <div className="qrComponents-flexBox__box">
                    <p>{__("Эту ссылку и QR код нужно размещать на ваших бизнес карточках, рекламных материалах если вы делаете заказы на вынос. Эта ссылка позволит клиенту сформировать заказ и отправить его на ваш принтер. Вы получите контакты клиента и сможете уточнить свой заказ по телефону")}</p>
                </div>
                <br />

                <br /> */}


                <div className="catalog-table-settings-screenshot__screenshot-area">
                    <QRCode
                        id="WebUrl"
                        value={pwaApp}
                        size={290}
                        bgColor={"#ffffff"}
                        fgColor={"#000000"}
                        level={"L"}
                        includeMargin={false}
                       
                    />
                    <br />
                    <div className="catalog-table-settings-screenshot__screenshot-area__description">
                        <Icon type="camera" className="catalog-table-settings-screenshot__screenshot-area__description__icon" />&nbsp;
                        <b>{__("Область для снимка экрана")}</b>
                    </div>
                    <p className="linkQr" onClick={() => window.open(pwaApp, "_blank")}>{pwaApp}</p>
                </div>
                <div className="qrComponents-flexBox__box">
                    <p>{__("Эта ссылка позволяет создать приложение для работы официантов без интернета. Рекомендуется открыть ее на планшете и сохранить прогрессивное веб приложение на экране своего планшета. Подробнее о том, как сохранить прогрессивное веб приложение на экране своего вашей модели планшета узнайте в интернете")}</p>
                </div>
                <br />               

                <div className="settings-video-link-box">
                    <a href="" target="_blank">
                        <div className="settings-video-link-box__flexbox" style={{ marginLeft: 12 }}>
                            <Icon className="settings-video-link-box__flexbox__icon" type="youtube" />
                            <div className="settings-video-link-box__flexbox__text">{__("Нажмите, чтобы посмотреть инструкцию в YouTube")}</div>
                        </div>
                    </a>
                </div>
                <br />

            </Slider>
        </React.Fragment>
    );
};


export default QrComponent;
