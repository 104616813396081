import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import classnames from 'classnames';
import { getSearchParams } from 'utils/url';

import Icon from 'components/common/Icon';
import Button from 'components/common/Button';
import Stories from 'components/Stories';
import StoriesDrinks from 'components/StoriesDrinks';
import StoriesCigarettas from 'components/StoriesCigarettas';
import StoriesBottles from 'components/StoriesBottles';
import StoriesSoftDrinks from 'components/StoriesSoftDrinks';
import StoriesCakes from 'components/StoriesCakes';
import Stories2Items from 'components/Stories2Items';
import Stories3Items from 'components/Stories3Items';
import StoriesOffers from 'components/StoriesOffers';
import StoriesSnacks from 'components/StoriesSnacks';
import StoriesDishes from 'components/StoriesDishes';

import CatalogItems from 'components/CatalogItems';
import CatalogDrinks from 'components/CatalogDrinks';
import CatalogCigarettas from 'components/CatalogCigarettas';
import CatalogBottles from 'components/CatalogBottles';
import CatalogSoftDrinks from 'components/CatalogSoftDrinks';
import CatalogCakes from 'components/CatalogCakes';
import Catalog2Items from 'components/Catalog2Items';
import Catalog3Items from 'components/Catalog3Items';
import CatalogOffers from 'components/CatalogOffers';
import CatalogSnacks from 'components/CatalogSnacks'
import CatalogDishes from 'components/CatalogDishes'
import CatalogTablesNew from 'components/CatalogTablesNew'


import Header from 'components/Header';
import Avatars from 'components/Avatars';
import Title from 'components/Title';
import Messengers from 'components/Messengers';
import Reviews from 'components/Reviews';
import Blocks from 'components/Blocks';
import GoogleMaps from 'components/GoogleMaps';
import Footer from 'components/Footer';
import CallWaiter from 'components/CallWaiter';
import Publish from 'components/Publish';
import PublishImage from 'components/PublishImage';
import { __ } from 'utils/translation';
import { Helmet } from 'react-helmet';

const App = () => {
  const { data = {} } = useSelector((state) => state.config);
  const { currentTheme,  imageUrl, imageSending } = useSelector((state) => state.config);
  const { settingsName } = useSelector((state) => state.config.data);
  const backgroundStyles = currentTheme.getBackgroundStyles();
  const linkTableBooking = "https://table-booking.online";
  const { stories } = useSelector((state) => state.config.data);
  const counterStories = data.storiesDishes.length + data.storiesOffers.length + data.storiesSnacks.length + data.storiesCakes.length + data.stories2Items.length + data.storiesSoftDrinks.length + 
    data.stories3Items.length + data.storiesDrinks.length + data.storiesBottles.length + data.storiesCigarettas.length + data.stories3Items.length + data.stories4Items.length + 
    data.stories5Items.length;

  const [typeStory, setTypeStory] = useState("all-dish");
  var params = getSearchParams(window.location.search);

  let paramsSortCategorias = "";
  let isBook = false;
  if (params['categoriacoctails'] === "" || params['categoriacoctails']) {
    paramsSortCategorias = "categoria-coctails";
    isBook = true;
  };
  if (params['categoriabeer'] === "" || params['categoriabeer']) {
    paramsSortCategorias = "categoria-beer";
    isBook = true;
  };
  if (params['categoriawine'] === "" || params['categoriawine']) {
    paramsSortCategorias = "categoria-wine";
    isBook = true;
  };
  if (params['categoriawhicky'] === "" || params['categoriawhicky']) {
    paramsSortCategorias = "categoria-whicky";
    isBook = true;
  };
  if (params['categoriabrandy'] === "" || params['categoriabrandy']) {
    paramsSortCategorias = "categoria-brandy";
    isBook = true;
  };
  if (params['categoriacoffee'] === "" || params['categoriacoffee']) {
    paramsSortCategorias = "categoria-coffee";
    isBook = true;
  };
  if (params['categoriavodka'] === "" || params['categoriavodka']) {
    paramsSortCategorias = "categoria-vodka";
    isBook = true;
  };
  if (params['categoriagin'] === "" || params['categoriagin']) {
    paramsSortCategorias = "categoria-gin";
    isBook = true;
  };
  if (params['categoriarum'] === "" || params['categoriarum']) {
    paramsSortCategorias = "categoria-rum";
    isBook = true;
  };
  if (params['categoriaother'] === "" || params['categoriaother']) {
    paramsSortCategorias = "categoria-other";
    isBook = true;
  };
  if (params['categoriasoftdrinks'] === "" || params['categoriasoftdrinks']) {
    paramsSortCategorias = "categoria-soft-drinks";
    isBook = true;
  };
  if (params['categoriahookah'] === "" || params['categoriahookah']) {
    paramsSortCategorias = "categoria-hookah";
    isBook = true;
  };
  if (params['categoriatequila'] === "" || params['categoriatequila']) {
    paramsSortCategorias = "categoria-tequila";
    isBook = true;
  };
  if (params['categoriaaperitifs'] === "" || params['categoriaperitifs']) {
    paramsSortCategorias = "categoria-aperitifs";
    isBook = true;
  };
  if (params['categoriadegistives'] === "" || params['categoriadegistives']) {
    paramsSortCategorias = "categoria-degistives";
    isBook = true;
  };
  if (params['categoriasnacks'] === "" || params['categoriasnacks']) {
    paramsSortCategorias = "categoria-snacks";
    isBook = true;
  };

  if (params['categorialiquore'] === "" || params['categorialiquore']) {
    paramsSortCategorias = "categoria-liquore";
    isBook = true;
  };


  return (
    <React.Fragment>
      <Helmet>
        <script src="https://apps.elfsight.com/p/platform.js" defer></script>
      </Helmet>
      
            <div className={(imageSending && !imageUrl) ? "app-fixed" : "app"} style={backgroundStyles}>
              <div className="app-container">
              <Header />
              <Avatars data={data.avatars} />
              {!isBook && 
                <>
                  <div className="app-story-box">
                    <div className="app-story-box-picker">
                      <Button onClick={() => setTypeStory("all-dish")} className={`app-story-type-button${typeStory === "all-dish" ? "-selected" : ""}`}>
                        {counterStories > 0 ?
                          <>
                            <Icon className={classnames(['icon-40'])} type="Utensils" />
                          </>
                          :
                          <>
                            <Icon className={classnames(['icon-40'])} type="bars" />
                          </>
                        }
                      </Button>
                      {counterStories > 0  &&
                        <>
                          <Button onClick={() => setTypeStory("chief")} className={`app-story-type-button${typeStory === "chief" ? "-selected" : ""}`}><Icon className={classnames(['icon-40-minus-padding'])} type="medal" /></Button>
                          <Button onClick={() => setTypeStory("offers")} className={`app-story-type-button${typeStory === "offers" ? "-selected" : ""}`}><Icon className={classnames(['icon-40-minus-padding'])} type="percent" /></Button>
                          <Button onClick={() => setTypeStory("snacks")} className={`app-story-type-button${typeStory === "snacks" ? "-selected" : ""}`}><Icon className={classnames(['icon-40-minus-padding'])} type="hamburger" /></Button>
                          <Button onClick={() => setTypeStory("cakes")} className={`app-story-type-button${typeStory === "cakes" ? "-selected" : ""}`}><Icon className={classnames(['icon-40'])} type="iceCream" /></Button>
                          <Button onClick={() => setTypeStory("children")} className={`app-story-type-button${typeStory === "children" ? "-selected" : ""}`}><Icon className={classnames(['icon-40'])} type="baby" /></Button>
                          <Button onClick={() => setTypeStory("softDrinks")} className={`app-story-type-button${typeStory === "softDrinks" ? "-selected" : ""}`}><Icon className={classnames(['icon-40'])} type="gulp" /></Button>
                          <Button onClick={() => setTypeStory("tea")} className={`app-story-type-button${typeStory === "tea" ? "-selected" : ""}`}><Icon className={classnames(['icon-40-plus-padding'])} type="MugHot" /></Button>
                          <Button onClick={() => setTypeStory("drinks")} className={`app-story-type-button${typeStory === "drinks" ? "-selected" : ""}`}><Icon className={classnames(['icon-40-minus-padding'])} type="cocktail" /></Button>
                          <Button onClick={() => setTypeStory("bottles")} className={`app-story-type-button${typeStory === "bottles" ? "-selected" : ""}`}><Icon className={classnames(['icon-40-minus-padding'])} type="wineBottle" /></Button>
                          <Button onClick={() => setTypeStory("cigarettas")} className={`app-story-type-button${typeStory === "cigarettas" ? "-selected" : ""}`}><Icon className={classnames(['icon-40-special'])} type="smoking" /></Button>
                        </>
                      }
                      <Button onClick={() => setTypeStory("blocks")} className={`app-story-type-button${typeStory === "blocks" ? "-selected" : ""}`}><Icon className={classnames(['icon-40'])} type="puzzlePiece" /></Button>
                      <Button onClick={() => setTypeStory("reviews")} className={`app-story-type-button${typeStory === "reviews" ? "-selected" : ""}`}><Icon className={classnames(['icon-40-minus-padding'])} type="starHalfAlt" /></Button>
                      <Button onClick={() => setTypeStory("map")} className={`app-story-type-button${typeStory === "map" ? "-selected" : ""}`}><Icon className={classnames(['icon-40-minus-padding'])} type="mapMarkedAlt" /></Button>
                      {/* {stories.length > 5 &&
                        <>
                          <Button onClick={() => setTypeStory("tables")} className={`app-story-type-button${typeStory === "tables" ? "-selected" : ""}`}><Icon className={classnames(['icon-40'])} type="chair" /></Button>
                          <Button onClick={() =>  window.open(linkTableBooking, "_blank")} className= "app-story-type-button-selected" style={{borderColor:"orange"}} ><Icon className={classnames(['icon-40'])} type="calendarAlt" /></Button>
                        </>
                      } */}
                    </div>
                  </div>
                  {typeStory === "tables" &&
                    <>
                      <CatalogTablesNew data={data.catalogTables} />
                    </>
                    }  

                  {typeStory === "all-dish" &&
                    <>
                      <Stories data={data.stories} />
                      <CatalogItems data={data.catalogItems} />
                    </>}

                  {typeStory === "chief" &&
                    <>
                      <StoriesDishes data={data.storiesDishes} />
                      <CatalogDishes data={data.catalogDishes} />
                    </>}

                  {typeStory === "offers" &&
                    <>
                      <StoriesOffers data={data.storiesOffers} />
                      <CatalogOffers data={data.catalogOffers} />
                    </>}

                  {typeStory === "snacks" &&
                    <>
                      <StoriesSnacks data={data.storiesSnacks} />
                      <CatalogSnacks data={data.catalogSnacks} />
                    </>}

                  {typeStory === "cakes" &&
                    <>
                      <StoriesCakes data={data.storiesCakes} />
                      <CatalogCakes data={data.catalogCakes} />
                    </>}

                  {typeStory === "children" &&
                    <>
                      <Stories2Items data={data.stories2Items} />
                      <Catalog2Items data={data.catalog2Items} />
                    </>}

                  {typeStory === "softDrinks" &&
                    <>
                      <StoriesSoftDrinks data={data.storiesSoftDrinks} />
                      <CatalogSoftDrinks data={data.catalogSoftDrinks} />
                    </>}

                  {typeStory === "tea" &&
                    <>
                      <Stories3Items data={data.stories3Items} />
                      <Catalog3Items data={data.catalog3Items} />
                    </>}

                  {typeStory === "drinks" &&
                    <>
                      <StoriesDrinks data={data.storiesDrinks} />
                      <CatalogDrinks data={data.catalogDrinks} />
                    </>}

                  {typeStory === "bottles" &&
                    <>
                      <StoriesBottles data={data.storiesBottles} />
                      <CatalogBottles data={data.catalogBottles} />
                    </>}

                  {typeStory === "cigarettas" &&
                    <>
                      <StoriesCigarettas data={data.storiesCigarettas} />
                      <CatalogCigarettas data={data.catalogCigarettas} />
                    </>}

                  {typeStory === "blocks" &&
                    <>
                      <Blocks data={data.blocks} />
                    </>}

                  {typeStory === "reviews" &&
                    <>
                      <Reviews />
                    </>}

                  {typeStory === "waiters" &&
                    <>
                      <CallWaiter />
                    </>}

                  {typeStory === "map" &&
                    <>
                      <GoogleMaps data={data.googleMaps} />
                    </>}

                  <br />
                  <Title />
                  <Messengers />
                  <Footer /><br /><br /><br />
                  <Publish settingsName={settingsName} />
                </> 
              }
              {isBook && 
                <>
                  <Stories data={data.stories} />
                  <CatalogItems data={data.catalogItems} />
                  <Publish settingsName={settingsName} />
                </> 
              }

            </div>          
         </div>
      <PublishImage />
    </React.Fragment>
  );
};

export default App;
