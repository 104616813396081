import React from 'react';
import { useSelector} from 'react-redux';   
import './index.scss';

const PublishImage = () => {

    const { imageUrl, imageSending } = useSelector((state) => state.config);
    return (
        <>
        {imageSending && !imageUrl ?
            <div className="publish-image">
                <div className="spinner">
                    <div className="bounce1" />
                    <div className="bounce2" />
                    <div className="bounce3" />
                </div>                
            </div>
            :
            null}
        </>
    );
};

export default PublishImage;
