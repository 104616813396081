import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { uuid } from 'uuidv4';
import { useSelector, useDispatch } from 'react-redux';

import Slider from 'components/common/Slider';
import { __ } from 'utils/translation';
import { EDIT_AVATAR, UPDATE_CONFIG_DATA } from 'constants/actions';
import addedAvatar from 'images/CafeLogo.png';

import AvatarSettings from './AvatarSettings';
import Avatar from './Avatar';
import './index.scss';

const emptySettings = {
    guid: null,
    type: 'free',
    link: '',
    text: '',
    image: '' 
};

const Avatars = ({ data }) => {
    const [settingsOpened, setSettingsOpened] = useState(null);
    const [avatarData, setAvatarData] = useState(emptySettings);

    const closeAvatarsSettings = () => {
        setSettingsOpened(null);
    };

    const onOpenAvatarSettings = (avatarId) => {
        setSettingsOpened(avatarId);
    };

    const dispatch = useDispatch();
    const submitSettings = () => {
        if (avatarData.text || avatarData.image)
            dispatch({ type: EDIT_AVATAR, payload: avatarData });
            dispatch({
                type: UPDATE_CONFIG_DATA, name: avatarData.text 
            });
        closeAvatarsSettings();
    };

    const { avatars } = useSelector((state) => state.config.data);

    useEffect(() => {
        const currentAvatar = avatars.find((avatar) => avatar.guid === settingsOpened);
        const settings = currentAvatar || { ...emptySettings };
        if (!settings.guid)
            settings.guid = uuid();
        setAvatarData({ ...settings });
    }, [settingsOpened, avatars]);


    return (
        <React.Fragment>
            <div className="avatar-username-box">
                {data.length < 1 && (
                    <>
                        <div className="avatar" onClick={onOpenAvatarSettings} style={{ backgroundImage: `URL(${addedAvatar})` }} />
                        <h1 className="main-page__page1__h1"
                            onClick={() => setSettingsOpened(true)}>
                            {__("Добавить название")}
                        </h1>
                    </>
                )}

                {
                    data.map((avatar) => <Avatar onClick={() => onOpenAvatarSettings(avatar.guid)} key={avatar.guid} {...avatar} />)
                }
            </div>

            <Slider
                opened={settingsOpened !== null}
                onClose={closeAvatarsSettings}
                title={__("Настройка логотипа и названия бренда")}
                
                onSubmit={submitSettings}
            >
                <AvatarSettings {...avatarData} onChange={(settings) => setAvatarData({ ...avatarData, ...settings })} />
            </Slider>

        </React.Fragment >
    );
};

Avatars.propTypes = {
    data: PropTypes.arrayOf(PropTypes.shape({}))
};

Avatars.defaultProps = {
    data: []
};

export default Avatars;
