import { handleActions } from 'redux-actions';

import Theme from 'utils/theme';
// import Background from 'utils/background';
// import ButtonColor from 'utils/buttonColor';

import {
  LOADING_ERROR,
  SET_DATA, 
  CHANGE_THEME,
  // CHANGE_BACKGROUND,
  // CHANGE_BUTTON_COLOR,
  BOOKING_ZONES,
  BOOKING_DATES,
  BOOKING_DATES_CLEAR,
  BOOKING_TIMES_FROM,
  BOOKING_TIMES_FROM_CLEAR,
  BOOKING_TIMES_TO,
  BOOKING_TIMES_TO_CLEAR,
  BOOKING_PEOPLE,
  BOOKING_PEOPLE_CLEAR,
  BOOKING_GROUP_ONE_TABLE,
  BOOKING_GROUP_ONE_TABLE_CLEAR,
  BOOKING_GROUP_TWO_TABLE,
  BOOKING_GROUP_TWO_TABLE_CLEAR,
  BOOKING_GROUP_THREE_TABLE,
  BOOKING_GROUP_THREE_TABLE_CLEAR,
  BOOKING_GROUP_FOUR_TABLE,
  BOOKING_GROUP_FOUR_TABLE_CLEAR,
  BOOKING_GROUP_IS_GROUP,

  EDIT_BLOCK,
  REMOVE_BLOCK,
  ROTATE_BLOCK,

  EDIT_STORY,
  EDIT_STORY_DRINK,
  EDIT_STORY_TABLE,
  EDIT_STORY_2_ITEMS,
  EDIT_STORY_3_ITEMS,
  EDIT_STORY_4_ITEMS,
  EDIT_STORY_5_ITEMS,
  EDIT_STORY_BOTTLES,
  EDIT_STORY_CAKES,
  EDIT_STORY_CIGARETTAS,
  EDIT_STORY_DISHES,
  EDIT_STORY_OFFERS,
  EDIT_STORY_SNACKS,
  EDIT_STORY_SOFTDRINKS,

  REMOVE_STORY,
  REMOVE_STORY_DRINK,
  REMOVE_STORY_TABLE,
  REMOVE_STORY_2_ITEMS,
  REMOVE_STORY_3_ITEMS,
  REMOVE_STORY_4_ITEMS,
  REMOVE_STORY_5_ITEMS,
  REMOVE_STORY_BOTTLES,
  REMOVE_STORY_CAKES,
  REMOVE_STORY_CIGARETTAS,
  REMOVE_STORY_DISHES,
  REMOVE_STORY_OFFERS,
  REMOVE_STORY_SNACKS,
  REMOVE_STORY_SOFTDRINKS,

  ROTATE_STORY,
  ROTATE_STORY_DRINK,
  ROTATE_STORY_TABLE,
  ROTATE_STORY_2_ITEMS,
  ROTATE_STORY_3_ITEMS,
  ROTATE_STORY_4_ITEMS,
  ROTATE_STORY_5_ITEMS,
  ROTATE_STORY_BOTTLES,
  ROTATE_STORY_CAKES,
  ROTATE_STORY_CIGARETTAS,
  ROTATE_STORY_DISHES,
  ROTATE_STORY_OFFERS,
  ROTATE_STORY_SNACKS,
  ROTATE_STORY_SOFTDRINKS, 



  EDIT_CATALOG,
  EDIT_CATALOG_DRINK,
  EDIT_CATALOG_2_ITEMS,
  EDIT_CATALOG_3_ITEMS,
  EDIT_CATALOG_4_ITEMS,
  EDIT_CATALOG_5_ITEMS,
  EDIT_CATALOG_BOTTLES,
  EDIT_CATALOG_CAKES,
  EDIT_CATALOG_CIGARETTAS,
  EDIT_CATALOG_DISHES,
  EDIT_CATALOG_OFFERS,
  EDIT_CATALOG_SNACKS,
  EDIT_CATALOG_SOFTDRINKS,
  EDIT_CATALOG_TABLE,

  REMOVE_CATALOG,
  REMOVE_CATALOG_DRINK,
  REMOVE_CATALOG_2_ITEMS,
  REMOVE_CATALOG_3_ITEMS,
  REMOVE_CATALOG_4_ITEMS,
  REMOVE_CATALOG_5_ITEMS,
  REMOVE_CATALOG_BOTTLES,
  REMOVE_CATALOG_CAKES,
  REMOVE_CATALOG_CIGARETTAS,
  REMOVE_CATALOG_DISHES,
  REMOVE_CATALOG_OFFERS,
  REMOVE_CATALOG_SNACKS,
  REMOVE_CATALOG_SOFTDRINKS,
  REMOVE_CATALOG_TABLE,



  ROTATE_CATALOG,
  ROTATE_CATALOG_DRINK,
  ROTATE_CATALOG_2_ITEMS,
  ROTATE_CATALOG_3_ITEMS,
  ROTATE_CATALOG_4_ITEMS,
  ROTATE_CATALOG_5_ITEMS,
  ROTATE_CATALOG_BOTTLES,
  ROTATE_CATALOG_CAKES,
  ROTATE_CATALOG_CIGARETTAS,
  ROTATE_CATALOG_DISHES,
  ROTATE_CATALOG_OFFERS,
  ROTATE_CATALOG_SNACKS,
  ROTATE_CATALOG_SOFTDRINKS,
  ROTATE_CATALOG_TABLE,

  IMPORT_CATALOG,
  IMPORT_CATALOG_DRINK,
  IMPORT_CATALOG_TABLE,
  IMPORT_CATALOG_2_ITEMS,
  IMPORT_CATALOG_3_ITEMS,
  IMPORT_CATALOG_4_ITEMS,
  IMPORT_CATALOG_5_ITEMS,
  IMPORT_CATALOG_BOTTLES,
  IMPORT_CATALOG_CAKES,
  IMPORT_CATALOG_CIGARETTAS,
  IMPORT_CATALOG_DISHES,
  IMPORT_CATALOG_OFFERS,
  IMPORT_CATALOG_SNACKS,
  IMPORT_CATALOG_SOFTDRINKS,


  CATALOG_FILTER,
  CATALOG_DRINK_FILTER,
  TABLE_FILTER,
  CATALOG_2_ITEMS_FILTER,
  CATALOG_3_ITEMS_FILTER,
  CATALOG_4_ITEMS_FILTER,
  CATALOG_5_ITEMS_FILTER,
  CATALOG_BOTTLES_FILTER,
  CATALOG_CAKES_FILTER,
  CATALOG_CIGARETTAS_FILTER,
  CATALOG_DISHES_FILTER,
  CATALOG_OFFERS_FILTER,
  CATALOG_SNACKS_FILTER,
  CATALOG_SOFTDRINKS_FILTER,




  EDIT_MESSENGERS_DATA,
  EDIT_REVIEWS_DATA,
  EDIT_SOCIAL_DATA,
  UPDATE_CONFIG_DATA,
  EDIT_AVATAR,
  EDIT_GOOGLE_MAPS,
  REMOVE_GOOGLE_MAPS,
  DATA_ALREADY_SAVED,
  USER_REGISTERED,
  HIDE_LANDING,
  EDIT_AD,
  REMOVE_AD,





  EDIT_OPENING_HOURS_DATA,
  EDIT_RESERVATIONS_DATA,
  EDIT_CALL_WAITER_DATA,
  EDIT_DELIVERY_DATA,
  EDIT_TAKE_AWAY_DATA,
  IMAGE_UPLOADED,
  START_SENDING_IMAGE,
  IMAGE_ALREADY_SAVED,



} from 'constants/actions';

const defaultTheme = new Theme({ name: 'Default' });
const initialState = {
  error: null,
  data: null,
  themes: [defaultTheme],
  currentTheme: defaultTheme,
  updated: false,
  imageUrl: false,
  imageSending: false,
  config: {},
  account: {},
  showLanding: true,
  instagramFeed: [],
  zonesGuid: [],
  zonesGuidSelected: [],
  datesGuid: null,
  datesGuidSelected: null,
  groupOneTableGuides: "",
  groupTwoTableGuides: "",
  groupThreeTableGuides: "",
  groupFourTableGuides: "",
  isGroup: 1,
  timesFromGuid: null,
  timesToGuid: null,
  timesFromGuidSelected: null,
  peopleGuid: 2,
  peopleGuidSelected: 2,
  optionsGuid: [],
  optionsGuidSelected: [],
  settingsName: "",
  linkUrl:"",



  catalogItems: [],
  catalogDrinks: [],
  catalogTables: [],
  catalog2Items: [],
  catalog3Items: [],
  catalog4Items: [],
  catalog5Items: [],
  catalogBottles: [],
  catalogCakes: [],
  catalogCigarettas: [],
  catalogDishes: [],
  catalogOffers: [],
  catalogSnacks: [],
  catalogSoftDrinks: [],

  stories: [],
  storiesDrinks: [],
  storiesTables: [],
  stories2Items: [],
  stories3Items: [],
  stories4Items: [],
  stories5Items: [],
  storiesBottles: [],
  storiesCakes: [],
  storiesCigarettas: [],
  storiesDishes: [],
  storiesOffers: [],
  storiesSnacks: [],
  storiesSoftDrinks: [],
  url: null,
  storyGuid: null,
  storyGuidSelected: null,
  storyDrinkGuid: null,
  storyDrinkGuidSelected: null
};

const reducer = handleActions({

// [BOOKING_DATES] : (state, { datesGuid} ) => {
//     return {
//       ...state,
//       datesGuid,
//       datesGuidSelected: datesGuid,
//     }
//   },

//   [BOOKING_DATES_CLEAR] : (state) => {
//     return {
//       ...state,
//       datesGuid: null
//     }
//   },

//   [BOOKING_GROUP_IS_GROUP] : (state, { isGroup} ) => {
//     return {
//       ...state,
//       isGroup,
//     }
//   },

//   [BOOKING_GROUP_ONE_TABLE] : (state, { groupOneTableGuides} ) => {
//     return {
//       ...state,
//       groupOneTableGuides,
//     }
//   },

//   [BOOKING_GROUP_ONE_TABLE_CLEAR] : (state) => {
//     return {
//       ...state,
//       groupOneTableGuides: ""
//     }
//   },

//   [BOOKING_GROUP_TWO_TABLE] : (state, { groupTwoTableGuides} ) => {
//     return {
//       ...state,
//       groupTwoTableGuides,
//     }
//   },

//   [BOOKING_GROUP_TWO_TABLE_CLEAR] : (state) => {
//     return {
//       ...state,
//       groupTwoTableGuides: ""
//     }
//   },

//   [BOOKING_GROUP_THREE_TABLE] : (state, { groupThreeTableGuides} ) => {
//     return {
//       ...state,
//       groupThreeTableGuides,
//     }
//   },

//   [BOOKING_GROUP_THREE_TABLE_CLEAR] : (state) => {
//     return {
//       ...state,
//       groupThreeTableGuides: ""
//     }
//   },

//   [BOOKING_GROUP_FOUR_TABLE] : (state, { groupFourTableGuides} ) => {
//     return {
//       ...state,
//       groupFourTableGuides,
//     }
//   },

//   [BOOKING_GROUP_FOUR_TABLE_CLEAR] : (state) => {
//     return {
//       ...state,
//       groupFourTableGuides: ""
//     }
//   },



//   [BOOKING_TIMES_FROM] : (state, { timesFromGuid } ) => {
//     if (timesFromGuid === 9 * 60)
//     return {
//       ...state,
//       timesFromGuid,      
//       timesFromGuidSelected: "9:00",
//     };
//     if (timesFromGuid === 9.5 * 60)
//     return {
//       ...state,
//       timesFromGuid,      
//       timesFromGuidSelected: "9:30",
//     };
//     if (timesFromGuid === 10 * 60)
//     return {
//       ...state,
//       timesFromGuid,      
//       timesFromGuidSelected: "10:00",
//     };
//     if (timesFromGuid === 10.5 * 60)
//     return {
//       ...state,
//       timesFromGuid,      
//       timesFromGuidSelected: "10:30",
//     };
//     if (timesFromGuid === 11 * 60)
//     return {
//       ...state,
//       timesFromGuid,      
//       timesFromGuidSelected: "11:00",
//     };
//     if (timesFromGuid === 11.5 * 60)
//     return {
//       ...state,
//       timesFromGuid,      
//       timesFromGuidSelected: "11:30",
//     };
//     if (timesFromGuid === 12 * 60)
//     return {
//       ...state,
//       timesFromGuid,      
//       timesFromGuidSelected: "12:00",
//     };
//     if (timesFromGuid === 12.5 * 60)
//     return {
//       ...state,
//       timesFromGuid,      
//       timesFromGuidSelected: "12:30",
//     };
//     if (timesFromGuid === 13 * 60)
//     return {
//       ...state,
//       timesFromGuid,      
//       timesFromGuidSelected: "13:00",
//     };
//     if (timesFromGuid === 13.5 * 60)
//     return {
//       ...state,
//       timesFromGuid,      
//       timesFromGuidSelected: "13:30",
//     };
//     if (timesFromGuid === 14 * 60)
//     return {
//       ...state,
//       timesFromGuid,      
//       timesFromGuidSelected: "14:00",
//     };
//     if (timesFromGuid === 14.5 * 60)
//     return {
//       ...state,
//       timesFromGuid,      
//       timesFromGuidSelected: "14:30",
//     };
//     if (timesFromGuid === 15 * 60)
//     return {
//       ...state,
//       timesFromGuid,      
//       timesFromGuidSelected: "15:00",
//     };
//     if (timesFromGuid === 15.5 * 60)
//     return {
//       ...state,
//       timesFromGuid,      
//       timesFromGuidSelected: "15:30",
//     };
//     if (timesFromGuid === 16 * 60)
//     return {
//       ...state,
//       timesFromGuid,      
//       timesFromGuidSelected: "16:00",
//     };
//     if (timesFromGuid === 16.5 * 60)
//     return {
//       ...state,
//       timesFromGuid,      
//       timesFromGuidSelected: "16:30",
//     };
//     if (timesFromGuid === 17 * 60)
//     return {
//       ...state,
//       timesFromGuid,      
//       timesFromGuidSelected: "17:00",
//     };
//     if (timesFromGuid === 17.5 * 60)
//     return {
//       ...state,
//       timesFromGuid,      
//       timesFromGuidSelected: "17:30",
//     };
//     if (timesFromGuid === 18 * 60)
//     return {
//       ...state,
//       timesFromGuid,      
//       timesFromGuidSelected: "18:00",
//     };
//     if (timesFromGuid === 18.5 * 60)
//     return {
//       ...state,
//       timesFromGuid,      
//       timesFromGuidSelected: "18:30",
//     };
//     if (timesFromGuid === 19 * 60)
//     return {
//       ...state,
//       timesFromGuid,      
//       timesFromGuidSelected: "19:00",
//     };
//     if (timesFromGuid === 19.5 * 60)
//     return {
//       ...state,
//       timesFromGuid,      
//       timesFromGuidSelected: "19:30",
//     };
//     if (timesFromGuid === 20 * 60)
//     return {
//       ...state,
//       timesFromGuid,      
//       timesFromGuidSelected: "20:00",
//     };
//     if (timesFromGuid === 20.5 * 60)
//     return {
//       ...state,
//       timesFromGuid,      
//       timesFromGuidSelected: "20:30",
//     };
//     if (timesFromGuid === 21 * 60)
//     return {
//       ...state,
//       timesFromGuid,      
//       timesFromGuidSelected: "21:00",
//     };
//     if (timesFromGuid === 21.5 * 60)
//     return {
//       ...state,
//       timesFromGuid,      
//       timesFromGuidSelected: "21:30",
//     };
//     if (timesFromGuid === 22 * 60)
//     return {
//       ...state,
//       timesFromGuid,      
//       timesFromGuidSelected: "22:00",
//     };
//     if (timesFromGuid === 22.5 * 60)
//     return {
//       ...state,
//       timesFromGuid,      
//       timesFromGuidSelected: "22:30",
//     };
//     if (timesFromGuid === 23 * 60)
//     return {
//       ...state,
//       timesFromGuid,      
//       timesFromGuidSelected: "23:00",
//     };
//     if (timesFromGuid === 23.5 * 60)
//     return {
//       ...state,
//       timesFromGuid,      
//       timesFromGuidSelected: "23:30",
//     };
    
//     return {
//       ...state,
//       timesFromGuid,      
//       timesFromGuidSelected: timesFromGuid,
//     };
//   },

//   [BOOKING_TIMES_FROM_CLEAR] : (state) => {
//     return {
//       ...state,
//       timesFromGuid: null
//     }
//   },

//   [BOOKING_TIMES_TO] : (state, { timesToGuid } ) => {
//     return {
//       ...state,
//       timesToGuid,
//       timesToGuidSelected: timesToGuid,
//     }
//   },

//   [BOOKING_TIMES_TO_CLEAR] : (state) => {
//     return {
//       ...state,
//       timesToGuid: null
//     }
//   },
  
//   [BOOKING_PEOPLE] : (state, { peopleGuid } ) => {
//     return {
//       ...state,
//       peopleGuid,
//       peopleGuidSelected: peopleGuid,
//     }
//   },

//   [BOOKING_PEOPLE_CLEAR] : (state) => {
//     return {
//       ...state,
//       peopleGuid: null
//     }
//   },


  // edit story
  [EDIT_STORY]: (state, { payload }) => {
    const currentStory = state.data.stories.find((story) => payload.guid === story.guid);
    const { stories } = state.data;
    const newOrder = stories.reduce((result, story) => (story.order > result ? story.order : result), 0) + 1;

    const { order, ...storyData } = payload;

    if (!currentStory) {
      return {
        ...state,
        data: {
          ...state.data,
          stories: [...stories, { ...storyData, order: newOrder }]
        },
        updated: true
      };
    }
    return {
      ...state,
      data: {
        ...state.data,
        stories: stories.map((story) => (story.guid === storyData.guid ? { ...story, ...storyData } : story))
      },
      updated: true
    };
  },

  [EDIT_STORY_DRINK]: (state, { payload }) => {
    const currentStoryDrink = state.data.storiesDrinks.find((storyDrink) => payload.guid === storyDrink.guid);
    const { storiesDrinks } = state.data;
    const newOrder = storiesDrinks.reduce((result, storyDrink) => (storyDrink.order > result ? storyDrink.order : result), 0) + 1;

    const { order, ...storyDrinkData } = payload;

    if (!currentStoryDrink) {
      return {
        ...state,
        data: {
          ...state.data,
          storiesDrinks: [...storiesDrinks, { ...storyDrinkData, order: newOrder }]
        },
        updated: true
      };
    }
    return {
      ...state,
      data: {
        ...state.data,
        storiesDrinks: storiesDrinks.map((storyDrink) => (storyDrink.guid === storyDrinkData.guid ? { ...storyDrink, ...storyDrinkData } : storyDrink))
      },
      updated: true
    };
  },

  [EDIT_STORY_TABLE]: (state, { payload }) => {
    const currentStoryTable = state.data.storiesTables.find((storyTable) => payload.guid === storyTable.guid);
    const { storiesTables } = state.data;
    const newOrder = storiesTables.reduce((result, storyTable) => (storyTable.order > result ? storyTable.order : result), 0) + 1;

    const { order, ...storyTableData } = payload; 

    if (!currentStoryTable) {
      return {
        ...state,
        data: {
          ...state.data,
          storiesTables: [...storiesTables, { ...storyTableData, order: newOrder }]
        },
        updated: true
      };
    }
    return {
      ...state,
      data: {
        ...state.data,
        storiesTables: storiesTables.map((storyTable) => (storyTable.guid === storyTableData.guid ? { ...storyTable, ...storyTableData } : storyTable))
      },
      updated: true
    };
  },

  [EDIT_STORY_2_ITEMS]: (state, { payload }) => {
    const currentStory = state.data.stories2Items.find((story) => payload.guid === story.guid);
    const { stories2Items } = state.data;
    const newOrder = stories2Items.reduce((result, story) => (story.order > result ? story.order : result), 0) + 1;

    const { order, ...storyData } = payload;

    if (!currentStory) {
      return {
        ...state,
        data: {
          ...state.data,
          stories2Items: [...stories2Items, { ...storyData, order: newOrder }]
        },
        updated: true
      };
    }
    return {
      ...state,
      data: {
        ...state.data,
        stories2Items: stories2Items.map((story) => (story.guid === storyData.guid ? { ...story, ...storyData } : story))
      },
      updated: true
    };
  },

  [EDIT_STORY_3_ITEMS]: (state, { payload }) => {
    const currentStory = state.data.stories3Items.find((story) => payload.guid === story.guid);
    const { stories3Items } = state.data;
    const newOrder = stories3Items.reduce((result, story) => (story.order > result ? story.order : result), 0) + 1;

    const { order, ...storyData } = payload;

    if (!currentStory) {
      return {
        ...state,
        data: {
          ...state.data,
          stories3Items: [...stories3Items, { ...storyData, order: newOrder }]
        },
        updated: true
      };
    }
    return {
      ...state,
      data: {
        ...state.data,
        stories3Items: stories3Items.map((story) => (story.guid === storyData.guid ? { ...story, ...storyData } : story))
      },
      updated: true
    };
  },

  [EDIT_STORY_4_ITEMS]: (state, { payload }) => {
    const currentStory = state.data.stories4Items.find((story) => payload.guid === story.guid);
    const { stories4Items } = state.data;
    const newOrder = stories4Items.reduce((result, story) => (story.order > result ? story.order : result), 0) + 1;

    const { order, ...storyData } = payload;

    if (!currentStory) {
      return {
        ...state,
        data: {
          ...state.data,
          stories4Items: [...stories4Items, { ...storyData, order: newOrder }]
        },
        updated: true
      };
    }
    return {
      ...state,
      data: {
        ...state.data,
        stories4Items: stories4Items.map((story) => (story.guid === storyData.guid ? { ...story, ...storyData } : story))
      },
      updated: true
    };
  },

  [EDIT_STORY_5_ITEMS]: (state, { payload }) => {
    const currentStory = state.data.stories5Items.find((story) => payload.guid === story.guid);
    const { stories5Items } = state.data;
    const newOrder = stories5Items.reduce((result, story) => (story.order > result ? story.order : result), 0) + 1;

    const { order, ...storyData } = payload;

    if (!currentStory) {
      return {
        ...state,
        data: {
          ...state.data,
          stories5Items: [...stories5Items, { ...storyData, order: newOrder }]
        },
        updated: true
      };
    }
    return {
      ...state,
      data: {
        ...state.data,
        stories5Items: stories5Items.map((story) => (story.guid === storyData.guid ? { ...story, ...storyData } : story))
      },
      updated: true
    };
  },

  [EDIT_STORY_BOTTLES]: (state, { payload }) => {
    const currentStory = state.data.storiesBottles.find((story) => payload.guid === story.guid);
    const { storiesBottles } = state.data;
    const newOrder = storiesBottles.reduce((result, story) => (story.order > result ? story.order : result), 0) + 1;

    const { order, ...storyData } = payload;

    if (!currentStory) {
      return {
        ...state,
        data: {
          ...state.data,
          storiesBottles: [...storiesBottles, { ...storyData, order: newOrder }]
        },
        updated: true
      };
    }
    return {
      ...state,
      data: {
        ...state.data,
        storiesBottles: storiesBottles.map((story) => (story.guid === storyData.guid ? { ...story, ...storyData } : story))
      },
      updated: true
    };
  },

  [EDIT_STORY_CAKES]: (state, { payload }) => {
    const currentStory = state.data.storiesCakes.find((story) => payload.guid === story.guid);
    const { storiesCakes } = state.data;
    const newOrder = storiesCakes.reduce((result, story) => (story.order > result ? story.order : result), 0) + 1;

    const { order, ...storyData } = payload;

    if (!currentStory) {
      return {
        ...state,
        data: {
          ...state.data,
          storiesCakes: [...storiesCakes, { ...storyData, order: newOrder }]
        },
        updated: true
      };
    }
    return {
      ...state,
      data: {
        ...state.data,
        storiesCakes: storiesCakes.map((story) => (story.guid === storyData.guid ? { ...story, ...storyData } : story))
      },
      updated: true
    };
  },

  [EDIT_STORY_CIGARETTAS]: (state, { payload }) => {
    const currentStory = state.data.storiesCigarettas.find((story) => payload.guid === story.guid);
    const { storiesCigarettas } = state.data;
    const newOrder = storiesCigarettas.reduce((result, story) => (story.order > result ? story.order : result), 0) + 1;

    const { order, ...storyData } = payload;

    if (!currentStory) {
      return {
        ...state,
        data: {
          ...state.data,
          storiesCigarettas: [...storiesCigarettas, { ...storyData, order: newOrder }]
        },
        updated: true
      };
    }
    return {
      ...state,
      data: {
        ...state.data,
        storiesCigarettas: storiesCigarettas.map((story) => (story.guid === storyData.guid ? { ...story, ...storyData } : story))
      },
      updated: true
    };
  },

  [EDIT_STORY_DISHES]: (state, { payload }) => {
    const currentStory = state.data.storiesDishes.find((story) => payload.guid === story.guid);
    const { storiesDishes } = state.data;
    const newOrder = storiesDishes.reduce((result, story) => (story.order > result ? story.order : result), 0) + 1;

    const { order, ...storyData } = payload;

    if (!currentStory) {
      return {
        ...state,
        data: {
          ...state.data,
          storiesDishes: [...storiesDishes, { ...storyData, order: newOrder }]
        },
        updated: true
      };
    }
    return {
      ...state,
      data: {
        ...state.data,
        storiesDishes: storiesDishes.map((story) => (story.guid === storyData.guid ? { ...story, ...storyData } : story))
      },
      updated: true
    };
  },

  [EDIT_STORY_OFFERS]: (state, { payload }) => {
    const currentStory = state.data.storiesOffers.find((story) => payload.guid === story.guid);
    const { storiesOffers } = state.data;
    const newOrder = storiesOffers.reduce((result, story) => (story.order > result ? story.order : result), 0) + 1;

    const { order, ...storyData } = payload;

    if (!currentStory) {
      return {
        ...state,
        data: {
          ...state.data,
          storiesOffers: [...storiesOffers, { ...storyData, order: newOrder }]
        },
        updated: true
      };
    }
    return {
      ...state,
      data: {
        ...state.data,
        storiesOffers: storiesOffers.map((story) => (story.guid === storyData.guid ? { ...story, ...storyData } : story))
      },
      updated: true
    };
  },

  [EDIT_STORY_SNACKS]: (state, { payload }) => {
    const currentStory = state.data.storiesSnacks.find((story) => payload.guid === story.guid);
    const { storiesSnacks } = state.data;
    const newOrder = storiesSnacks.reduce((result, story) => (story.order > result ? story.order : result), 0) + 1;
    
    const { order, ...storyData } = payload;
    
    if (!currentStory) {
    return {
    ...state,
    data: {
    ...state.data,
    storiesSnacks: [...storiesSnacks, { ...storyData, order: newOrder }]
    },
    updated: true
    };
    }
    return {
    ...state,
    data: {
    ...state.data,
    storiesSnacks: storiesSnacks.map((story) => (story.guid === storyData.guid ? { ...story, ...storyData } : story))
    },
    updated: true
    };
    },

  [EDIT_STORY_SOFTDRINKS]: (state, { payload }) => {
    const currentStory = state.data.storiesSoftDrinks.find((story) => payload.guid === story.guid);
    const { storiesSoftDrinks } = state.data;
    const newOrder = storiesSoftDrinks.reduce((result, story) => (story.order > result ? story.order : result), 0) + 1;

    const { order, ...storyData } = payload;

    if (!currentStory) {
      return {
        ...state,
        data: {
          ...state.data,
          storiesSoftDrinks: [...storiesSoftDrinks, { ...storyData, order: newOrder }]
        },
        updated: true
      };
    }
    return {
      ...state,
      data: {
        ...state.data,
        storiesSoftDrinks: storiesSoftDrinks.map((story) => (story.guid === storyData.guid ? { ...story, ...storyData } : story))
      },
      updated: true
    };
  },


  //  remove story
  [REMOVE_STORY]: (state, { guid }) => ({
    ...state,
    data: {
      ...state.data,
      stories: state.data.stories.filter((story) => story.guid !== guid),
      catalogItems: state.data.catalogItems.filter(ci => !ci.storyGuid || ci.storyGuid !== guid)
    },
    updated: true
  }),

  [REMOVE_STORY_DRINK]: (state, { guid }) => ({
    ...state,
    data: {
      ...state.data,
      storiesDrinks: state.data.storiesDrinks.filter((storyDrink) => storyDrink.guid !== guid),
      catalogDrinks: state.data.catalogDrinks.filter(ci => !ci.storyDrinkGuid || ci.storyDrinkGuid !== guid)
    },
    updated: true
  }),

  [REMOVE_STORY_TABLE]: (state, { guid }) => ({
    ...state,
    data: {
      ...state.data,
      storiesTables: state.data.storiesTables.filter((storyTable) => storyTable.guid !== guid),
      catalogTables: state.data.catalogTables.filter(ci => !ci.storyGuid || ci.storyGuid !== guid)
    },
    updated: true
  }),

  [REMOVE_STORY_2_ITEMS]: (state, { guid }) => ({
    ...state,
    data: {
      ...state.data,
      stories2Items: state.data.stories2Items.filter((story) => story.guid !== guid),
      catalog2Items: state.data.catalog2Items.filter(ci => !ci.storyGuid || ci.storyGuid !== guid)
    },
    updated: true
  }),

  [REMOVE_STORY_3_ITEMS]: (state, { guid }) => ({
    ...state,
    data: {
      ...state.data,
      stories3Items: state.data.stories3Items.filter((story) => story.guid !== guid),
      catalog3Items: state.data.catalog3Items.filter(ci => !ci.storyGuid || ci.storyGuid !== guid)
    },
    updated: true
  }),

  [REMOVE_STORY_4_ITEMS]: (state, { guid }) => ({
    ...state,
    data: {
      ...state.data,
      stories4Items: state.data.stories4Items.filter((story) => story.guid !== guid),
      catalog4Items: state.data.catalog4Items.filter(ci => !ci.storyGuid || ci.storyGuid !== guid)
    },
    updated: true
  }),

  [REMOVE_STORY_5_ITEMS]: (state, { guid }) => ({
    ...state,
    data: {
      ...state.data,
      stories5Items: state.data.stories5Items.filter((story) => story.guid !== guid),
      catalog5Items: state.data.catalog5Items.filter(ci => !ci.storyGuid || ci.storyGuid !== guid)
    },
    updated: true
  }),

  [REMOVE_STORY_BOTTLES]: (state, { guid }) => ({
    ...state,
    data: {
      ...state.data,
      storiesBottles: state.data.storiesBottles.filter((story) => story.guid !== guid),
      catalogBottles: state.data.catalogBottles.filter(ci => !ci.storyGuid || ci.storyGuid !== guid)
    },
    updated: true
  }),

  [REMOVE_STORY_CAKES]: (state, { guid }) => ({
    ...state,
    data: {
      ...state.data,
      storiesCakes: state.data.storiesCakes.filter((story) => story.guid !== guid),
      catalogCakes: state.data.catalogCakes.filter(ci => !ci.storyGuid || ci.storyGuid !== guid)
    },
    updated: true
  }),

  [REMOVE_STORY_CIGARETTAS]: (state, { guid }) => ({
    ...state,
    data: {
      ...state.data,
      storiesCigarettas: state.data.storiesCigarettas.filter((story) => story.guid !== guid),
      catalogCigarettas: state.data.catalogCigarettas.filter(ci => !ci.storyGuid || ci.storyGuid !== guid)
    },
    updated: true
  }),

  [REMOVE_STORY_DISHES]: (state, { guid }) => ({
    ...state,
    data: {
      ...state.data,
      storiesDishes: state.data.storiesDishes.filter((story) => story.guid !== guid),
      catalogDishes: state.data.catalogDishes.filter(ci => !ci.storyGuid || ci.storyGuid !== guid)
    },
    updated: true
  }),

  [REMOVE_STORY_OFFERS]: (state, { guid }) => ({
    ...state,
    data: {
      ...state.data,
      storiesOffers: state.data.storiesOffers.filter((story) => story.guid !== guid),
      catalogOffers: state.data.catalogOffers.filter(ci => !ci.storyGuid || ci.storyGuid !== guid)
    },
    updated: true
  }),

  [REMOVE_STORY_SNACKS]: (state, { guid }) => ({
    ...state,
    data: {
      ...state.data,
      storiesSnacks: state.data.storiesSnacks.filter((story) => story.guid !== guid),
      catalogSnacks: state.data.catalogSnacks.filter(ci => !ci.storyGuid || ci.storyGuid !== guid)
    },
    updated: true
  }),

  [REMOVE_STORY_SOFTDRINKS]: (state, { guid }) => ({
    ...state,
    data: {
      ...state.data,
      storiesSoftDrinks: state.data.storiesSoftDrinks.filter((story) => story.guid !== guid),
      catalogSoftDrinks: state.data.catalogSoftDrinks.filter(ci => !ci.storyGuid || ci.storyGuid !== guid)
    },
    updated: true
  }),


  // rotate story
  [ROTATE_STORY]: (state, { guid, order }) => {
    const { stories } = state.data;
    let currentStory = -1;
    stories.sort((a, b) => b.order - a.order);
    for (let i = 0; i < stories.length; i++) {
      if (stories[i].guid === guid)
        currentStory = i;
    }

    let nextStory = -1;
    if (order > 0 && currentStory !== 0)
      nextStory = currentStory - 1;
    else if (order < 0 && currentStory !== stories.length - 1)
      nextStory = currentStory + 1;

    if (nextStory > -1 && currentStory > -1) {
      const nextOrder = stories[nextStory].order;
      stories[nextStory].order = stories[currentStory].order;
      stories[currentStory].order = nextOrder;
    }

    return {
      ...state,
      data: {
        ...state.data,
        stories
      },
      updated: true
    };
  },

  [ROTATE_STORY_DRINK]: (state, { guid, order }) => {
    const { storiesDrinks } = state.data;
    let currentStoryDrink = -1;
    storiesDrinks.sort((a, b) => b.order - a.order);
    for (let i = 0; i < storiesDrinks.length; i++) {
      if (storiesDrinks[i].guid === guid)
        currentStoryDrink = i;
    }

    let nextStoryDrink = -1;
    if (order > 0 && currentStoryDrink !== 0)
      nextStoryDrink = currentStoryDrink - 1;
    else if (order < 0 && currentStoryDrink !== storiesDrinks.length - 1)
      nextStoryDrink = currentStoryDrink + 1;

    if (nextStoryDrink > -1 && currentStoryDrink > -1) {
      const nextOrder = storiesDrinks[nextStoryDrink].order;
      storiesDrinks[nextStoryDrink].order = storiesDrinks[currentStoryDrink].order;
      storiesDrinks[currentStoryDrink].order = nextOrder;
    }

    return {
      ...state,
      data: {
        ...state.data,
        storiesDrinks
      },
      updated: true
    };
  },

  [ROTATE_STORY_TABLE]: (state, { guid, order }) => {
    const { storiesTables } = state.data;
    let currentStoryTable = -1;
    storiesTables.sort((a, b) => b.order - a.order);
    for (let i = 0; i < storiesTables.length; i++) {
      if (storiesTables[i].guid === guid)
        currentStoryTable = i;
    }

    let nextStoryTable = -1;
    if (order > 0 && currentStoryTable !== 0)
      nextStoryTable = currentStoryTable - 1;
    else if (order < 0 && currentStoryTable !== storiesTables.length - 1)
      nextStoryTable = currentStoryTable + 1;

    if (nextStoryTable > -1 && currentStoryTable > -1) {
      const nextOrder = storiesTables[nextStoryTable].order;
      storiesTables[nextStoryTable].order = storiesTables[currentStoryTable].order;
      storiesTables[currentStoryTable].order = nextOrder;
    }

    return {
      ...state,
      data: {
        ...state.data,
        storiesTables
      },
      updated: true
    };
  },

  [ROTATE_STORY_2_ITEMS]: (state, { guid, order }) => {
    const { stories2Items } = state.data;
    let currentStory = -1;
    stories2Items.sort((a, b) => b.order - a.order);
    for (let i = 0; i < stories2Items.length; i++) {
      if (stories2Items[i].guid === guid)
        currentStory = i;
    }

    let nextStory = -1;
    if (order > 0 && currentStory !== 0)
      nextStory = currentStory - 1;
    else if (order < 0 && currentStory !== stories2Items.length - 1)
      nextStory = currentStory + 1;

    if (nextStory > -1 && currentStory > -1) {
      const nextOrder = stories2Items[nextStory].order;
      stories2Items[nextStory].order = stories2Items[currentStory].order;
      stories2Items[currentStory].order = nextOrder;
    }

    return {
      ...state,
      data: {
        ...state.data,
        stories2Items
      },
      updated: true
    };
  },

  [ROTATE_STORY_3_ITEMS]: (state, { guid, order }) => {
    const { stories3Items } = state.data;
    let currentStory = -1;
    stories3Items.sort((a, b) => b.order - a.order);
    for (let i = 0; i < stories3Items.length; i++) {
      if (stories3Items[i].guid === guid)
        currentStory = i;
    }

    let nextStory = -1;
    if (order > 0 && currentStory !== 0)
      nextStory = currentStory - 1;
    else if (order < 0 && currentStory !== stories3Items.length - 1)
      nextStory = currentStory + 1;

    if (nextStory > -1 && currentStory > -1) {
      const nextOrder = stories3Items[nextStory].order;
      stories3Items[nextStory].order = stories3Items[currentStory].order;
      stories3Items[currentStory].order = nextOrder;
    }

    return {
      ...state,
      data: {
        ...state.data,
        stories3Items
      },
      updated: true
    };
  },

  [ROTATE_STORY_4_ITEMS]: (state, { guid, order }) => {
    const { stories4Items } = state.data;
    let currentStory = -1;
    stories4Items.sort((a, b) => b.order - a.order);
    for (let i = 0; i < stories4Items.length; i++) {
      if (stories4Items[i].guid === guid)
        currentStory = i;
    }

    let nextStory = -1;
    if (order > 0 && currentStory !== 0)
      nextStory = currentStory - 1;
    else if (order < 0 && currentStory !== stories4Items.length - 1)
      nextStory = currentStory + 1;

    if (nextStory > -1 && currentStory > -1) {
      const nextOrder = stories4Items[nextStory].order;
      stories4Items[nextStory].order = stories4Items[currentStory].order;
      stories4Items[currentStory].order = nextOrder;
    }

    return {
      ...state,
      data: {
        ...state.data,
        stories4Items
      },
      updated: true
    };
  },

  [ROTATE_STORY_5_ITEMS]: (state, { guid, order }) => {
    const { stories5Items } = state.data;
    let currentStory = -1;
    stories5Items.sort((a, b) => b.order - a.order);
    for (let i = 0; i < stories5Items.length; i++) {
      if (stories5Items[i].guid === guid)
        currentStory = i;
    }

    let nextStory = -1;
    if (order > 0 && currentStory !== 0)
      nextStory = currentStory - 1;
    else if (order < 0 && currentStory !== stories5Items.length - 1)
      nextStory = currentStory + 1;

    if (nextStory > -1 && currentStory > -1) {
      const nextOrder = stories5Items[nextStory].order;
      stories5Items[nextStory].order = stories5Items[currentStory].order;
      stories5Items[currentStory].order = nextOrder;
    }

    return {
      ...state,
      data: {
        ...state.data,
        stories5Items
      },
      updated: true
    };
  },

  [ROTATE_STORY_BOTTLES]: (state, { guid, order }) => {
    const { storiesBottles } = state.data;
    let currentStory = -1;
    storiesBottles.sort((a, b) => b.order - a.order);
    for (let i = 0; i < storiesBottles.length; i++) {
      if (storiesBottles[i].guid === guid)
        currentStory = i;
    }

    let nextStory = -1;
    if (order > 0 && currentStory !== 0)
      nextStory = currentStory - 1;
    else if (order < 0 && currentStory !== storiesBottles.length - 1)
      nextStory = currentStory + 1;

    if (nextStory > -1 && currentStory > -1) {
      const nextOrder = storiesBottles[nextStory].order;
      storiesBottles[nextStory].order = storiesBottles[currentStory].order;
      storiesBottles[currentStory].order = nextOrder;
    }

    return {
      ...state,
      data: {
        ...state.data,
        storiesBottles
      },
      updated: true
    };
  },

  [ROTATE_STORY_CAKES]: (state, { guid, order }) => {
    const { storiesCakes } = state.data;
    let currentStory = -1;
    storiesCakes.sort((a, b) => b.order - a.order);
    for (let i = 0; i < storiesCakes.length; i++) {
      if (storiesCakes[i].guid === guid)
        currentStory = i;
    }

    let nextStory = -1;
    if (order > 0 && currentStory !== 0)
      nextStory = currentStory - 1;
    else if (order < 0 && currentStory !== storiesCakes.length - 1)
      nextStory = currentStory + 1;

    if (nextStory > -1 && currentStory > -1) {
      const nextOrder = storiesCakes[nextStory].order;
      storiesCakes[nextStory].order = storiesCakes[currentStory].order;
      storiesCakes[currentStory].order = nextOrder;
    }

    return {
      ...state,
      data: {
        ...state.data,
        storiesCakes
      },
      updated: true
    };
  },

  [ROTATE_STORY_CIGARETTAS]: (state, { guid, order }) => {
    const { storiesCigarettas } = state.data;
    let currentStory = -1;
    storiesCigarettas.sort((a, b) => b.order - a.order);
    for (let i = 0; i < storiesCigarettas.length; i++) {
      if (storiesCigarettas[i].guid === guid)
        currentStory = i;
    }

    let nextStory = -1;
    if (order > 0 && currentStory !== 0)
      nextStory = currentStory - 1;
    else if (order < 0 && currentStory !== storiesCigarettas.length - 1)
      nextStory = currentStory + 1;

    if (nextStory > -1 && currentStory > -1) {
      const nextOrder = storiesCigarettas[nextStory].order;
      storiesCigarettas[nextStory].order = storiesCigarettas[currentStory].order;
      storiesCigarettas[currentStory].order = nextOrder;
    }

    return {
      ...state,
      data: {
        ...state.data,
        storiesCigarettas
      },
      updated: true
    };
  },

  [ROTATE_STORY_DISHES]: (state, { guid, order }) => {
    const { storiesDishes } = state.data;
    let currentStory = -1;
    storiesDishes.sort((a, b) => b.order - a.order);
    for (let i = 0; i < storiesDishes.length; i++) {
      if (storiesDishes[i].guid === guid)
        currentStory = i;
    }

    let nextStory = -1;
    if (order > 0 && currentStory !== 0)
      nextStory = currentStory - 1;
    else if (order < 0 && currentStory !== storiesDishes.length - 1)
      nextStory = currentStory + 1;

    if (nextStory > -1 && currentStory > -1) {
      const nextOrder = storiesDishes[nextStory].order;
      storiesDishes[nextStory].order = storiesDishes[currentStory].order;
      storiesDishes[currentStory].order = nextOrder;
    }

    return {
      ...state,
      data: {
        ...state.data,
        storiesDishes
      },
      updated: true
    };
  },

  [ROTATE_STORY_OFFERS]: (state, { guid, order }) => {
    const { storiesOffers } = state.data;
    let currentStory = -1;
    storiesOffers.sort((a, b) => b.order - a.order);
    for (let i = 0; i < storiesOffers.length; i++) {
      if (storiesOffers[i].guid === guid)
        currentStory = i;
    }

    let nextStory = -1;
    if (order > 0 && currentStory !== 0)
      nextStory = currentStory - 1;
    else if (order < 0 && currentStory !== storiesOffers.length - 1)
      nextStory = currentStory + 1;

    if (nextStory > -1 && currentStory > -1) {
      const nextOrder = storiesOffers[nextStory].order;
      storiesOffers[nextStory].order = storiesOffers[currentStory].order;
      storiesOffers[currentStory].order = nextOrder;
    }

    return {
      ...state,
      data: {
        ...state.data,
        storiesOffers
      },
      updated: true
    };
  },

  [ROTATE_STORY_SNACKS]: (state, { guid, order }) => {
    const { storiesSnacks } = state.data;
    let currentStory = -1;
    storiesSnacks.sort((a, b) => b.order - a.order);
    for (let i = 0; i < storiesSnacks.length; i++) {
      if (storiesSnacks[i].guid === guid)
        currentStory = i;
    }

    let nextStory = -1;
    if (order > 0 && currentStory !== 0)
      nextStory = currentStory - 1;
    else if (order < 0 && currentStory !== storiesSnacks.length - 1)
      nextStory = currentStory + 1;

    if (nextStory > -1 && currentStory > -1) {
      const nextOrder = storiesSnacks[nextStory].order;
      storiesSnacks[nextStory].order = storiesSnacks[currentStory].order;
      storiesSnacks[currentStory].order = nextOrder;
    }

    return {
      ...state,
      data: {
        ...state.data,
        storiesSnacks
      },
      updated: true
    };
  },

  [ROTATE_STORY_SOFTDRINKS]: (state, { guid, order }) => {
    const { storiesSoftDrinks } = state.data;
    let currentStory = -1;
    storiesSoftDrinks.sort((a, b) => b.order - a.order);
    for (let i = 0; i < storiesSoftDrinks.length; i++) {
      if (storiesSoftDrinks[i].guid === guid)
        currentStory = i;
    }

    let nextStory = -1;
    if (order > 0 && currentStory !== 0)
      nextStory = currentStory - 1;
    else if (order < 0 && currentStory !== storiesSoftDrinks.length - 1)
      nextStory = currentStory + 1;

    if (nextStory > -1 && currentStory > -1) {
      const nextOrder = storiesSoftDrinks[nextStory].order;
      storiesSoftDrinks[nextStory].order = storiesSoftDrinks[currentStory].order;
      storiesSoftDrinks[currentStory].order = nextOrder;
    }

    return {
      ...state,
      data: {
        ...state.data,
        storiesSoftDrinks
      },
      updated: true
    };
  },

  // edit catalog

  [EDIT_CATALOG]: (state, { payload }) => {
    const currentItem = state.data.catalogItems.find((item) => payload.guid === item.guid);
    const { catalogItems } = state.data;
    const newOrder = catalogItems.reduce((result, item) => (item.order > result ? item.order : result), 0) + 1;

    const { order, ...catalogItemData } = payload;

    if (!currentItem) {
      return {
        ...state,
        data: {
          ...state.data,
          catalogItems: [...catalogItems, { ...catalogItemData, order: newOrder }]
        },
        updated: true
      };
    }
    return {
      ...state,
      data: {
        ...state.data,
        catalogItems: catalogItems.map((item) => (item.guid === catalogItemData.guid ? { ...item, ...catalogItemData } : item))
      },
      updated: true
    };
  },

  [EDIT_CATALOG_DRINK]: (state, { payload }) => {
    const currentDrink = state.data.catalogDrinks.find((drink) => payload.guid === drink.guid);
    const { catalogDrinks } = state.data;
    const newOrder = catalogDrinks.reduce((result, drink) => (drink.order > result ? drink.order : result), 0) + 1;

    const { order, ...catalogDrinkData } = payload;

    if (!currentDrink) {
      return {
        ...state,
        data: {
          ...state.data,
          catalogDrinks: [...catalogDrinks, { ...catalogDrinkData, order: newOrder }]
        },
        updated: true
      };
    }
    return {
      ...state,
      data: {
        ...state.data,
        catalogDrinks: catalogDrinks.map((drink) => (drink.guid === catalogDrinkData.guid ? { ...drink, ...catalogDrinkData } : drink))
      },
      updated: true
    };
  },

  [EDIT_CATALOG_2_ITEMS]: (state, { payload }) => {
    const currentItem = state.data.catalog2Items.find((item) => payload.guid === item.guid);
    const { catalog2Items } = state.data;
    const newOrder = catalog2Items.reduce((result, item) => (item.order > result ? item.order : result), 0) + 1;

    const { order, ...catalogItemData } = payload;

    if (!currentItem) {
      return {
        ...state,
        data: {
          ...state.data,
          catalog2Items: [...catalog2Items, { ...catalogItemData, order: newOrder }]
        },
        updated: true
      };
    }
    return {
      ...state,
      data: {
        ...state.data,
        catalog2Items: catalog2Items.map((item) => (item.guid === catalogItemData.guid ? { ...item, ...catalogItemData } : item))
      },
      updated: true
    };
  },

  [EDIT_CATALOG_3_ITEMS]: (state, { payload }) => {
    const currentItem = state.data.catalog3Items.find((item) => payload.guid === item.guid);
    const { catalog3Items } = state.data;
    const newOrder = catalog3Items.reduce((result, item) => (item.order > result ? item.order : result), 0) + 1;

    const { order, ...catalogItemData } = payload;

    if (!currentItem) {
      return {
        ...state,
        data: {
          ...state.data,
          catalog3Items: [...catalog3Items, { ...catalogItemData, order: newOrder }]
        },
        updated: true
      };
    }
    return {
      ...state,
      data: {
        ...state.data,
        catalog3Items: catalog3Items.map((item) => (item.guid === catalogItemData.guid ? { ...item, ...catalogItemData } : item))
      },
      updated: true
    };
  },

  [EDIT_CATALOG_4_ITEMS]: (state, { payload }) => {
    const currentItem = state.data.catalog4Items.find((item) => payload.guid === item.guid);
    const { catalog4Items } = state.data;
    const newOrder = catalog4Items.reduce((result, item) => (item.order > result ? item.order : result), 0) + 1;

    const { order, ...catalogItemData } = payload;

    if (!currentItem) {
      return {
        ...state,
        data: {
          ...state.data,
          catalog4Items: [...catalog4Items, { ...catalogItemData, order: newOrder }]
        },
        updated: true
      };
    }
    return {
      ...state,
      data: {
        ...state.data,
        catalog4Items: catalog4Items.map((item) => (item.guid === catalogItemData.guid ? { ...item, ...catalogItemData } : item))
      },
      updated: true
    };
  },

  [EDIT_CATALOG_5_ITEMS]: (state, { payload }) => {
    const currentItem = state.data.catalog5Items.find((item) => payload.guid === item.guid);
    const { catalog5Items } = state.data;
    const newOrder = catalog5Items.reduce((result, item) => (item.order > result ? item.order : result), 0) + 1;

    const { order, ...catalogItemData } = payload;

    if (!currentItem) {
      return {
        ...state,
        data: {
          ...state.data,
          catalog5Items: [...catalog5Items, { ...catalogItemData, order: newOrder }]
        },
        updated: true
      };
    }
    return {
      ...state,
      data: {
        ...state.data,
        catalog5Items: catalog5Items.map((item) => (item.guid === catalogItemData.guid ? { ...item, ...catalogItemData } : item))
      },
      updated: true
    };
  },

  [EDIT_CATALOG_BOTTLES]: (state, { payload }) => {
    const currentItem = state.data.catalogBottles.find((item) => payload.guid === item.guid);
    const { catalogBottles } = state.data;
    const newOrder = catalogBottles.reduce((result, item) => (item.order > result ? item.order : result), 0) + 1;

    const { order, ...catalogItemData } = payload;

    if (!currentItem) {
      return {
        ...state,
        data: {
          ...state.data,
          catalogBottles: [...catalogBottles, { ...catalogItemData, order: newOrder }]
        },
        updated: true
      };
    }
    return {
      ...state,
      data: {
        ...state.data,
        catalogBottles: catalogBottles.map((item) => (item.guid === catalogItemData.guid ? { ...item, ...catalogItemData } : item))
      },
      updated: true
    };
  },

  [EDIT_CATALOG_CAKES]: (state, { payload }) => {
    const currentItem = state.data.catalogCakes.find((item) => payload.guid === item.guid);
    const { catalogCakes } = state.data;
    const newOrder = catalogCakes.reduce((result, item) => (item.order > result ? item.order : result), 0) + 1;

    const { order, ...catalogItemData } = payload;

    if (!currentItem) {
      return {
        ...state,
        data: {
          ...state.data,
          catalogCakes: [...catalogCakes, { ...catalogItemData, order: newOrder }]
        },
        updated: true
      };
    }
    return {
      ...state,
      data: {
        ...state.data,
        catalogCakes: catalogCakes.map((item) => (item.guid === catalogItemData.guid ? { ...item, ...catalogItemData } : item))
      },
      updated: true
    };
  },

  [EDIT_CATALOG_CIGARETTAS]: (state, { payload }) => {
    const currentItem = state.data.catalogCigarettas.find((item) => payload.guid === item.guid);
    const { catalogCigarettas } = state.data;
    const newOrder = catalogCigarettas.reduce((result, item) => (item.order > result ? item.order : result), 0) + 1;

    const { order, ...catalogItemData } = payload;

    if (!currentItem) {
      return {
        ...state,
        data: {
          ...state.data,
          catalogCigarettas: [...catalogCigarettas, { ...catalogItemData, order: newOrder }]
        },
        updated: true
      };
    }
    return {
      ...state,
      data: {
        ...state.data,
        catalogCigarettas: catalogCigarettas.map((item) => (item.guid === catalogItemData.guid ? { ...item, ...catalogItemData } : item))
      },
      updated: true
    };
  },

  [EDIT_CATALOG_DISHES]: (state, { payload }) => {
    const currentItem = state.data.catalogDishes.find((item) => payload.guid === item.guid);
    const { catalogDishes } = state.data;
    const newOrder = catalogDishes.reduce((result, item) => (item.order > result ? item.order : result), 0) + 1;

    const { order, ...catalogItemData } = payload;

    if (!currentItem) {
      return {
        ...state,
        data: {
          ...state.data,
          catalogDishes: [...catalogDishes, { ...catalogItemData, order: newOrder }]
        },
        updated: true
      };
    }
    return {
      ...state,
      data: {
        ...state.data,
        catalogDishes: catalogDishes.map((item) => (item.guid === catalogItemData.guid ? { ...item, ...catalogItemData } : item))
      },
      updated: true
    };
  },

  [EDIT_CATALOG_OFFERS]: (state, { payload }) => {
    const currentItem = state.data.catalogOffers.find((item) => payload.guid === item.guid);
    const { catalogOffers } = state.data;
    const newOrder = catalogOffers.reduce((result, item) => (item.order > result ? item.order : result), 0) + 1;

    const { order, ...catalogItemData } = payload;

    if (!currentItem) {
      return {
        ...state,
        data: {
          ...state.data,
          catalogOffers: [...catalogOffers, { ...catalogItemData, order: newOrder }]
        },
        updated: true
      };
    }
    return {
      ...state,
      data: {
        ...state.data,
        catalogOffers: catalogOffers.map((item) => (item.guid === catalogItemData.guid ? { ...item, ...catalogItemData } : item))
      },
      updated: true
    };
  },

  [EDIT_CATALOG_SNACKS]: (state, { payload }) => {
    const currentItem = state.data.catalogSnacks.find((item) => payload.guid === item.guid);
    const { catalogSnacks } = state.data;
    const newOrder = catalogSnacks.reduce((result, item) => (item.order > result ? item.order : result), 0) + 1;

    const { order, ...catalogItemData } = payload;

    if (!currentItem) {
      return {
        ...state,
        data: {
          ...state.data,
          catalogSnacks: [...catalogSnacks, { ...catalogItemData, order: newOrder }]
        },
        updated: true
      };
    }
    return {
      ...state,
      data: {
        ...state.data,
        catalogSnacks: catalogSnacks.map((item) => (item.guid === catalogItemData.guid ? { ...item, ...catalogItemData } : item))
      },
      updated: true
    };
  },

  [EDIT_CATALOG_SOFTDRINKS]: (state, { payload }) => {
    const currentItem = state.data.catalogSoftDrinks.find((item) => payload.guid === item.guid);
    const { catalogSoftDrinks } = state.data;
    const newOrder = catalogSoftDrinks.reduce((result, item) => (item.order > result ? item.order : result), 0) + 1;

    const { order, ...catalogItemData } = payload;

    if (!currentItem) {
      return {
        ...state,
        data: {
          ...state.data,
          catalogSoftDrinks: [...catalogSoftDrinks, { ...catalogItemData, order: newOrder }]
        },
        updated: true
      };
    }
    return {
      ...state,
      data: {
        ...state.data,
        catalogSoftDrinks: catalogSoftDrinks.map((item) => (item.guid === catalogItemData.guid ? { ...item, ...catalogItemData } : item))
      },
      updated: true
    };
  },

  [EDIT_CATALOG_TABLE]: (state, { payload }) => {
    const currentCatalogTable = state.data.catalogTables.find((item) => payload.guid === item.guid);
    const { catalogTables } = state.data;
    const newOrder = catalogTables.reduce((result, item) => (item.order > result ? item.order : result), 0) + 1;

    const { order, ...catalogItemData } = payload;

    if (!currentCatalogTable) {
      return {
        ...state,
        data: {
          ...state.data,
          catalogTables: [...catalogTables, { ...catalogItemData, order: newOrder }]
        },
        updated: true
      };
    }
    return {
      ...state,
      data: {
        ...state.data,
        catalogTables: catalogTables.map((item) => (item.guid === catalogItemData.guid ? { ...item, ...catalogItemData } : item))
      },
      updated: true
    };
  },


  // remove catalog

  [REMOVE_CATALOG]: (state, { guid }) => ({
    ...state,
    data: {
      ...state.data,
      catalogItems: state.data.catalogItems.filter((item) => item.guid !== guid)
    },
    updated: true
  }),

  [REMOVE_CATALOG_DRINK]: (state, { guid }) => ({
    ...state,
    data: {
      ...state.data,
      catalogDrinks: state.data.catalogDrinks.filter((drink) => drink.guid !== guid)
    },
    updated: true
  }),

  [REMOVE_CATALOG_2_ITEMS]: (state, { guid }) => ({
    ...state,
    data: {
      ...state.data,
      catalog2Items: state.data.catalog2Items.filter((item) => item.guid !== guid)
    },
    updated: true
  }),

  [REMOVE_CATALOG_3_ITEMS]: (state, { guid }) => ({
    ...state,
    data: {
      ...state.data,
      catalog3Items: state.data.catalog3Items.filter((item) => item.guid !== guid)
    },
    updated: true
  }),

  [REMOVE_CATALOG_4_ITEMS]: (state, { guid }) => ({
    ...state,
    data: {
      ...state.data,
      catalog4Items: state.data.catalog4Items.filter((item) => item.guid !== guid)
    },
    updated: true
  }),

  [REMOVE_CATALOG_5_ITEMS]: (state, { guid }) => ({
    ...state,
    data: {
      ...state.data,
      catalog5Items: state.data.catalog5Items.filter((item) => item.guid !== guid)
    },
    updated: true
  }),

  [REMOVE_CATALOG_BOTTLES]: (state, { guid }) => ({
    ...state,
    data: {
      ...state.data,
      catalogBottles: state.data.catalogBottles.filter((item) => item.guid !== guid)
    },
    updated: true
  }),

  [REMOVE_CATALOG_CAKES]: (state, { guid }) => ({
    ...state,
    data: {
      ...state.data,
      catalogCakes: state.data.catalogCakes.filter((item) => item.guid !== guid)
    },
    updated: true
  }),

  [REMOVE_CATALOG_CIGARETTAS]: (state, { guid }) => ({
    ...state,
    data: {
      ...state.data,
      catalogCigarettas: state.data.catalogCigarettas.filter((item) => item.guid !== guid)
    },
    updated: true
  }),

  [REMOVE_CATALOG_DISHES]: (state, { guid }) => ({
    ...state,
    data: {
      ...state.data,
      catalogDishes: state.data.catalogDishes.filter((item) => item.guid !== guid)
    },
    updated: true
  }),

  [REMOVE_CATALOG_OFFERS]: (state, { guid }) => ({
    ...state,
    data: {
      ...state.data,
      catalogOffers: state.data.catalogOffers.filter((item) => item.guid !== guid)
    },
    updated: true
  }),

  [REMOVE_CATALOG_SNACKS]: (state, { guid }) => ({
    ...state,
    data: {
      ...state.data,
      catalogSnacks: state.data.catalogSnacks.filter((item) => item.guid !== guid)
    },
    updated: true
  }),

  [REMOVE_CATALOG_SOFTDRINKS]: (state, { guid }) => ({
    ...state,
    data: {
      ...state.data,
      catalogSoftDrinks: state.data.catalogSoftDrinks.filter((item) => item.guid !== guid)
    },
    updated: true
  }),

  [REMOVE_CATALOG_TABLE]: (state, { guid }) => ({
    ...state,
    data: {
      ...state.data,
      catalogTables: state.data.catalogTables.filter((item) => item.guid !== guid)
    },
    updated: true
  }),

  // rotate catalog

  [ROTATE_CATALOG]: (state, { guid, order }) => { 
    const { catalogItems } = state.data;
    const { storyGuidSelected } = state;

    const newArray = catalogItems
    .sort((a, b) => a.order - b.order).
    filter((item) => item.storyGuid === storyGuidSelected );

    function roughScale(x, base) {
      const parsed = parseInt(x, base);
      if (isNaN(parsed)) { return 0; }
      return parsed * 1000;
    }

    const numberStoryGuidSelected = roughScale(storyGuidSelected.slice(-4), 16);

    const newOrderNumbers = newArray.map((item, index) => ( {...item, order: numberStoryGuidSelected + index + 1 }  ));

    const catalogItemsWhithOut = catalogItems.filter((item) => item.storyGuid !== storyGuidSelected);

    const newCatalogItems = catalogItemsWhithOut.concat(newOrderNumbers);


    
    let currentItem = -1;
    newCatalogItems.sort((a, b) => b.order - a.order);
    for (let i = 0; i < newCatalogItems.length; i++) { 
      if (newCatalogItems[i].guid === guid)
        currentItem = i;
    }

    let nextItem = -1;
    if (order > 0 && currentItem !== 0)
      nextItem = currentItem - 1;
    else if (order < 0 && currentItem !== newCatalogItems.length - 1)
      nextItem = currentItem + 1;

    if (nextItem > -1 && currentItem > -1) {
      const nextOrder = newCatalogItems[nextItem].order;
      newCatalogItems[nextItem].order = newCatalogItems[currentItem].order;
      newCatalogItems[currentItem].order = nextOrder;
    }

    return {
      ...state,
      data: {
        ...state.data,
        catalogItems: newCatalogItems
      },
      updated: true
    };
  },

  [ROTATE_CATALOG_DRINK]: (state, { guid, order }) => {
    const { catalogDrinks } = state.data;
    const { storyDrinkGuidSelected } = state;

    const newArray = catalogDrinks
    .sort((a, b) => a.order - b.order).
    filter((item) => item.storyDrinkGuid === storyDrinkGuidSelected );

    function roughScale(x, base) {
      const parsed = parseInt(x, base);
      if (isNaN(parsed)) { return 0; }
      return parsed * 1000;
    }

    const numberStoryGuidSelected = roughScale(storyDrinkGuidSelected.slice(-4), 16);

    const newOrderNumbers = newArray.map((item, index) => ( {...item, order: numberStoryGuidSelected + index + 1 }  ));

    const catalogItemsWhithOut = catalogDrinks.filter((item) => item.storyDrinkGuid !== storyDrinkGuidSelected);

    const newCatalogItems = catalogItemsWhithOut.concat(newOrderNumbers);


    
    let currentItem = -1;
    newCatalogItems.sort((a, b) => b.order - a.order);
    for (let i = 0; i < newCatalogItems.length; i++) { 
      if (newCatalogItems[i].guid === guid)
        currentItem = i;
    }

    let nextItem = -1;
    if (order > 0 && currentItem !== 0)
      nextItem = currentItem - 1;
    else if (order < 0 && currentItem !== newCatalogItems.length - 1)
      nextItem = currentItem + 1;

    if (nextItem > -1 && currentItem > -1) {
      const nextOrder = newCatalogItems[nextItem].order;
      newCatalogItems[nextItem].order = newCatalogItems[currentItem].order;
      newCatalogItems[currentItem].order = nextOrder;
    }

    return {
      ...state,
      data: {
        ...state.data,
        catalogDrinks: newCatalogItems
      },
      updated: true
    };
  },

  [ROTATE_CATALOG_2_ITEMS]: (state, { guid, order }) => {
    const { catalog2Items } = state.data;
    let currentItem = -1;
    catalog2Items.sort((a, b) => b.order - a.order);
    for (let i = 0; i < catalog2Items.length; i++) {
      if (catalog2Items[i].guid === guid)
        currentItem = i;
    }

    let nextItem = -1;
    if (order > 0 && currentItem !== 0)
      nextItem = currentItem - 1;
    else if (order < 0 && currentItem !== catalog2Items.length - 1)
      nextItem = currentItem + 1;

    if (nextItem > -1 && currentItem > -1) {
      const nextOrder = catalog2Items[nextItem].order;
      catalog2Items[nextItem].order = catalog2Items[currentItem].order;
      catalog2Items[currentItem].order = nextOrder;
    }

    return {
      ...state,
      data: {
        ...state.data,
        catalog2Items
      },
      updated: true
    };
  },

  [ROTATE_CATALOG_3_ITEMS]: (state, { guid, order }) => {
    const { catalog3Items } = state.data;
    let currentItem = -1;
    catalog3Items.sort((a, b) => b.order - a.order);
    for (let i = 0; i < catalog3Items.length; i++) {
      if (catalog3Items[i].guid === guid)
        currentItem = i;
    }

    let nextItem = -1;
    if (order > 0 && currentItem !== 0)
      nextItem = currentItem - 1;
    else if (order < 0 && currentItem !== catalog3Items.length - 1)
      nextItem = currentItem + 1;

    if (nextItem > -1 && currentItem > -1) {
      const nextOrder = catalog3Items[nextItem].order;
      catalog3Items[nextItem].order = catalog3Items[currentItem].order;
      catalog3Items[currentItem].order = nextOrder;
    }

    return {
      ...state,
      data: {
        ...state.data,
        catalog3Items
      },
      updated: true
    };
  },

  [ROTATE_CATALOG_4_ITEMS]: (state, { guid, order }) => {
    const { catalog4Items } = state.data;
    let currentItem = -1;
    catalog4Items.sort((a, b) => b.order - a.order);
    for (let i = 0; i < catalog4Items.length; i++) {
      if (catalog4Items[i].guid === guid)
        currentItem = i;
    }

    let nextItem = -1;
    if (order > 0 && currentItem !== 0)
      nextItem = currentItem - 1;
    else if (order < 0 && currentItem !== catalog4Items.length - 1)
      nextItem = currentItem + 1;

    if (nextItem > -1 && currentItem > -1) {
      const nextOrder = catalog4Items[nextItem].order;
      catalog4Items[nextItem].order = catalog4Items[currentItem].order;
      catalog4Items[currentItem].order = nextOrder;
    }

    return {
      ...state,
      data: {
        ...state.data,
        catalog4Items
      },
      updated: true
    };
  },

  [ROTATE_CATALOG_5_ITEMS]: (state, { guid, order }) => {
    const { catalog5Items } = state.data;
    let currentItem = -1;
    catalog5Items.sort((a, b) => b.order - a.order);
    for (let i = 0; i < catalog5Items.length; i++) {
      if (catalog5Items[i].guid === guid)
        currentItem = i;
    }

    let nextItem = -1;
    if (order > 0 && currentItem !== 0)
      nextItem = currentItem - 1;
    else if (order < 0 && currentItem !== catalog5Items.length - 1)
      nextItem = currentItem + 1;

    if (nextItem > -1 && currentItem > -1) {
      const nextOrder = catalog5Items[nextItem].order;
      catalog5Items[nextItem].order = catalog5Items[currentItem].order;
      catalog5Items[currentItem].order = nextOrder;
    }

    return {
      ...state,
      data: {
        ...state.data,
        catalog5Items
      },
      updated: true
    };
  },

  [ROTATE_CATALOG_BOTTLES]: (state, { guid, order }) => {
    const { catalogBottles } = state.data;
    let currentItem = -1;
    catalogBottles.sort((a, b) => b.order - a.order);
    for (let i = 0; i < catalogBottles.length; i++) {
      if (catalogBottles[i].guid === guid)
        currentItem = i;
    }

    let nextItem = -1;
    if (order > 0 && currentItem !== 0)
      nextItem = currentItem - 1;
    else if (order < 0 && currentItem !== catalogBottles.length - 1)
      nextItem = currentItem + 1;

    if (nextItem > -1 && currentItem > -1) {
      const nextOrder = catalogBottles[nextItem].order;
      catalogBottles[nextItem].order = catalogBottles[currentItem].order;
      catalogBottles[currentItem].order = nextOrder;
    }

    return {
      ...state,
      data: {
        ...state.data,
        catalogBottles
      },
      updated: true
    };
  },

  [ROTATE_CATALOG_CAKES]: (state, { guid, order }) => {
    const { catalogCakes } = state.data;
    let currentItem = -1;
    catalogCakes.sort((a, b) => b.order - a.order);
    for (let i = 0; i < catalogCakes.length; i++) {
      if (catalogCakes[i].guid === guid)
        currentItem = i;
    }

    let nextItem = -1;
    if (order > 0 && currentItem !== 0)
      nextItem = currentItem - 1;
    else if (order < 0 && currentItem !== catalogCakes.length - 1)
      nextItem = currentItem + 1;

    if (nextItem > -1 && currentItem > -1) {
      const nextOrder = catalogCakes[nextItem].order;
      catalogCakes[nextItem].order = catalogCakes[currentItem].order;
      catalogCakes[currentItem].order = nextOrder;
    }

    return {
      ...state,
      data: {
        ...state.data,
        catalogCakes
      },
      updated: true
    };
  },

  [ROTATE_CATALOG_CIGARETTAS]: (state, { guid, order }) => {
    const { catalogCigarettas } = state.data;
    let currentItem = -1;
    catalogCigarettas.sort((a, b) => b.order - a.order);
    for (let i = 0; i < catalogCigarettas.length; i++) {
      if (catalogCigarettas[i].guid === guid)
        currentItem = i;
    }

    let nextItem = -1;
    if (order > 0 && currentItem !== 0)
      nextItem = currentItem - 1;
    else if (order < 0 && currentItem !== catalogCigarettas.length - 1)
      nextItem = currentItem + 1;

    if (nextItem > -1 && currentItem > -1) {
      const nextOrder = catalogCigarettas[nextItem].order;
      catalogCigarettas[nextItem].order = catalogCigarettas[currentItem].order;
      catalogCigarettas[currentItem].order = nextOrder;
    }

    return {
      ...state,
      data: {
        ...state.data,
        catalogCigarettas
      },
      updated: true
    };
  },

  [ROTATE_CATALOG_DISHES]: (state, { guid, order }) => {
    const { catalogDishes } = state.data;
    let currentItem = -1;
    catalogDishes.sort((a, b) => b.order - a.order);
    for (let i = 0; i < catalogDishes.length; i++) {
      if (catalogDishes[i].guid === guid)
        currentItem = i;
    }

    let nextItem = -1;
    if (order > 0 && currentItem !== 0)
      nextItem = currentItem - 1;
    else if (order < 0 && currentItem !== catalogDishes.length - 1)
      nextItem = currentItem + 1;

    if (nextItem > -1 && currentItem > -1) {
      const nextOrder = catalogDishes[nextItem].order;
      catalogDishes[nextItem].order = catalogDishes[currentItem].order;
      catalogDishes[currentItem].order = nextOrder;
    }

    return {
      ...state,
      data: {
        ...state.data,
        catalogDishes
      },
      updated: true
    };
  },

  [ROTATE_CATALOG_OFFERS]: (state, { guid, order }) => {
    const { catalogOffers } = state.data;
    let currentItem = -1;
    catalogOffers.sort((a, b) => b.order - a.order);
    for (let i = 0; i < catalogOffers.length; i++) {
      if (catalogOffers[i].guid === guid)
        currentItem = i;
    }

    let nextItem = -1;
    if (order > 0 && currentItem !== 0)
      nextItem = currentItem - 1;
    else if (order < 0 && currentItem !== catalogOffers.length - 1)
      nextItem = currentItem + 1;

    if (nextItem > -1 && currentItem > -1) {
      const nextOrder = catalogOffers[nextItem].order;
      catalogOffers[nextItem].order = catalogOffers[currentItem].order;
      catalogOffers[currentItem].order = nextOrder;
    }

    return {
      ...state,
      data: {
        ...state.data,
        catalogOffers
      },
      updated: true
    };
  },

  [ROTATE_CATALOG_SNACKS]: (state, { guid, order }) => {
    const { catalogSnacks } = state.data;
    let currentItem = -1;
    catalogSnacks.sort((a, b) => b.order - a.order);
    for (let i = 0; i < catalogSnacks.length; i++) {
      if (catalogSnacks[i].guid === guid)
        currentItem = i;
    }

    let nextItem = -1;
    if (order > 0 && currentItem !== 0)
      nextItem = currentItem - 1;
    else if (order < 0 && currentItem !== catalogSnacks.length - 1)
      nextItem = currentItem + 1;

    if (nextItem > -1 && currentItem > -1) {
      const nextOrder = catalogSnacks[nextItem].order;
      catalogSnacks[nextItem].order = catalogSnacks[currentItem].order;
      catalogSnacks[currentItem].order = nextOrder;
    }

    return {
      ...state,
      data: {
        ...state.data,
        catalogSnacks
      },
      updated: true
    };
  },

  [ROTATE_CATALOG_SOFTDRINKS]: (state, { guid, order }) => {
    const { catalogSoftDrinks } = state.data;
    let currentItem = -1;
    catalogSoftDrinks.sort((a, b) => b.order - a.order);
    for (let i = 0; i < catalogSoftDrinks.length; i++) {
      if (catalogSoftDrinks[i].guid === guid)
        currentItem = i;
    }

    let nextItem = -1;
    if (order > 0 && currentItem !== 0)
      nextItem = currentItem - 1;
    else if (order < 0 && currentItem !== catalogSoftDrinks.length - 1)
      nextItem = currentItem + 1;

    if (nextItem > -1 && currentItem > -1) {
      const nextOrder = catalogSoftDrinks[nextItem].order;
      catalogSoftDrinks[nextItem].order = catalogSoftDrinks[currentItem].order;
      catalogSoftDrinks[currentItem].order = nextOrder;
    }

    return {
      ...state,
      data: {
        ...state.data,
        catalogSoftDrinks
      },
      updated: true
    };
  },

  [ROTATE_CATALOG_TABLE]: (state, { guid, order }) => {
    const { catalogTables } = state.data;
    let currentCatalogTable = -1;
    catalogTables.sort((a, b) => b.order - a.order);
    for (let i = 0; i < catalogTables.length; i++) {
      if (catalogTables[i].guid === guid)
      currentCatalogTable = i;
    }

    let nextItem = -1;
    if (order > 0 && currentCatalogTable !== 0)
      nextItem = currentCatalogTable - 1;
    else if (order < 0 && currentCatalogTable !== catalogTables.length - 1)
      nextItem = currentCatalogTable + 1;

    if (nextItem > -1 && currentCatalogTable > -1) {
      const nextOrder = catalogTables[nextItem].order;
      catalogTables[nextItem].order = catalogTables[currentCatalogTable].order;
      catalogTables[currentCatalogTable].order = nextOrder;
    }

    return {
      ...state,
      data: {
        ...state.data,
        catalogTables
      },
      updated: true
    };
  },

  // import catalog
  [IMPORT_CATALOG]: (state, { items, storyGuid }) => {
    var catalogItems = state.data.catalogItems;

    if (!storyGuid) {
      catalogItems = items;
    } else {
      catalogItems = catalogItems.filter(ci => ci.storyGuid !== storyGuid)
      Array.prototype.push.apply(catalogItems, items)
    }

    return {
      ...state,
      data: {
        ...state.data,
        catalogItems
      },
      updated: true
    };
  },

  [IMPORT_CATALOG_DRINK]: (state, { itemsDrinks, storyDrinkGuid }) => {
    var catalogDrinks = state.data.catalogDrinks;

    if (!storyDrinkGuid) {
      catalogDrinks = itemsDrinks;
    } else {
      catalogDrinks = catalogDrinks.filter(ci => ci.storyDrinkGuid !== storyDrinkGuid)
      Array.prototype.push.apply(catalogDrinks, itemsDrinks)
    }

    return {
      ...state,
      data: {
        ...state.data,
        catalogDrinks
      },
      updated: true
    };
  },

  [IMPORT_CATALOG_TABLE]: (state, { itemsTables, storyGuid }) => {
    var catalogTables = state.data.catalogTables;

    if (!storyGuid) {
      catalogTables = itemsTables;
    } else {
      catalogTables = catalogTables.filter(ci => ci.storyGuid !== storyGuid)
      Array.prototype.push.apply(catalogTables, itemsTables)
    }

    return {
      ...state,
      data: {
        ...state.data,
        catalogTables
      },
      updated: true
    };
  },

  [IMPORT_CATALOG_2_ITEMS]: (state, { items, storyGuid }) => {
    var catalog2Items = state.data.catalog2Items;

    if (!storyGuid) {
      catalog2Items = items;
    } else {
      catalog2Items = catalog2Items.filter(ci => ci.storyGuid !== storyGuid)
      Array.prototype.push.apply(catalog2Items, items)
    }

    return {
      ...state,
      data: {
        ...state.data,
        catalog2Items
      },
      updated: true
    };
  },

  [IMPORT_CATALOG_3_ITEMS]: (state, { items, storyGuid }) => {
    var catalog3Items = state.data.catalog3Items;

    if (!storyGuid) {
      catalog3Items = items;
    } else {
      catalog3Items = catalog3Items.filter(ci => ci.storyGuid !== storyGuid)
      Array.prototype.push.apply(catalog3Items, items)
    }

    return {
      ...state,
      data: {
        ...state.data,
        catalog3Items
      },
      updated: true
    };
  },

  [IMPORT_CATALOG_4_ITEMS]: (state, { items, storyGuid }) => {
    var catalog4Items = state.data.catalog4Items;

    if (!storyGuid) {
      catalog4Items = items;
    } else {
      catalog4Items = catalog4Items.filter(ci => ci.storyGuid !== storyGuid)
      Array.prototype.push.apply(catalog4Items, items)
    }

    return {
      ...state,
      data: {
        ...state.data,
        catalog4Items
      },
      updated: true
    };
  },

  [IMPORT_CATALOG_5_ITEMS]: (state, { items, storyGuid }) => {
    var catalog5Items = state.data.catalog5Items;

    if (!storyGuid) {
      catalog5Items = items;
    } else {
      catalog5Items = catalog5Items.filter(ci => ci.storyGuid !== storyGuid)
      Array.prototype.push.apply(catalog5Items, items)
    }

    return {
      ...state,
      data: {
        ...state.data,
        catalog5Items
      },
      updated: true
    };
  },

  [IMPORT_CATALOG_BOTTLES]: (state, { items, storyGuid }) => {
    var catalogBottles = state.data.catalogBottles;

    if (!storyGuid) {
      catalogBottles = items;
    } else {
      catalogBottles = catalogBottles.filter(ci => ci.storyGuid !== storyGuid)
      Array.prototype.push.apply(catalogBottles, items)
    }

    return {
      ...state,
      data: {
        ...state.data,
        catalogBottles
      },
      updated: true
    };
  },

  [IMPORT_CATALOG_CAKES]: (state, { items, storyGuid }) => {
    var catalogCakes = state.data.catalogCakes;

    if (!storyGuid) {
      catalogCakes = items;
    } else {
      catalogCakes = catalogCakes.filter(ci => ci.storyGuid !== storyGuid)
      Array.prototype.push.apply(catalogCakes, items)
    }

    return {
      ...state,
      data: {
        ...state.data,
        catalogCakes
      },
      updated: true
    };
  },

  [IMPORT_CATALOG_CIGARETTAS]: (state, { items, storyGuid }) => {
    var catalogCigarettas = state.data.catalogCigarettas;

    if (!storyGuid) {
      catalogCigarettas = items;
    } else {
      catalogCigarettas = catalogCigarettas.filter(ci => ci.storyGuid !== storyGuid)
      Array.prototype.push.apply(catalogCigarettas, items)
    }

    return {
      ...state,
      data: {
        ...state.data,
        catalogCigarettas
      },
      updated: true
    };
  },

  [IMPORT_CATALOG_DISHES]: (state, { items, storyGuid }) => {
    var catalogDishes = state.data.catalogDishes;

    if (!storyGuid) {
      catalogDishes = items;
    } else {
      catalogDishes = catalogDishes.filter(ci => ci.storyGuid !== storyGuid)
      Array.prototype.push.apply(catalogDishes, items)
    }

    return {
      ...state,
      data: {
        ...state.data,
        catalogDishes
      },
      updated: true
    };
  },

  [IMPORT_CATALOG_OFFERS]: (state, { items, storyGuid }) => {
    var catalogOffers = state.data.catalogOffers;

    if (!storyGuid) {
      catalogOffers = items;
    } else {
      catalogOffers = catalogOffers.filter(ci => ci.storyGuid !== storyGuid)
      Array.prototype.push.apply(catalogOffers, items)
    }

    return {
      ...state,
      data: {
        ...state.data,
        catalogOffers
      },
      updated: true
    };
  },

  [IMPORT_CATALOG_SNACKS]: (state, { items, storyGuid }) => {
    var catalogSnacks = state.data.catalogSnacks;

    if (!storyGuid) {
      catalogSnacks = items;
    } else {
      catalogSnacks = catalogSnacks.filter(ci => ci.storyGuid !== storyGuid)
      Array.prototype.push.apply(catalogSnacks, items)
    }

    return {
      ...state,
      data: {
        ...state.data,
        catalogSnacks
      },
      updated: true
    };
  },

  [IMPORT_CATALOG_SOFTDRINKS]: (state, { items, storyGuid }) => {
    var catalogSoftDrinks = state.data.catalogSoftDrinks;

    if (!storyGuid) {
      catalogSoftDrinks = items;
    } else {
      catalogSoftDrinks = catalogSoftDrinks.filter(ci => ci.storyGuid !== storyGuid)
      Array.prototype.push.apply(catalogSoftDrinks, items)
    }

    return {
      ...state,
      data: {
        ...state.data,
        catalogSoftDrinks
      },
      updated: true
    };
  },

  // catalog filter
  [CATALOG_FILTER]: (state, { storyGuid }) => {
    return {
      ...state,
      storyGuid,
      storyGuidSelected: storyGuid,
    }
  },

  [CATALOG_DRINK_FILTER]: (state, { storyDrinkGuid }) => {
    return {
      ...state,
      storyDrinkGuid,
      storyDrinkGuidSelected: storyDrinkGuid,
    }
  },

  [TABLE_FILTER]: (state, { storyGuid }) => {
    return {
      ...state,
      storyGuid
    }
  },

  [CATALOG_2_ITEMS_FILTER]: (state, { storyGuid }) => {
    return {
      ...state,
      storyGuid
    }
  },

  [CATALOG_3_ITEMS_FILTER]: (state, { storyGuid }) => {
    return {
      ...state,
      storyGuid
    }
  },

  [CATALOG_4_ITEMS_FILTER]: (state, { storyGuid }) => {
    return {
      ...state,
      storyGuid
    }
  },

  [CATALOG_5_ITEMS_FILTER]: (state, { storyGuid }) => {
    return {
      ...state,
      storyGuid
    }
  },

  [CATALOG_BOTTLES_FILTER]: (state, { storyGuid }) => {
    return {
      ...state,
      storyGuid
    }
  },

  [CATALOG_CAKES_FILTER]: (state, { storyGuid }) => {
    return {
      ...state,
      storyGuid
    }
  },

  [CATALOG_CIGARETTAS_FILTER]: (state, { storyGuid }) => {
    return {
      ...state,
      storyGuid
    }
  },

  [CATALOG_DISHES_FILTER]: (state, { storyGuid }) => {
    return {
      ...state,
      storyGuid
    }
  },

  [CATALOG_OFFERS_FILTER]: (state, { storyGuid }) => {
    return {
      ...state,
      storyGuid
    }
  },

  [CATALOG_SNACKS_FILTER]: (state, { storyGuid }) => {
    return {
      ...state,
      storyGuid
    }
  },

  [CATALOG_SOFTDRINKS_FILTER]: (state, { storyGuid }) => {
    return {
      ...state,
      storyGuid
    }
  },

  // more

  [LOADING_ERROR]: (state, { error }) => ({
    ...state,
    error
  }),

  [SET_DATA]: (state, {
    themes,
    config,
    account,
    settingsName,
    data
  }) => {
    const selectedTheme = themes.find((theme) => data.settings.theme === theme.name)
      || (themes[0] && { ...themes[0] })
      || { ...state.themes[0] };

    let currentTheme = new Theme(selectedTheme);

    return {
      ...state,
      themes: themes.map((theme) => new Theme(theme)), config,
      account,
      data: {
        ...data,
        counter: data.counter ? data.counter + 1 : 1,
      },
      currentTheme,
      error: null,
      settingsName,
    };
  },

  [CHANGE_THEME]: (state, { name }) => {
    const currentTheme = state.themes.find((theme) => name === theme.name) || state.themes[0];
    return ({
      ...state,
      currentTheme,
      data: {
        ...state.data,
        settings: { ...state.data.settings, theme: name }
      },
      updated: true
    });
  },

  // blocks

  [EDIT_BLOCK]: (state, { payload }) => {
    const currentBlock = state.data.blocks.find((block) => payload.guid === block.guid);
    const { blocks } = state.data;
    const newOrder = blocks.reduce((result, block) => (block.order > result ? block.order : result), 0) + 1;

    const { order, ...blockData } = payload;

    if (!currentBlock) {
      return {
        ...state,
        data: {
          ...state.data,
          blocks: [...blocks, { ...blockData, order: newOrder }]
        },
        updated: true
      };
    }
    return {
      ...state,
      data: {
        ...state.data,
        blocks: blocks.map((block) => (block.guid === blockData.guid ? { ...block, ...blockData } : block))
      },
      updated: true
    };
  },

  [REMOVE_BLOCK]: (state, { guid }) => ({
    ...state,
    data: {
      ...state.data,
      blocks: state.data.blocks.filter((block) => block.guid !== guid)
    },
    updated: true
  }),

  [ROTATE_BLOCK]: (state, { guid, order }) => {
    const { blocks } = state.data;
    let currentBlock = -1;
    blocks.sort((a, b) => b.order - a.order);
    for (let i = 0; i < blocks.length; i++) {
      if (blocks[i].guid === guid)
        currentBlock = i;
    }

    let nextBlock = -1;
    if (order > 0 && currentBlock !== 0)
      nextBlock = currentBlock - 1;
    else if (order < 0 && currentBlock !== blocks.length - 1)
      nextBlock = currentBlock + 1;

    if (nextBlock > -1 && currentBlock > -1) {
      const nextOrder = blocks[nextBlock].order;
      blocks[nextBlock].order = blocks[currentBlock].order;
      blocks[currentBlock].order = nextOrder;
    }

    return {
      ...state,
      data: {
        ...state.data,
        blocks
      },
      updated: true
    };
  },

  [EDIT_AVATAR]: (state, { payload }) => {
    const currentAvatar = state.data.avatars.find((avatar) => payload.guid === avatar.guid);
    const { avatars } = state.data;

    const { ...avatarData } = payload;

    if (!currentAvatar) {
      return {
        ...state,
        data: {
          ...state.data,
          avatars: [...avatars, { ...avatarData }]
        },
        updated: true
      };
    }
    return {
      ...state,
      data: {
        ...state.data,
        avatars: avatars.map((avatar) => (avatar.guid === avatarData.guid ? { ...avatar, ...avatarData } : avatar))
      },
      updated: true
    };
  },

  [EDIT_GOOGLE_MAPS]: (state, { payload }) => {
    const currentGoogleMap = state.data.googleMaps.find((googleMap) => payload.guid === googleMap.guid);
    const { googleMaps } = state.data;

    const { ...googleMapData } = payload;

    if (!currentGoogleMap) {
      return {
        ...state,
        data: {
          ...state.data,
          googleMaps: [...googleMaps, { ...googleMapData }]
        },
        updated: true
      };
    }
    return {
      ...state,
      data: {
        ...state.data,
        googleMaps: googleMaps.map((googleMap) => (googleMap.guid === googleMapData.guid ? { ...googleMap, ...googleMapData } : googleMap))
      },
      updated: true
    };
  },

  [REMOVE_GOOGLE_MAPS]: (state, { guid }) => ({
    ...state,
    data: {
      ...state.data,
      googleMaps: state.data.googleMaps.filter((googleMap) => googleMap.guid !== guid)
    },
    updated: true
  }),

  [EDIT_AD]: (state, { payload }) => {
    const currentAd = state.data.ads.find((ad) => payload.guid === ad.guid);
    const { ads } = state.data;
    const newOrder = ads.reduce((result, ad) => (ad.order > result ? ad.order : result), 0) + 1;

    const { order, ...adData } = payload;

    if (!currentAd) {
      return {
        ...state,
        data: {
          ...state.data,
          ads: [...ads, { ...adData, order: newOrder }]
        },
        updated: true
      };
    }
    return {
      ...state,
      data: {
        ...state.data,
        ads: ads.map((ad) => (ad.guid === adData.guid ? { ...ad, ...adData } : ad))
      },
      updated: true
    };
  },

  [REMOVE_AD]: (state, { guid }) => ({
    ...state,
    data: {
      ...state.data,
      ads: state.data.ads.filter((ad) => ad.guid !== guid)
    },
    updated: true
  }),

  [EDIT_SOCIAL_DATA]: (state, { data }) => ({
    ...state,
    data: {
      ...state.data,
      social: data
    },
    updated: true
  }),

  [EDIT_MESSENGERS_DATA]: (state, { data }) => ({
    ...state,
    data: {
      ...state.data,
      messengers: data
    },
    updated: true
  }),


  [EDIT_REVIEWS_DATA]: (state, { data }) => ({
    ...state,
    data: {
      ...state.data,
      reviews: data
    },
    updated: true
  }),
  //
  [EDIT_OPENING_HOURS_DATA]: (state, { data }) => ({
    ...state,
    data: {
      ...state.data,
      openingHours: data
    },
    updated: true
  }),

  [EDIT_RESERVATIONS_DATA]: (state, { data }) => ({
    ...state,
    data: {
      ...state.data,
      reservations: data
    },
    updated: true
  }),

  [EDIT_CALL_WAITER_DATA]: (state, { data }) => ({
    ...state,
    data: {
      ...state.data,
      callWaiter: data
    },
    updated: true
  }),

  [EDIT_DELIVERY_DATA]: (state, { data }) => ({
    ...state,
    data: {
      ...state.data,
      delivery: data
    },
    updated: true
  }),

  [EDIT_TAKE_AWAY_DATA]: (state, { data }) => ({
    ...state,
    data: {
      ...state.data,
      takeAway: data
    },
    updated: true
  }),

  [UPDATE_CONFIG_DATA]: (state, { name, avatar, title, description, googleAnalytics, appWaiterLink, constructor, seoSettings }) => ({
    ...state,
    data: {
      ...state.data,
      name,
      avatar,
      title,
      description,
      constructor,
      googleAnalytics,
      appWaiterLink,
      seoSettings,
    },
    
    updated: true
  }),

  [DATA_ALREADY_SAVED]: (state) => ({
    ...state,
    updated: false,
    imageUrl: false,
    imageSending: false
  }),

  [USER_REGISTERED]: (state, { email = '' }) => ({
    ...state,
    account: {
      ...state.account,
      email,
      status: 'registered'
    }
  }),

  [HIDE_LANDING]: (state) => ({
    ...state,
    showLanding: false
  }),




  [IMAGE_UPLOADED]: (state, { guid, image }) => {
    const currentAd = state.data.ads.find((ad) => guid === ad.guid);
    if (currentAd) {
      currentAd.image = image;
    }

    const currentBlock = state.data.blocks.find((block) => guid === block.guid);
    if (currentBlock) {
      currentBlock.image = image;
    }

    const currentStory = state.data.stories.find((story) => guid === story.guid);
    if (currentStory) {
      currentStory.image = image;
    }
    const currentStoryDrink = state.data.storiesDrinks.find((storyDrink) => guid === storyDrink.guid);
    if (currentStoryDrink) {
      currentStoryDrink.image = image;
    }
    const currentStoryTable = state.data.storiesTables.find((storyTable) => guid === storyTable.guid);
    if (currentStoryTable) {
      currentStoryTable.image = image;
    }  
    const currentStory2Items = state.data.stories2Items.find((story) => guid === story.guid);
    if (currentStory2Items) {
      currentStory2Items.image = image;
    }
    const currentStory3Items = state.data.stories3Items.find((story) => guid === story.guid);
    if (currentStory3Items) {
      currentStory3Items.image = image;
    }
    const currentStory4Items = state.data.stories4Items.find((story) => guid === story.guid);
    if (currentStory4Items) {
      currentStory4Items.image = image;
    }
    const currentStory5Items = state.data.stories5Items.find((story) => guid === story.guid);
    if (currentStory5Items) {
      currentStory5Items.image = image;
    }
    const currentStoryBottles = state.data.storiesBottles.find((story) => guid === story.guid);
    if (currentStoryBottles) {
      currentStoryBottles.image = image;
    }
    const currentStoryCigarettas = state.data.storiesCigarettas.find((story) => guid === story.guid);
    if (currentStoryCigarettas) {
      currentStoryCigarettas.image = image;
    }
    const currentStoryOffers = state.data.storiesOffers.find((story) => guid === story.guid);
    if (currentStoryOffers) {
      currentStoryOffers.image = image;
    }
    const currentStoryCakes = state.data.storiesCakes.find((story) => guid === story.guid);
    if (currentStoryCakes) {
      currentStoryCakes.image = image;
    }
    const currentStoryDishes = state.data.storiesDishes.find((story) => guid === story.guid);
    if (currentStoryDishes) {
      currentStoryDishes.image = image;
    }
    const currentStorySnacks = state.data.storiesSnacks.find((story) => guid === story.guid);
    if (currentStorySnacks) {
      currentStorySnacks.image = image;
    }
    const currentStorySoftDrinks = state.data.storiesSoftDrinks.find((story) => guid === story.guid);
    if (currentStorySoftDrinks) {
      currentStorySoftDrinks.image = image;
    }


    const currentItem = state.data.catalogItems.find((item) => guid === item.guid);
    if (currentItem) {
      currentItem.image = image;
    }
    const currentDrink = state.data.catalogDrinks.find((drink) => guid === drink.guid);
    if (currentDrink) {
      currentDrink.image = image;
    }
    const currentTable = state.data.catalogTables.find((itemTable) => guid === itemTable.guid);
    if (currentTable) {
      currentTable.image = image;
    }

    const currentCatalog2Items = state.data.catalog2Items.find((item) => guid === item.guid);
    if (currentCatalog2Items) {
      currentCatalog2Items.image = image;
    }

      const currentCatalog3Items = state.data.catalog3Items.find((item) => guid === item.guid);
    if (currentCatalog3Items) {
      currentCatalog3Items.image = image;
    }

      const currentCatalog4Items = state.data.catalog4Items.find((item) => guid === item.guid);
    if (currentCatalog4Items) {
      currentCatalog4Items.image = image;
    }
    
      const currentCatalog5Items = state.data.catalog5Items.find((item) => guid === item.guid);
    if (currentCatalog5Items) {
      currentCatalog5Items.image = image;
    }

      const currentCatalogBottles = state.data.catalogBottles.find((item) => guid === item.guid);
    if (currentCatalogBottles) {
      currentCatalogBottles.image = image;
    }

      const currentCatalogCakes = state.data.catalogCakes.find((item) => guid === item.guid);
    if (currentCatalogCakes) {
      currentCatalogCakes.image = image;
    }

      const currentCatalogCigarettas = state.data.catalogCigarettas.find((item) => guid === item.guid);
    if (currentCatalogCigarettas) {
      currentCatalogCigarettas.image = image;
    }

      const currentCatalogDishes = state.data.catalogDishes.find((item) => guid === item.guid);
    if (currentCatalogDishes) {
      currentCatalogDishes.image = image;
    }

      const currentCatalogOffers = state.data.catalogOffers.find((item) => guid === item.guid);
    if (currentCatalogOffers) {
      currentCatalogOffers.image = image;
    }

      const currentCatalogSnacks = state.data.catalogSnacks.find((item) => guid === item.guid);
    if (currentCatalogSnacks) {
      currentCatalogSnacks.image = image;
    }

      const currentCatalogSoftDrinks = state.data.catalogSoftDrinks.find((item) => guid === item.guid);
    if (currentCatalogSoftDrinks) {
      currentCatalogSoftDrinks.image = image;  
    }

    const currentCatalogTable = state.data.catalogTables.find((itemTable) => guid === itemTable.guid);
    if (currentCatalogTable) {
      currentCatalogTable.image = image;
    }


    const currentAvatar = state.data.avatars.find((avatar) => guid === avatar.guid);
    if (currentAvatar) {
      currentAvatar.image = image;
    }

    const currentGoogleMap = state.data.googleMaps.find((googleMap) => guid === googleMap.guid);
    if (currentGoogleMap) {
      currentGoogleMap.image = image;
    }

    return {
      ...state,
      data: {
        ...state.data
      },
      imageUrl: true
    };
  },

  [IMAGE_ALREADY_SAVED]: (state) => ({
    ...state,
    imageUrl: false,
    imageSending: false
  }),

  [START_SENDING_IMAGE]: (state) => ({
    ...state,
    imageSending: true
  })



}, initialState);


export default reducer;
