import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { uuid } from 'uuidv4';
import { useSelector, useDispatch } from 'react-redux';
import { getSearchParams } from 'utils/url';
import Button from 'components/common/Button';
import Icon from 'components/common/Icon';
import Slider from 'components/common/Slider';
import history from 'utils/history';
import {__} from 'utils/translation';
import { EDIT_CATALOG, REMOVE_CATALOG, ROTATE_CATALOG } from 'constants/actions';

import CatalogItemSettings from './CatalogItemSettings';
import CatalogItem from './CatalogItem';

import './index.scss';

const emptySettings = {
  animation: false,
  guid: null,
  image: '',
  text: '',
  textEn: '',
  description: '',
  textAlt: '',
  storyGuid: null,
  order: null,
  price: '0',
  currency: '',
  timeFrom: '',
  timeTo: '',
  outOfStock: false,
  number: '',
  additionally: '', 
  allergySoja: false,
  allergyPescado: false,
  allergyMostaza: false,
  allergyMoluscos: false,
  allergyGluten: false,
  allergyCascara: false,
  allergySulfitos: false,
  allergyCrustaseos: false,
  allergyAltramuces: false,
  allergyLacteos: false,
  allergyHuevos: false,
  allergySesamo: false,
  allergyApio: false,
  allergyCacahuetes: false,
  type: 'preview-text', 
};

const CatalogItems = ({ data }) => {
  const [settingsOpened, setSettingsOpened] = useState(null);
  const [catalogItemData, setСatalogItemData] = useState(emptySettings);
  const [currency, setCurrency] = useState(null);

  const closeCatalogItemsSettings = () => {
    setSettingsOpened(null);
  };

  const onOpenCatalogItemSettings = (catalogItemId) => {
    setSettingsOpened(catalogItemId);
  };

  const dispatch = useDispatch();
  const submitSettings = () => {
    if (catalogItemData.textEn || catalogItemData.text || catalogItemData.image)
      dispatch({ type: EDIT_CATALOG, payload: catalogItemData });
    if (currency !== catalogItemData.currency) {
      catalogItems.forEach(item => {
        dispatch({ type: EDIT_CATALOG, payload: {...item, currency: catalogItemData.currency} });
      });
    }
    closeCatalogItemsSettings(); 
  };

  const { active, paymentData } = useSelector((state) => state.config.account);

  const { catalogItems } = useSelector((state) => state.config.data);
  const { storyGuid, storyGuidSelected } = useSelector((state) => state.config);

  var params = getSearchParams(window.location.search);
  
  let paramsSortCategorias = "";
  let isBookNoFoto = false;
  let isBookFoto = false;

  if (params['categoriacoctails'] === "" || params['categoriacoctails']) {
    paramsSortCategorias = "categoria-coctails";
    isBookNoFoto = true;
  };
  if (params['categoriabeer'] === "" || params['categoriabeer']) {
    paramsSortCategorias = "categoria-beer";
    isBookFoto = true;
  };
  if (params['categoriawine'] === "" || params['categoriawine']) {
    paramsSortCategorias = "categoria-wine";
    isBookFoto = true;
  };
  if (params['categoriawhicky'] === "" || params['categoriawhicky']) {
    paramsSortCategorias = "categoria-whicky";
    isBookFoto = true;
  };
  if (params['categoriabrandy'] === "" || params['categoriabrandy']) {
    paramsSortCategorias = "categoria-brandy";
    isBookFoto = true;
  };
  if (params['categoriacoffee'] === "" || params['categoriacoffee']) {
    paramsSortCategorias = "categoria-coffee";
    isBookFoto = true;
  };
  if (params['categoriavodka'] === "" || params['categoriavodka']) {
    paramsSortCategorias = "categoria-vodka";
    isBookNoFoto = true;
  };
  if (params['categoriagin'] === "" || params['categoriagin']) {
    paramsSortCategorias = "categoria-gin";
    isBookFoto = true;
  };
  if (params['categoriarum'] === "" || params['categoriarum']) {
    paramsSortCategorias = "categoria-rum";
    isBookFoto = true;
  };
  if (params['categoriaother'] === "" || params['categoriaother']) {
    paramsSortCategorias = "categoria-other";
    isBookNoFoto = true;
  };
  if (params['categoriasoftdrinks'] === "" || params['categoriasoftdrinks']) {
    paramsSortCategorias = "categoria-soft-drinks";
    isBookFoto = true;
  };
  if (params['categoriahookah'] === "" || params['categoriahookah']) {
    paramsSortCategorias = "categoria-hookah";
    isBookNoFoto = true;
  };
  if (params['categoriatequila'] === "" || params['categoriatequila']) {
    paramsSortCategorias = "categoria-tequila";
    isBookFoto = true;
  };
  if (params['categoriaaperitifs'] === "" || params['categoriaperitifs']) {
    paramsSortCategorias = "categoria-aperitifs";
    isBookFoto = true;
  };
  if (params['categoriadegistives'] === "" || params['categoriadegistives']) {
    paramsSortCategorias = "categoria-degistives";
    isBookFoto = true;
  };
  if (params['categoriasnacks'] === "" || params['categoriasnacks']) {
    paramsSortCategorias = "categoria-snacks";
    isBookFoto = true;
  };

  if (params['categorialiquore'] === "" || params['categorialiquore']) {
    paramsSortCategorias = "categoria-liquore";
    isBookFoto = true;
  };


  useEffect(() => {
    const currentCatalogItem = catalogItems.find((catalogItem) => catalogItem.guid === settingsOpened);
    const settings = currentCatalogItem || { ...emptySettings };
    if (!settings.guid) {
      settings.guid = uuid();
      settings.currency = catalogItems.filter(e => e.currency)[0]?.currency ?? "";
    }
    setCurrency(settings.currency);
    setСatalogItemData({ ...settings });
  }, [settingsOpened, catalogItems]);

  const removeCatalog = () => {
    dispatch({ type: REMOVE_CATALOG, guid: catalogItemData.guid });
    closeCatalogItemsSettings();
  };

  const idOrder = storyGuidSelected?.slice(-6);


  const onRotate = (guid, order) => {
    dispatch({ type: ROTATE_CATALOG, guid, order, idOrder });
  };

  

  return (
    <React.Fragment>
      <div className="catalogItems">
          {storyGuidSelected && 
            <Button
              key="add-button"
              onClick={() => onOpenCatalogItemSettings('')}
              className={classnames(['catalogItem-button', 'pulse'])}
            >
              <Icon type="plus" />
              <span>{__("Ваши продукты")}</span>
            </Button> 
          }
        </div>
        
        {
          data.sort((a, b) => a.order - b.order)
            .filter(item => (!item.storyGuid || item.storyGuid === storyGuid))
            // .filter(item => (item.number === paramsSortCategorias))
            .map((catalogItem) =>
            <CatalogItem onClick={() => onOpenCatalogItemSettings(catalogItem.guid)}
            key={catalogItem.guid}  {...catalogItem} />)
        }
        
        
      <Slider
        opened={settingsOpened !== null}
        onClose={closeCatalogItemsSettings}
        onRemoveDown={removeCatalog}
        title= {__("Добавление ваших продуктов, товаров или услуг")}
        onSubmit={submitSettings}
      >
        <CatalogItemSettings {...catalogItemData}  onRotate={onRotate}  onChange={(settings) => setСatalogItemData({ ...catalogItemData, ...settings })} />
      </Slider>

    </React.Fragment >
  );
};

CatalogItems.propTypes = {
  data: PropTypes.arrayOf(PropTypes.shape({}))
};

CatalogItems.defaultProps = {
  data: []
};

export default CatalogItems;
