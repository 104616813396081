export const getSearchString = (search, variable) => {
  const query = search.substring(search.indexOf("?") + 1);
  const vars = query.split('&');
  for (let i = 0; i < vars.length; i++) {
    const pair = vars[i].split('=');
    if (decodeURIComponent(pair[0]) === variable)
      return decodeURIComponent(pair[1]);
  }
  return undefined;
};

export const validUrl = (str) => {
  var pattern = new RegExp('^(https?:\\/\\/)?'+ // protocol
    '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|'+ // domain name
    '((\\d{1,3}\\.){3}\\d{1,3}))'+ // OR ip (v4) address
    '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*'+ // port and path
    '(\\?[;&a-z\\d%_.~+=-]*)?'+ // query string
    '(\\#[-a-z\\d_]*)?$','i'); // fragment locator
  return !!pattern.test(str);
};

export const getSearchParams = (search, ignored) => {
  const query = search.substring(1);
  const vars = query.split('&');
  let result = {};
  for (let i = 0; i < vars.length; i++) {
    const pair = vars[i].split('=');

    if (!ignored || ignored.filter(i => i.toLowerCase() === pair[0].toLowerCase()).length === 0) {
      if (pair.length > 1) {
        result[decodeURI(pair[0])] = decodeURIComponent(pair[1]);
      }
      else {
        result[decodeURI(pair[0])] = "";
      }
    }
  }
  return result;
};
