import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getSearchString } from 'utils/url';
import API from 'utils/api';
import {__} from 'utils/translation';
import { CONFIG_LOAD } from 'constants/actions';
import Reaptcha from 'reaptcha';

import classnames from 'classnames';
import Button from 'components/common/Button';
import App from 'components/App';
import BackToLanding from 'components/BackToLanding';
import Loading from 'components/common/Loading';

import './index.scss';

let invite = '';
let key = '';
const keyNow = Date.now();

export const setInvite = (newInvite) => {
    invite = newInvite;
    };

export const setKey = (newKey) => {
    key = newKey;
    };   
    

export const getInvite = () => {
    const searchInvite = getSearchString(window.location.search, 'invitationId');
    return invite || searchInvite;
    };

export const getKey = () => {
    const searchKey = getSearchString(window.location.search, 'secretKey');
    return key || searchKey;
    };         

  
  const Captcha = ({}) => {
    const dispatch = useDispatch();

    const [verified, setVerified] = useState(false);
    const [isClicked, setIsClicked] = useState(0);
    const onVerify = () => {
        setVerified(true);
      };

    const id = getInvite();
    const idKey = getKey();

    

    useEffect(() => {        
      }, [isClicked]);

    const wasVerified = () => {
        API.updateToken("123")
      .then(() => {
        dispatch({ type: CONFIG_LOAD }); 
      });
      setIsClicked(1) 
    };

    const { data = {} } = useSelector((state) => state.config);
    
    if ((keyNow - idKey) > 20000) {
        console.log("(keyNow - idKey) Captcha", (keyNow - idKey) )    
        return <BackToLanding />
    }

    if (!id) {  
        return (
            <div className="app">
                <div className="app-container" style={{backgroundColor:"#9d8971"}}>
                    <div className="captcha">
                        <p>{__("text00022")}</p>
                        <Reaptcha sitekey="6Lfw4UceAAAAAOi_h-30m9MsYYzyHWFgUuSl76_t" onVerify={onVerify} />
                        <div className="captcha-empty"></div>
                        {verified && 
                            <Button type="submit"
                                className={classnames(['catalogItem-settings__settings__animation__store'])}
                                onClick={wasVerified}
                                isInline
                                noStyled
                            >
                                {__("text00023")}
                            </Button>                   
                    }
                    </div>
                </div>
            </div>
        );
    };
    
    if (id && !data) 
        return <Loading />;
    
    if (id && data) 
        return <App />; 
};

export default Captcha;
  