import { takeEvery, put, call, all } from 'redux-saga/effects';

import {
  CONFIG_LOAD,
  CONFIG_LOAD_RECOVERY,
  LOADING_ERROR,
  SET_DATA,
  SAVE_DATA,
  DATA_ALREADY_SAVED,
  REGISTER_USER,
  USER_REGISTERED,
  EDIT_BLOCK,

  EDIT_STORY,
  EDIT_STORY_DRINK,
  EDIT_STORY_TABLE,
  EDIT_STORY_2_ITEMS,
  EDIT_STORY_3_ITEMS,
  EDIT_STORY_4_ITEMS,
  EDIT_STORY_5_ITEMS,
  EDIT_STORY_BOTTLES,
  EDIT_STORY_CAKES,
  EDIT_STORY_CIGARETTAS,
  EDIT_STORY_DISHES,
  EDIT_STORY_OFFERS,
  EDIT_STORY_SNACKS,
  EDIT_STORY_SOFTDRINKS,

  EDIT_CATALOG,
  EDIT_CATALOG_DRINK,
  EDIT_CATALOG_2_ITEMS,
  EDIT_CATALOG_3_ITEMS,
  EDIT_CATALOG_4_ITEMS,
  EDIT_CATALOG_5_ITEMS,
  EDIT_CATALOG_BOTTLES,
  EDIT_CATALOG_CAKES,
  EDIT_CATALOG_CIGARETTAS,
  EDIT_CATALOG_DISHES,
  EDIT_CATALOG_OFFERS,
  EDIT_CATALOG_SNACKS,
  EDIT_CATALOG_SOFTDRINKS,
  EDIT_CATALOG_TABLE,

  EDIT_AD,
  IMAGE_UPLOADED,
  START_SENDING_IMAGE,
  EDIT_AVATAR,
  EDIT_GOOGLE_MAPS
} from 'constants/actions';

import API from 'utils/api';
import fileReader from 'utils/fileReader';
// import mockData from 'mocks/data.json';

function* loadConfig() {
  try {
    const mockData = yield call(API.getData, {});
    const {
      themes,
      buttonColors,
      backgrounds,
      config, 
      account,
      ...data
    } = mockData;

    
    yield put({
      type: SET_DATA,
      themes,
      buttonColors,
      backgrounds,
      config,
      account,
      data
    });
  }
  catch (error) {
    yield put({ type: LOADING_ERROR, error });
  }
}

function* loadConfigRecovery() {
  try {
    const mockData = yield call(API.getDataRecovery, {});
    const {
      themes,
      buttonColors,
      backgrounds,
      config, 
      account,
      settingsName,
      ...data
    } = mockData;

    
    yield put({
      type: SET_DATA,
      themes,
      buttonColors,
      backgrounds,
      config,
      account,
      settingsName,     
      data
    });
  }
  catch (error) {
    yield put({ type: LOADING_ERROR, error });
  }
}

function* storeImage({ payload }) {
  try {
    if (payload?.image?.startsWith('data:image')) {
      yield put({
        type: START_SENDING_IMAGE
      });
    };
    if (payload?.image?.startsWith('data:image')) {
      const response = yield call(API.uploadImage, { image: payload.image });

      if (response?.imageUrl) {
        yield put({
          type: IMAGE_UPLOADED,
          guid: payload.guid,
          image: response.imageUrl  
        });
      };
    }
  }
  catch (error) {
    console.log(error);
  }
}

function* storeSweetyImage({ payload }) {
  try {
    if (payload?.image?.startsWith('https://sweety')) {
      yield put({
        type: START_SENDING_IMAGE
      });
    };
    if (payload?.image?.startsWith('https://sweety')) {
      const idNameSweetyImage = payload.image.split("/").slice(-1).toString();
      const urlImageFromMaps = `https://api.maps.menu/sweety-images/${idNameSweetyImage}`
      
      const blob = yield call(API.toDataUrl, urlImageFromMaps);
      if (!blob) {
        return null;
      };

      const blobImage = yield call(fileReader, blob)
      const response = yield call(API.uploadImage, { image: blobImage });
   
      if (response?.imageUrl) {
        yield put({
          type: IMAGE_UPLOADED,
          guid: payload.guid,
          image: response.imageUrl  
        });
      };
    }
  }
  catch (error) {
    console.log(error);
  }
}

function* saveData({ data }) {
  try {
    yield call(API.saveData, data);
    yield put({
      type: DATA_ALREADY_SAVED

    });
  }
  catch (error) {
    yield put({ type: LOADING_ERROR, error });
  }
}

function* registerUser({ email, password }) {
  try {
    yield call(API.register, { email, password });
    yield put({
      type: USER_REGISTERED,
      email
    });
  }
  catch {
    yield put({
      type: USER_REGISTERED
    });
  }
}



export default function* configure() {
  yield takeEvery(CONFIG_LOAD, loadConfig);
  yield takeEvery(CONFIG_LOAD_RECOVERY, loadConfigRecovery);

  yield takeEvery(SAVE_DATA, saveData);
  yield takeEvery(REGISTER_USER, registerUser);

  yield takeEvery(EDIT_BLOCK, storeImage);

  yield takeEvery(EDIT_STORY, storeImage);
  yield takeEvery(EDIT_STORY_DRINK, storeImage);
  yield takeEvery(EDIT_STORY_TABLE, storeImage);
  yield takeEvery(EDIT_STORY_2_ITEMS, storeImage);
  yield takeEvery(EDIT_STORY_3_ITEMS, storeImage);
  yield takeEvery(EDIT_STORY_4_ITEMS, storeImage);
  yield takeEvery(EDIT_STORY_5_ITEMS, storeImage);
  yield takeEvery(EDIT_STORY_BOTTLES, storeImage);
  yield takeEvery(EDIT_STORY_CAKES, storeImage);
  yield takeEvery(EDIT_STORY_CIGARETTAS, storeImage);
  yield takeEvery(EDIT_STORY_DISHES, storeImage);
  yield takeEvery(EDIT_STORY_OFFERS, storeImage);
  yield takeEvery(EDIT_STORY_SNACKS, storeImage);
  yield takeEvery(EDIT_STORY_SOFTDRINKS, storeImage);

  yield takeEvery(EDIT_CATALOG, storeImage);
  yield takeEvery(EDIT_CATALOG_DRINK, storeImage);
  yield takeEvery(EDIT_CATALOG_2_ITEMS, storeImage);
  yield takeEvery(EDIT_CATALOG_3_ITEMS, storeImage);
  yield takeEvery(EDIT_CATALOG_4_ITEMS, storeImage);
  yield takeEvery(EDIT_CATALOG_5_ITEMS, storeImage);
  yield takeEvery(EDIT_CATALOG_BOTTLES, storeImage);
  yield takeEvery(EDIT_CATALOG_CAKES, storeImage);
  yield takeEvery(EDIT_CATALOG_CIGARETTAS, storeImage);
  yield takeEvery(EDIT_CATALOG_DISHES, storeImage);
  yield takeEvery(EDIT_CATALOG_OFFERS, storeImage);
  yield takeEvery(EDIT_CATALOG_SNACKS, storeImage);
  yield takeEvery(EDIT_CATALOG_SOFTDRINKS, storeImage);
  yield takeEvery(EDIT_CATALOG_TABLE, storeImage);

  yield takeEvery(EDIT_AVATAR, storeImage);
  yield takeEvery(EDIT_GOOGLE_MAPS, storeImage);
  yield takeEvery(EDIT_AD, storeImage);

  yield takeEvery(EDIT_BLOCK, storeSweetyImage);

  yield takeEvery(EDIT_STORY, storeSweetyImage);
  yield takeEvery(EDIT_STORY_DRINK, storeSweetyImage);
  yield takeEvery(EDIT_STORY_TABLE, storeSweetyImage);
  yield takeEvery(EDIT_STORY_2_ITEMS, storeSweetyImage);
  yield takeEvery(EDIT_STORY_3_ITEMS, storeSweetyImage);
  yield takeEvery(EDIT_STORY_4_ITEMS, storeSweetyImage);
  yield takeEvery(EDIT_STORY_5_ITEMS, storeSweetyImage);
  yield takeEvery(EDIT_STORY_BOTTLES, storeSweetyImage);
  yield takeEvery(EDIT_STORY_CAKES, storeSweetyImage);
  yield takeEvery(EDIT_STORY_CIGARETTAS, storeSweetyImage);
  yield takeEvery(EDIT_STORY_DISHES, storeSweetyImage);
  yield takeEvery(EDIT_STORY_OFFERS, storeSweetyImage);
  yield takeEvery(EDIT_STORY_SNACKS, storeSweetyImage);
  yield takeEvery(EDIT_STORY_SOFTDRINKS, storeSweetyImage);

  yield takeEvery(EDIT_CATALOG, storeSweetyImage);
  yield takeEvery(EDIT_CATALOG_DRINK, storeSweetyImage);
  yield takeEvery(EDIT_CATALOG_2_ITEMS, storeSweetyImage);
  yield takeEvery(EDIT_CATALOG_3_ITEMS, storeSweetyImage);
  yield takeEvery(EDIT_CATALOG_4_ITEMS, storeSweetyImage);
  yield takeEvery(EDIT_CATALOG_5_ITEMS, storeSweetyImage);
  yield takeEvery(EDIT_CATALOG_BOTTLES, storeSweetyImage);
  yield takeEvery(EDIT_CATALOG_CAKES, storeSweetyImage);
  yield takeEvery(EDIT_CATALOG_CIGARETTAS, storeSweetyImage);
  yield takeEvery(EDIT_CATALOG_DISHES, storeSweetyImage);
  yield takeEvery(EDIT_CATALOG_OFFERS, storeSweetyImage);
  yield takeEvery(EDIT_CATALOG_SNACKS, storeSweetyImage);
  yield takeEvery(EDIT_CATALOG_SOFTDRINKS, storeSweetyImage);
  yield takeEvery(EDIT_CATALOG_TABLE, storeSweetyImage);

  yield takeEvery(EDIT_AVATAR, storeSweetyImage);
  yield takeEvery(EDIT_GOOGLE_MAPS, storeSweetyImage);
  yield takeEvery(EDIT_AD, storeSweetyImage);
}
