import React, { createElement, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { useSelector } from 'react-redux';
import QRCode from "qrcode.react";
import Icon from 'components/common/Icon';
import Button from 'components/common/Button';
import Input from 'components/common/Input';
import Textarea from 'components/common/Textarea';
import Popup from 'components/common/Popup';
import CropImage from 'components/common/CropImage';
import Picker from 'components/common/Picker';
import { __, translatedProperty, translatedPropertyName, supportedLanguages, getDefaultLanguage, translate } from 'utils/translation';
import API from 'utils/api';

import {
    CATALOG_LEFT,
    CATALOG_CENTER,
    CATALOG_RIGHT,
    CATALOG_HEADER
} from 'constants/catalogTypes';

import CatalogItem from '../CatalogItem';

import './index.scss';

const CatalogItemSettings = (props) => {
    const [selectedImage, setSelectedImage] = useState(undefined);
    const [translateText, setTranslateText] = useState(false);
    const [translateTextAlt, setTranslateTextAlt] = useState(false);
    const [translateDescription, setTranslateDescription] = useState(false);

    const [textLang, setTextLang] = useState(getDefaultLanguage());
    const [textTranslating, setTextTranslating] = useState(false);

    const [textAltLang, setTextAltLang] = useState(getDefaultLanguage());
    const [textAltTranslating, setTextAltTranslating] = useState(false);

    const [descriptionLang, setDescriptionLang] = useState(getDefaultLanguage());
    const [descriptionTranslating, setDescriptionTranslating] = useState(false);

    const [fullSettingsOpend, setFullSettingsOpend] = useState(true);
    const [additionalSettingsOpend, setAdditionalSettingsOpend] = useState(false);

    const [sent, setSent] = useState(false);
    const [timer, setTimer] = useState(null);
    const [quantityCopy, setQuantityCopy] = useState("");

    const { title, settingsName } = useSelector((state) => state.config.data);
    
    const url = `https://maps.menu/${settingsName}`;

    useEffect(() => {
        timer && timer > 0 && setTimeout(() => {
            if (!timer || timer <= 1) {
                setTimer(null);
                setSent(false);
                return;
            }
            setTimer(timer - 1);
        }, 1000);
    }, [timer]);

    const handleSubmit = () => {
        API.sendOrder({
            type: "QR",
            linkQr: `${linkForTable}`,
            title: title,
            table: "СТОЛ N",
            tableNumber: number,
            description0: "Отсканируйте этот QR чтобы:",
            description1: "Посмотреть меню;",
            description2: "Вызвать официанта;",
            description3: "Отправить заказ",
            user: settingsName,
            copy: quantityCopy
        }).then(() => {
            setTimer(30);
            setSent(true);
        });
    };



    const updateSettings = (catalogItemType, value) => {
        const {
            onChange,
            order,
            onRotate,
            ...settings
        } = props;
        if (catalogItemType === "text" && !translateText) {
            var updating = {
                ...settings,
                text: value
            };
            supportedLanguages.forEach(lang => {
                updating[translatedPropertyName("text", lang)] = value;
            });
            onChange(updating);
        } else if (catalogItemType === "text" && translateText) {
            onChange({
                ...settings,
                text: value,
                [translatedPropertyName("text", textLang)]: value
            })
        } else if (catalogItemType.startsWith("text") && !catalogItemType.startsWith("text")) {
            onChange({
                ...settings,
                [catalogItemType]: value
            })
        }

        else if (catalogItemType === "textAlt" && !translateTextAlt) {
            var updating = {
                ...settings,
                textAlt: value
            };
            supportedLanguages.forEach(lang => {
                updating[translatedPropertyName("textAlt", lang)] = value;
            });
            onChange(updating);
        } else if (catalogItemType === "textAlt" && translateTextAlt) {
            onChange({
                ...settings,
                textAlt: value,
                [translatedPropertyName("textAlt", textAltLang)]: value
            })
        } else if (catalogItemType.startsWith("textAlt")) {
            onChange({
                ...settings,
                [catalogItemType]: value
            })
        }

        else if (catalogItemType === "description" && !translateDescription) {
            var updating = {
                ...settings,
                description: value
            };
            supportedLanguages.forEach(lang => {
                updating[translatedPropertyName("description", lang)] = value;
            });
            onChange(updating);
        } else if (catalogItemType === "description" && translateDescription) {
            onChange({
                ...settings,
                description: value,
                [translatedPropertyName("description", descriptionLang)]: value
            })
        } else if (catalogItemType.startsWith("description")) {
            onChange({
                ...settings,
                [catalogItemType]: value
            })
        }

        else {
            onChange({
                ...settings,
                [catalogItemType]: value
            })
        };
    };

    const {
        animation,
        guid,
        image,
        storyGuid,
        order,
        price,
        currency,
        timeFrom,
        timeTo,
        unavailableForBooking,
        unavailableJoin,
        unavailableSelfService,
        unavailableCallingWaiter,
        withChildren,
        withAnimals,
        forDisabled,
        onlyForAdiltos,
        forVip,
        mainSeats,
        aditionalSeats,
        number,
        type,
        onChange,
        onRotate
    } = props;

    let linkForTable = "";
    if (!unavailableForBooking && !unavailableSelfService && !unavailableCallingWaiter) {
        linkForTable = `${url}?${__("стол")}=${number}&waiterselfservice`
    } else if (!unavailableForBooking && !unavailableSelfService && unavailableCallingWaiter) {
        linkForTable = `${url}?${__("стол")}=${number}&selfservice`
    } else if (!unavailableForBooking && unavailableSelfService && !unavailableCallingWaiter) {
        linkForTable = `${url}?${__("стол")}=${number}&waiter`
    } else { linkForTable = `${url}` }

    let text = translatedProperty(props, "text", textLang, true);
    if (!text && props.text && !translateText) {
        text = props.text
    }
    let textAlt = translatedProperty(props, "textAlt", textAltLang, true);
    if (!textAlt && props.textAlt && !translateTextAlt) {
        textAlt = props.textAlt
    }
    let description = translatedProperty(props, "description", descriptionLang, true);
    if (!description && props.description && !translateDescription) {
        description = props.description
    }

    const [imageElements, setImageElements] = useState([]);
    const [lastImageText, setLastImageText] = useState(null);
    const [lastImageGuid, setLastImageGuid] = useState(null);
    const [imagesLoading, setImagesLoading] = useState(false);

    useEffect(() => {
        if (lastImageGuid !== guid) {
            setLastImageText(null);
            setImageElements([]);
        }
    }, [guid]);

    const { storiesTables = {} } = useSelector((state) => state.config.data);
    const storyElements = storiesTables.map((story) => ({
        id: story.titleEn,
        component: (
            <div
                onClick={() => updateSettings('storyGuid', story.guid)}
                className="theme-picker"
                style={{ backgroundImage: `URL(${story.image})`, backgroundRepeat: 'no-repeat', backgroundPosition: 'center center', backgroundSize: 'cover' }}
            />
        )
    }));

    const showTitle = storiesTables.length > 0;

    const uploadImage = (value) => {
        const imageUrl = value[0];
        setSelectedImage(imageUrl);
    };

    const onCropSave = (value) => {
        updateSettings('image', value);
        setSelectedImage(undefined);
    };

    const onOrderUp = !onRotate ? null : () => {
        onRotate(guid, 1);
    };

    const onOrderDown = !onRotate ? null : () => {
        onRotate(guid, -1);
    };

    const clickTextTranslate = async () => {
        if (!text) {
            return
        }
        setTextTranslating(true);
        const translated = await translate(text, textLang, "text");

        const {
            onChange,
            order,
            onRotate,
            ...settings
        } = props;
        onChange({
            ...settings,
            ...translated
        });
        setTextTranslating(false);
    }

    const clickTextAltTranslate = async () => {
        if (!textAlt) {
            return
        }
        setTextAltTranslating(true);
        const translated = await translate(textAlt, textAltLang, "textAlt");
        onChange({
            ...props,
            ...translated
        });
        setTextAltTranslating(false);
    }

    const clickDescriptionTranslate = async () => {
        if (!description) {
            return
        }
        setDescriptionTranslating(true);
        const translated = await translate(description, descriptionLang, "description");
        onChange({
            ...props,
            ...translated
        });
        setDescriptionTranslating(false);
    }

    return (
        <React.Fragment>
            
                <div>
                    <div className="catalogItem-settings">
                        <div className="catalogItem-settings__preview">
                            {number && <div className="preview-area-box-for-catalogItems">
                                <CatalogItem
                                    className="catalogItem-settings__preview__catalogItem"
                                    animation={animation}
                                    image={image}
                                    text={text}
                                    textAlt={textAlt}
                                    price={price}
                                    currency={currency}
                                    number={number}
                                    unavailableForBooking={unavailableForBooking}
                                    timeFrom={timeFrom}
                                    timeTo={timeTo}
                                    type={type}
                                    mainSeats={mainSeats}
                                    technical
                                />
                            </div>}
                        </div>
                        {number && <div className="catalogItem-settings__settings__field-header">
                            {__("Область предварительного просмотра")}
                        </div>}
                        {/* ОБЛАСТЬ ОСНОВНЫХ НАСТРОЕК */}
                        {!fullSettingsOpend ?
                            <>
                                <div className="area-box-for-catalogItems" onClick={() => setFullSettingsOpend(true)}>
                                    <div className="catalogItem-settings-field" >
                                        <Icon className="catalogItem-open-settings-field__icon" type="plusCircle" />
                                        <div className="catalogItem-open-settings-field__span">{__("Основные настройки")}</div>
                                    </div>
                                </div>
                            </>
                            :
                            <>
                                <div className="area-box-for-catalogItems">
                                    <div className="area-box-for-catalogItems__header" onClick={() => setFullSettingsOpend(false)}>{__(" - Основные настройки")}</div>
                                    <div className="catalogItem-settings__settings">

                                        <Input className="catalogItem-settings__settings__input"
                                            value={number}
                                            name="fieldNumberCaltalogTable"
                                            type="text"
                                            placeholder={__("Номер стола")}
                                            onChange={(value) => updateSettings('number', value)}
                                        />
                                        <div className="catalogItem-settings__settings__field-header">{__("Номер стола")}</div>
                                        <br />

                                        <Input
                                            className={classnames([
                                                'block-settings__settings__image pulse1 icon1',
                                                { used: Boolean(image) }
                                            ])}
                                            name="fieldImageCaltalog"
                                            type="file"
                                            icon="image"
                                            onChange={(value) => uploadImage(value)}
                                            onClick={image ? () => updateSettings('image', undefined) : undefined}
                                        />
                                        {!image
                                            ? (
                                                <div className="catalogItem-settings__settings__field-header"
                                                >
                                                    {__("Нажмите на кнопку, чтобы загрузить изображение")}
                                                </div>
                                            ) : <div className="catalogItem-settings__settings__field-header"
                                            >
                                                <div className="block-image-box" >
                                                    <div className="block-image-box__image" style={{ backgroundImage: `URL(${image})` }} />
                                                    {__("Нажмите на кнопку, чтобы удалить загруженное изображение")}
                                                </div>
                                            </div>}
                                        <br />
                                        <br />

                                        <div className="settings-video-link-box">
                                            <a href="https://youtu.be/AVbcZOxSuPE" target="_blank">
                                                <div className="settings-video-link-box__flexbox">
                                                    <Icon className="settings-video-link-box__flexbox__icon" type="youtube" />
                                                    <div className="settings-video-link-box__flexbox__text">{__("Нажмите, чтобы посмотреть инструкцию в YouTube")}</div>
                                                </div>
                                            </a>
                                        </div>


                                    </div>

                                    <br />
                                </div>

                                <div className="catalogItem-settings__settings__field-header">
                                    {__("Область основных настроек")}
                                </div>
                            </>
                        }
                        <br />

                        {/* ОБЛАСТЬ ДОПОЛНИТЕЛЬНЫХ НАСТРОЕК */}
                        {!additionalSettingsOpend ?
                            <>
                                <div className="area-box-for-catalogItems" onClick={() => setAdditionalSettingsOpend(true)}>
                                    <div className="catalogItem-settings-field" >
                                        <Icon className="catalogItem-open-settings-field__icon" type="plusCircle" />
                                        <div className="catalogItem-open-settings-field__span">{__("Дополнительные настройки")}</div>
                                    </div>
                                </div>
                            </>
                            : <>
                                <div className="area-box-for-catalogItems">
                                    <div className="area-box-for-catalogItems__header" onClick={() => setAdditionalSettingsOpend(false)}>{__(" - Дополнительные настройки")}</div>
                                    <div className="catalogItem-settings__settings">

                                        {!translateText && <>
                                            <div className="catalogItem-settings__settings__field-button"
                                                onClick={() => setTranslateText(true)}
                                            >
                                                {__("Добавить перевод")}
                                            </div>
                                            <Textarea className="catalogItem-settings__settings__input"
                                                value={text}
                                                name="fieldTextCaltalog"
                                                type="text"
                                                placeholder={__("Название столика")}
                                                onChange={(value) => updateSettings('text', value)}
                                            />
                                            <div className="catalogItem-settings__settings__field-header">{__("Название столика")}</div>
                                            <br />
                                        </>
                                        }

                                        {/* ОБЛАСТЬ ДОПОЛНИТЕЛЬНЫХ НАСТРОЕК ПОКАЗЫВАТЬ ЕСЛИ ЕСТЬ БРОНИРОВНИЕ */}
                                    {!unavailableForBooking && <>

                                        {/* управление статусом столов */}
                                        {(number || image) && !unavailableForBooking && (
                                            <div className="catalogItem-settings__types">
                                                <Button
                                                    className={classnames([
                                                        'catalogItem-settings__settings__animation__store',
                                                        { 'catalogItem-settings__settings__animation__store__checked': unavailableCallingWaiter }
                                                    ])}
                                                    onClick={() => updateSettings('unavailableCallingWaiter', !unavailableCallingWaiter)}
                                                    isInline
                                                    noStyled
                                                >
                                                    {unavailableCallingWaiter ? __("Невозможно вызвать официанта") : __("Возможно вызвать официанта")}
                                                </Button>

                                            </div>
                                        )}

                                        {(number || image) && !unavailableForBooking && (
                                            <div className="catalogItem-settings__types">
                                                <Button
                                                    className={classnames([
                                                        'catalogItem-settings__settings__animation__store',
                                                        { 'catalogItem-settings__settings__animation__store__checked': unavailableSelfService }
                                                    ])}
                                                    onClick={() => updateSettings('unavailableSelfService', !unavailableSelfService)}
                                                    isInline
                                                    noStyled
                                                >
                                                    {unavailableSelfService ? __("Недоступен для самообслуживания") : __("Доступен для самообслуживания")}
                                                </Button>

                                            </div>
                                        )}
                                            {/* <Input
                                                className="catalogItem-settings__settings__input"
                                                value={timeFrom}
                                                name="fieldTimeFromCaltalog"
                                                type="number"
                                                placeholder={__("Время (час) начало диапазона")}
                                                onChange={(value) => updateSettings('timeFrom', value)}
                                            />
                                            <Input
                                                className="catalogItem-settings__settings__input"
                                                value={timeTo}
                                                name="fieldTimeToCaltalog"
                                                type="number"
                                                placeholder={__("Время (час) окончания диапазона")}
                                                onChange={(value) => updateSettings('timeTo', value)}
                                            />
                                            <div className="catalogItem-settings__settings__field-header">{__("Бронирование этого столика возможно только в заданные часы, например в диапазоне с 18 до 23")}</div>
                                            <br /> */}
                                        </>
                                        }
                                            <div className="qrComponents-flexBox">
                                            <div className="catalog-table-settings-screenshot__screenshot-area">
                                                <QRCode
                                                    id="paramUrl"
                                                    value={linkForTable}
                                                    size={290}
                                                    bgColor={"#ffffff"}
                                                    fgColor={"#000000"}
                                                    level={"L"}
                                                    includeMargin={false}
                                                   
                                                />

                                                <p className="linkQr" onClick={() => window.open(linkForTable, "_blank")}>{linkForTable}</p>
                                            </div>
                                        </div>
                                        <div className="catalog-table-settings-screenshot__screenshot-area__description">
                                            <Icon type="camera" className="catalog-table-settings-screenshot__screenshot-area__description__icon" />&nbsp;
                                            <b>{__("Область для снимка экрана")}</b>
                                        </div>

                                        {/* КНОПКА ОТПРАВИТЬ QR В ПЕЧАТЬ */}
                                        {!sent &&
                                            <div className="call-waiter-buton-with-input-box" >
                                                <input className="call-waiter-buton-with-input"
                                                    type='text'
                                                    id='parametrs'
                                                    placeholder="0"
                                                    maxLength="2"
                                                    value={quantityCopy}
                                                    onChange={(e) => setQuantityCopy(e.target.value)} />
                                                {quantityCopy > 0 ?
                                                    <Button
                                                        className='call-waiter-settings__settings__animation__store'

                                                        onClick={handleSubmit}
                                                        isInline
                                                        noStyled
                                                    >
                                                        {__("РАСПЕЧАТАТЬ ЭТОТ QR CODE")}
                                                    </Button> :
                                                    <Button
                                                        className='call-waiter-settings__settings__animation__nostore'
                                                        onClick={() => { }}
                                                        isInline
                                                        noStyled
                                                    >
                                                        {__("РАСПЕЧАТАТЬ ЭТОТ QR CODE")}
                                                    </Button>
                                                }

                                            </div>

                                        }
                                        {sent && <Button
                                            className='call-waiter__checked'
                                            onClick={() => { }}
                                            isInline
                                            noStyled
                                        >
                                            {__("QR CODE ОТПРАВЛЕН В ПЕЧАТЬ")}{timer % 3 === 0 ? "..." : timer % 3 === 1 ? ".." : "."}
                                        </Button>
                                        }

                                    </div>
                                    <br />
                                    <div className="settings-video-link-box">
                                        <a href="" target="_blank">
                                            <div className="settings-video-link-box__flexbox" style={{ marginLeft: 12 }}>
                                                <Icon className="settings-video-link-box__flexbox__icon" type="youtube" />
                                                <div className="settings-video-link-box__flexbox__text">{__("Нажмите, чтобы посмотреть инструкцию в YouTube")}</div>
                                            </div>
                                        </a>
                                    </div>
                                    <br />
                                </div>
                                <div className="catalogItem-settings__settings__field-header">
                                    {__("Область дополнительных настроек")}
                                </div>
                            </>
                        }
                        <br />

                    </div>
                </div>
            {/* )} */}

            {
                selectedImage && (
                    <Popup visible={Boolean(selectedImage)}>
                        <CropImage
                            file={selectedImage}
                            onSave={onCropSave}
                        />
                    </Popup>
                )
            }
        </React.Fragment >
    );
};

CatalogItemSettings.propTypes = {
    animation: PropTypes.bool,
    image: PropTypes.string,
    text: PropTypes.string,
    description: PropTypes.string,
    textAlt: PropTypes.string,
    storyGuid: PropTypes.string,
    order: PropTypes.number,
    price: PropTypes.string,
    currency: PropTypes.string,
    timeFrom: PropTypes.string,
    timeTo: PropTypes.string,
    unavailableForBooking: PropTypes.bool,
    unavailableJoin: PropTypes.bool,
    unavailableSelfService: PropTypes.bool,
    unavailableCallingWaiter: PropTypes.bool,
    withChildren: PropTypes.bool,
    withAnimals: PropTypes.bool,
    forDisabled: PropTypes.bool,
    onlyForAdiltos: PropTypes.bool,
    forVip: PropTypes.bool,
    number: PropTypes.number,
    type: PropTypes.string,
    onChange: PropTypes.func,

};

CatalogItemSettings.defaultProps = {
    animation: false,
    image: '',
    text: '',
    description: '',
    textAlt: '',
    storyGuid: '',
    order: 0,
    price: '',
    currency: '',
    timeFrom: '',
    timeTo: '',
    unavailableForBooking: false,
    unavailableJoin: true,
    unavailableSelfService: false,
    unavailableCallingWaiter: true,
    withChildren: true,
    withAnimals: false,
    forDisabled: true,
    onlyForAdiltos: false,
    forVip: true,
    number: '',
    type: 'preview-text',
    onChange: () => { },


};

export default CatalogItemSettings;

