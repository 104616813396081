import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import Button from 'components/common/Button';
import Icon from 'components/common/Icon';

import './index.scss';



const GoogleMap = ({
  image,
  text,
  link,
  className,
  onClick
}) => {
  const googleMap = (
    <div
      className={classnames([
        'google-map',
        'google-map__preview',
        { 'google-map__preview__with-image': image },
        { 'google-map__preview__with-button': link },
        { 'google-map__preview__with-image__with-button': image && link },
        className
      ])}
      onClick={onClick}
    >
      {image && !link && (
        <p className="google-map__title"><img src={image} alt={text} />{text}</p>
      )}
      {image && link && (
        <div className="google-map-preview-with-image-with-button"><img src={image} alt={text} />
          <div className="google-map-preview-with-image-with-button__title">{text}</div>
        </div>
      )}
      {!image && (
        <div className="google-map-preview-without-image-with-button">
          <div className="google-maps-button-box"><Button>
            <Icon className="google-maps-icon" type="mapMarkerAlt" /></Button></div>
          <div className="google-maps-dilimetr-box"></div>
          <div className="google-map-p">{text}</div>
        </div>
      )}


    </div>
  );

  if (link)
    return <Button className="button-in-google-map" >{googleMap}</Button>;
  return googleMap;

};

GoogleMap.propTypes = {
  image: PropTypes.string,
  text: PropTypes.string
};

GoogleMap.defaultProps = {
  image: undefined,
  text: ''
};

export default GoogleMap;
