import React, { useState, useEffect, useCallback } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import Button from 'components/common/Button';
import Input from 'components/common/Input';
import Icon from 'components/common/Icon';
import Slider from 'components/common/Slider';
import { __ } from 'utils/translation';
import QRCode from "qrcode.react";
import API from 'utils/api';

import { UPDATE_CONFIG_DATA } from 'constants/actions';
import CallWaiterSettings from './CallWaiterSettings';

import './index.scss';

const CallWaiter = () => {
    const [data, setData] = useState({
        appWaiterLink: '',
    });
    const [settingsOpened, setSettingsOpened] = useState(false);
    const [quantityCopy, setQuantityCopy] = useState("");
    const [parametrs, setParametrs] = useState('WaiterName');
    const { title, appWaiterLink, settingsName } = useSelector((state) => state.config.data);
    const url = `https://maps.menu/${settingsName}`;
    
    const emptyParametr = "QWERTY12345"

    useEffect(() => {
        setData({ appWaiterLink });
    }, [appWaiterLink]);

    const onChangeData = useCallback((newAppWaiterLink) => {
        setData({
            appWaiterLink: newAppWaiterLink
        });
    }, [setData]);

    const closeTitleSettings = useCallback(() => setSettingsOpened(false), [setSettingsOpened]);

    const dispatch = useDispatch();

    const submitSettings = () => {
        dispatch({
            type: UPDATE_CONFIG_DATA, appWaiterLink: data.appWaiterLink
        });
        closeTitleSettings();
    };

    const [sent, setSent] = useState(false);
    const [timer, setTimer] = useState(null);
    const urlParametrs = `${url}?`;

    useEffect(() => {
        timer && timer > 0 && setTimeout(() => {
            if (!timer || timer <= 1) {
                setTimer(null);
                setSent(false);
                return;
            }
            setTimer(timer - 1);
        }, 1000);
    }, [timer]);


    const handleSubmit = () => {
        API.sendOrder({
            type: "QR",
            linkQr: `${urlParametrs}${data.appWaiterLink}&iamwaiter=${parametrs}`,
            title: title,
            table: "Журнал официантов",
            tableNumber: "",
            description0: "Это секретная ссылка,чтобы:",
            description1: "Установить журнал официантов;",
            description2: "Управлять заказами и столами.",
            description3: "Сохраните ссылку на телефоне!",
            user: settingsName,
            copy: quantityCopy
        }).then(() => {
            setTimer(30);
            setSent(true);
        });
    };

    let showAddButton = false;
    if (appWaiterLink !== "") {
        showAddButton = true;
    };

    let showAlert = false;
    if (emptyParametr === data.appWaiterLink) {
        showAlert = true;
    };



    return (
        <React.Fragment>

            <div className="reviews">
                {showAddButton ?
                    <Button
                        key="callWaiter-button"
                        className="review"
                        onClick={() => setSettingsOpened(true)}
                    >
                        <div className="google-map-preview-without-image-with-button">
                            <div className="google-maps-button-box"><Button><Icon className="google-maps-icon" type="running" /></Button></div>
                            <div className="google-maps-dilimetr-box"></div>
                            <div className="google-map-p">{__("Журнал управления столами активирован. Секретная ссылка на приложение внутри.")}</div>
                        </div>
                    </Button>
                    :
                    <Button
                        key="add-button"
                        onClick={() => setSettingsOpened(true)}
                        className='review-tech-button' style={{ textAlign: "left" }}
                    >
                        <Icon type="plus" />
                        <span>{__("Управление столами")}</span>
                    </Button>
                }

            </div>

            <Slider
                opened={settingsOpened}
                onClose={closeTitleSettings}
                title={__("Генератор секретной ссылки для открытия журнала для официантов")}
                onSubmit={submitSettings}
                subtitle={__("Эта секретная ссылка позволит вам просматривать заказы в вашем ресторане. Вы сможете подтверждать оплату заказа, отклонять заказ, активировать заказ, видеть открытые и свободные столы и другие функции. Любой кто знает эту ссылку может управлять вашими заказами. Рекомендуется заменять эту ссылку как можно чаще.")}
            >
                <div className="area-box-for-catalogItems" >
                    <CallWaiterSettings appWaiterLink={data.appWaiterLink} onChange={onChangeData} />
                    {!showAlert ?
                        <>
                            <div class="catalogItem-settings__settings__field-header" style={{ marginLeft: 24, marginTop: -30 }}>{__("Напишите в этом поле любые символы, чтобы сгенерировать секретную ссылку. Измените эти символы, чтобы создать новую ссылку.")}</div>
                            <br />
                            <div className="call-waiter-buton-box" >
                                <input className="call-waiter-buton-box-for-input-waiter"
                                    type='text'
                                    id='parametrs'
                                    placeholder={__("Имя официанта")}
                                    maxLength="20"
                                    value={parametrs}
                                    onChange={(e) => setParametrs(e.target.value)} />
                            </div>
                            <div class="catalogItem-settings__settings__field-header" style={{ marginLeft: 24, marginTop: -30 }}>{__("В это поле напишите имя официанта, а затем распечатайте этот код. Официант сможет управлять столиками в ресторане.")}</div>
                            <br />

                            <div className="qrComponents-flexBox">
                                <div className="catalog-table-settings-screenshot__screenshot-area">
                                    <QRCode
                                        id="paramUrl"
                                        value={urlParametrs + data.appWaiterLink + "&iamwaiter=" + parametrs}
                                        size={290}
                                        bgColor={"#ffffff"}
                                        fgColor={"#000000"}
                                        level={"L"}
                                        includeMargin={false}
                                        
                                    />

                                    <p className="linkQr">{urlParametrs}{data.appWaiterLink}&iamwaiter={parametrs}</p>

                                </div>
                            </div>

                            <div className="catalog-table-settings-screenshot__screenshot-area__description">
                                <Icon type="camera" className="catalog-table-settings-screenshot__screenshot-area__description__icon" />&nbsp;
                                <b>{__("Область для снимка экрана")}</b>
                            </div>
                            {/* КНОПКА ОТПРАВИТЬ QR В ПЕЧАТЬ */}
                            {!sent &&
                                <div className="call-waiter-buton-with-input-box" >
                                    <input className="call-waiter-buton-with-input"
                                        type='text'
                                        id='parametrs'
                                        placeholder="0"
                                        maxLength="2"
                                        value={quantityCopy}
                                        onChange={(e) => setQuantityCopy(e.target.value)} />
                                    {quantityCopy > 0 ?
                                        <Button
                                            className='call-waiter-settings__settings__animation__store'

                                            onClick={handleSubmit}
                                            isInline
                                            noStyled
                                        >
                                            {__("РАСПЕЧАТАТЬ ЭТОТ QR CODE")}
                                        </Button> :
                                        <Button
                                            className='call-waiter-settings__settings__animation__nostore'
                                            onClick={() => { }}
                                            isInline
                                            noStyled
                                        >
                                            {__("РАСПЕЧАТАТЬ ЭТОТ QR CODE")}
                                        </Button>
                                    }

                                </div>

                            }
                            {sent && <Button
                                className='call-waiter__checked'
                                onClick={() => { }}
                                isInline
                                noStyled
                            >
                                {__("QR CODE ОТПРАВЛЕН В ПЕЧАТЬ")}{timer % 3 === 0 ? "..." : timer % 3 === 1 ? ".." : "."}
                            </Button>

                            }
                            <br />
                            <br />

                        </> :
                        <>
                        <br />
                            <div class="catalogItem-settings__settings__field-header" style={{ marginLeft: 24, marginTop: -30, color: "red", paddingRight:24 }}><Icon type="exclamationTriangle" />&nbsp;{__("Необходимо заменить эту строку. Придумайте любые символы и напишите их без  пробела в этом поле.")}</div>
                            <br />
                        </>
                    }

                    <div className="settings-video-link-box" style={{ marginLeft: 12 }}>
                        <a href="" target="_blank">
                            <div className="settings-video-link-box__flexbox" style={{ marginLeft: 12 }}>
                                <Icon className="settings-video-link-box__flexbox__icon" type="youtube" />
                                <div className="settings-video-link-box__flexbox__text">{__("Нажмите, чтобы посмотреть инструкцию в YouTube")}</div>
                            </div>
                        </a>
                    </div>
                    <br />
                </div>
                <br />
            </Slider>
        </React.Fragment>
    );
};

export default CallWaiter;
