import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { uuid } from 'uuidv4';
import { __ } from 'utils/translation';
import Button from 'components/common/Button';
import Icon from 'components/common/Icon';
import Slider from 'components/common/Slider';

import addedMenu from 'images/addedmenu.jpg';

import { EDIT_STORY_DRINK, REMOVE_STORY_DRINK, ROTATE_STORY_DRINK, CATALOG_DRINK_FILTER } from 'constants/actions';

import StoryDrinkSettings from './StoryDrinkSettings';
import StoryDrink from './StoryDrink';
import history from 'utils/history';

import './index.scss';
import { concat } from 'lodash';

const emptySettings = {
  guid: null,
  type: 'preview-text',
  order: null,
  linkUrl: '',
  description: '',
  image: '',
};

const StoriesDrinks = ({ data }) => {
  const [settingsOpened, setSettingsOpened] = useState(null);
  const [storyDrinkData, setStoryDrinkData] = useState(emptySettings);
  const { storyDrinkGuid, storyDrinkGuidSelected  } = useSelector((state) => state.config);
  const showDescription = true;
  const closeStoriesDrinksSettings = () => {
    setSettingsOpened(null);
  };

  const addStoryDrinkSettings = () => {
    setSettingsOpened(uuid());
  }

  const onOpenStoryDrinkSettings = ({ guid }) => {
    setSettingsOpened(guid);
  };

  const dispatch = useDispatch();
  const submitSettings = () => {
    if (storyDrinkData.description || storyDrinkData.image)
      dispatch({ type: EDIT_STORY_DRINK, payload: storyDrinkData });
    closeStoriesDrinksSettings();
  };

  const removeSettings = () => {
    dispatch({ type: REMOVE_STORY_DRINK, guid: storyDrinkData.guid });
    closeStoriesDrinksSettings();
  };

  const { storiesDrinks = [] } = useSelector((state) => state.config.data);
  const { active, paymentData } = useSelector((state) => state.config.account);

  useEffect(() => {
    const currentStoryDrink = storiesDrinks.find((storyDrink) => storyDrink.guid === settingsOpened);
    const settings = currentStoryDrink || { ...emptySettings };
    if (!settings.guid && settingsOpened) {
      settings.guid = settingsOpened;
    } else if (!settings.guid) {
      settings.guid = uuid();
    }
    setStoryDrinkData({ ...settings });
    console.log(concat("Stories effect ", settingsOpened ? settingsOpened.toString() : ""));
  }, [settingsOpened, storiesDrinks]);
  

  const onRotate = (guid, order) => {
    dispatch({ type: ROTATE_STORY_DRINK, guid, order });
  };

  const setStoryFilter = ({ guid }) => {
    dispatch({ type: CATALOG_DRINK_FILTER, storyDrinkGuid: storyDrinkGuid !== guid ? guid : null });
  }

  const handleImport = (data) => {
    dispatch({ type: EDIT_STORY_DRINK, payload: data });
  }

  storiesDrinks.sort((a, b) => b.order - a.order);
  return (
    <React.Fragment>
      {data.length > 0 && (
        <div className="stories-theme2">
          <div className="stories-theme2-picker">
            <div className="stories-theme2-picker__empty">
              <div className="stories-theme2-picker-item">
                <div className="stories-theme2-picker-item-flexbox">
                  <div className="stories-theme2-picker-item-flexbox__box" onClick={addStoryDrinkSettings} style={{ backgroundImage: `URL(${addedMenu})` }} />
                  <Button onClick={addStoryDrinkSettings} className="stories-theme2-picker-item-flexbox__title">{__("Создайте разделы меню")}</Button>
                  <div className="stories-theme2-picker-item-flexbox__icon" onClick={addStoryDrinkSettings}><Icon className={classnames(['icon-40'])} type="plusCircle" /></div>
                </div>
              </div>

              {data.map((storyDrink) =>
                <StoryDrink className={classnames(['stories-theme2-picker-item'])}
                  onClick={() => onOpenStoryDrinkSettings(storyDrink)}
                  onFilter={() => setStoryFilter(storyDrink)}
                  key={storyDrink.guid} {...storyDrink}
                  selected={storyDrinkGuidSelected === storyDrink.guid} />)}

            </div>
          </div>
        </div>
      )}

      {data.length === 0 && (<div className="stories-theme2">
        <div className="stories-theme2-picker">
          <div className="stories-theme2-picker__empty">
            <div className="stories-theme2-picker-item">
              <div className="stories-theme2-picker-item-flexbox">
                <div className="stories-theme2-picker-item-flexbox__box" onClick={addStoryDrinkSettings} style={{ backgroundImage: `URL(${addedMenu})` }} />
                <Button onClick={addStoryDrinkSettings} className="stories-theme2-picker-item-flexbox__title">{__("Создайте разделы меню")}</Button>
                <div className="stories-theme2-picker-item-flexbox__icon" onClick={addStoryDrinkSettings}><Icon className={classnames(['icon-40'])} type="plusCircle" /></div>
              </div>
            </div>
            <div className="stories-theme2-picker-item">
              <div className="stories-theme2-picker-item-flexbox">
                <div className="stories-theme2-picker-item-flexbox__box" onClick={addStoryDrinkSettings} style={{ backgroundImage: `URL(${addedMenu})` }} />
                <Button onClick={addStoryDrinkSettings} className="stories-theme2-picker-item-flexbox__title">{__("Создайте разделы меню")}</Button>
                <div className="stories-theme2-picker-item-flexbox__icon" onClick={addStoryDrinkSettings}><Icon className={classnames(['icon-40'])} type="plusCircle" /></div>
              </div>
            </div>
            <div className="stories-theme2-picker-item">
              <div className="stories-theme2-picker-item-flexbox">
                <div className="stories-theme2-picker-item-flexbox__box" onClick={addStoryDrinkSettings} style={{ backgroundImage: `URL(${addedMenu})` }} />
                <Button onClick={addStoryDrinkSettings} className="stories-theme2-picker-item-flexbox__title">{__("Создайте разделы меню")}</Button>
                <div className="stories-theme2-picker-item-flexbox__icon" onClick={addStoryDrinkSettings}><Icon className={classnames(['icon-40'])} type="plusCircle" /></div>
              </div>
            </div>
            <div className="stories-theme2-picker-item">
              <div className="stories-theme2-picker-item-flexbox">
                <div className="stories-theme2-picker-item-flexbox__box" onClick={addStoryDrinkSettings} style={{ backgroundImage: `URL(${addedMenu})` }} />
                <Button onClick={addStoryDrinkSettings} className="stories-theme2-picker-item-flexbox__title">{__("Создайте разделы меню")}</Button>
                <div className="stories-theme2-picker-item-flexbox__icon" onClick={addStoryDrinkSettings}><Icon className={classnames(['icon-40'])} type="plusCircle" /></div>
              </div>
            </div>
            <div className="stories-theme2-picker-item">
              <div className="stories-theme2-picker-item-flexbox">
                <div className="stories-theme2-picker-item-flexbox__box" onClick={addStoryDrinkSettings} style={{ backgroundImage: `URL(${addedMenu})` }} />
                <Button onClick={addStoryDrinkSettings} className="stories-theme2-picker-item-flexbox__title">{__("Создайте разделы меню")}</Button>
                <div className="stories-theme2-picker-item-flexbox__icon" onClick={addStoryDrinkSettings}><Icon className={classnames(['icon-40'])} type="plusCircle" /></div>
              </div>
            </div>
            <div className="stories-theme2-picker-item">
              <div className="stories-theme2-picker-item-flexbox">
                <div className="stories-theme2-picker-item-flexbox__box" onClick={addStoryDrinkSettings} style={{ backgroundImage: `URL(${addedMenu})` }} />
                <Button onClick={addStoryDrinkSettings} className="stories-theme2-picker-item-flexbox__title">{__("Создайте разделы меню")}</Button>
                <div className="stories-theme2-picker-item-flexbox__icon" onClick={addStoryDrinkSettings}><Icon className={classnames(['icon-40'])} type="plusCircle" /></div>
              </div>
            </div>
          </div>
        </div>
      </div>
      )}





      <Slider
        opened={settingsOpened !== null}
        onClose={closeStoriesDrinksSettings}
        title={__("Добавление разделов меню")}
        subtitle={
          <React.Fragment>
            <p>{__("Создай разделы меню такие как: Чай, Кофе, Соки, Пиво, Коктейли и прочее...")}<br />
            </p>


          </React.Fragment>}
        onRemove={settingsOpened && removeSettings}
        onSubmit={submitSettings}
      >
        <StoryDrinkSettings {...storyDrinkData} showDescription={showDescription} onRotate={onRotate}
          onChange={(settings) => setStoryDrinkData({ ...storyDrinkData, ...settings })}
          onImport={(settings) => handleImport({ ...storyDrinkData, ...settings })} />
      </Slider>

    </React.Fragment>
  );
};

StoriesDrinks.propTypes = {
  showDescription: PropTypes.bool,
  data: PropTypes.arrayOf(PropTypes.shape({}))
};

StoriesDrinks.defaultProps = {
  showDescription: true,
  data: []
};

export default StoriesDrinks;
