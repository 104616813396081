import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import Recovery from 'components/Recovery';
import Icon from 'components/common/Icon';
import Button from 'components/common/Button';
import Input from 'components/common/Input';
import classnames from 'classnames';
import './index.scss';
import { __ } from 'utils/translation';
import Slider from 'components/common/Slider';


const Verify = () => {
    const [settingsOpened, setSettingsOpened] = useState(false);
    const [startRecovery, setStartRecovery] = useState(false);
    const [oldPageMenuLink, setOldPageMenuLink] = useState(""); 
    
    const { active } = useSelector((state) => state.config.account);
    const { settingsName } = useSelector((state) => state.config.data);

    const linkWhatsapp = `https://wa.me/34672442251?text=Maps%20Menu%20control%20panel%3A ${window.location.href}`;
    
    const createNewCP =  "https://dash.maps.menu";
    

    const openVerifySettings = () => {
        setSettingsOpened(true);
        return false;
    };
    const closeVerifySettings = () => {
        setSettingsOpened(false);
    };


    if (startRecovery)
    return <Recovery oldPageMenuLink={oldPageMenuLink} />;

    return ( 
        <React.Fragment>
            <Button onClick={openVerifySettings} isInline className={settingsName === "TbRqgtyBBd8MXYxSLzJeAT" ? "icon-header-padding-blink" : 
            "icon-header-profile"} >
                <Icon type="user" />
            </Button>
            <Slider
                opened={settingsOpened}
                onClose={closeVerifySettings}
                onSubmit={() => setSettingsOpened(false)}
                title={__("text00018")}
                subtitle={settingsName === "TbRqgtyBBd8MXYxSLzJeAT" ? __("text00016") : __("text00019") }
                linkWhatsapp={settingsName === "TbRqgtyBBd8MXYxSLzJeAT" ? createNewCP : linkWhatsapp }
                submitTitle={settingsName === "TbRqgtyBBd8MXYxSLzJeAT" ? __("text00014") : __("text00020")}
            >
                {settingsName !== "TbRqgtyBBd8MXYxSLzJeAT" &&
                    <div className="verify">
                        <div className="catalogItem-settings__settings " style={{paddingLeft:12}}>
                            <Input className="catalogItem-settings__settings__input"
                                value={oldPageMenuLink}
                                name="oldPageMenuLink"
                                type="text"
                                placeholder="https://maps.menu/XxxXXxXxxxxxxxxXX"
                                onChange={(value) => setOldPageMenuLink(value)}
                            />
                                <div className="catalogItem-settings__settings__field-header" style={{paddingLeft:24}}>{__("text00021")}</div>
                            <br />

                            <div className="catalogItem-settings__types">
                                <Button
                                    className={classnames(['catalogItem-settings__settings__animation__store'])}
                                        onClick={() => setStartRecovery(true)}
                                        isInline
                                        noStyled
                                    >
                                        {__("text00018")}
                                </Button>
                            </div>
                            <hr/>
                            {settingsName === "TbRqgtyBBd8MXYxSLzJeAT" ? 
                                    <>
                                        <p></p>
                                    </>
                                : 
                                <div className="catalogItem-settings__settings__field-header" style={{paddingLeft:24}}>
                                    <br/>
                                    {__("Мы отправим вам постоянную ссылку для этой панели управления.")}<br/>
                                    {__("Внимание!!! Это секретная ссылка, только администратор ресторана может использовать эту ссылку.")}
                                </div>
                            }
                        </div>
                    </div>
                } 
                <br/>
            </Slider>
        </React.Fragment>
    );
};


export default Verify;
