import React, { useEffect } from 'react';
import './index.scss';

const BackToLanding = () => {

    useEffect(() => { 
        window.open("https://maps.menu", "_self")       
    }, [ ]);

    return (
        <div/>
);

};

export default BackToLanding;